@media (max-width: 993px) {
  .div-logo {
    position: absolute;
    width: 218px;
    height: 136px;
    z-index: 10;
    left: 0px;
    top: 33px;
  }
}

@media (max-width: 993px) {
  .img-logo {
    width: 218px;
  }
}

@media (max-width: 993px) {
  .nav-item > a {
    font-size: 14px;
  }
}

@media (max-width: 985px) {
  .col-redes-top {
    display: none;
  }
}

@media (max-width: 985px) {
  .col-log-top {
    display: none;
  }
}

@media (max-width: 985px) {
  .col-menu-top {
    display: none;
  }
}

@media (max-width: 985px) {
  .div-logo-scrolled {
    top: -5px;
  }
}

@media (max-width: 985px) {
  .hamb-div {
    display: block;
  }
}

@media (max-width: 985px) {

  .div-logo {
    width: 116px;
    top: 2px;
  }

  .img-logo {
    width: 116px;
  }

  .row-104{
    height: 64px !important;
  }

  .border-header{
    height: 3px !important;
    top: 62px;
  }

  .header-gray{
    height: 64px !important;
  }

  .hamb-div {
    top: 3px;
  }

  #quemsomos{
    margin-top: 62px;
  }

  .box-sport-main .bs-popover-end{
    left: -31px !important;
  }

}

