body {
  font-family: 'Noto Sans';
  background: #e8ebef;
}

button {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
  outline: 0px !important;
}

.btn-back:disabled {
  color: #fff;
  background: none !important;
  border: none !important;;
}

.transit-400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.nav li {
  float: left;
}

.main-menu.sticky {
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.part-2.sticky a {
  font-weight: 600 !important;
}

.f-section {
  margin-top: 104px;
}

.stick-space {
  margin-top: 104px;
}

.header-gray {
  background: #e8ebef;
  height: 104px;
  position: fixed;
  width: 100vw;
  top: 0px;
  z-index: 25;
  box-shadow: 0px 0px 5px 5px rgba(23,25,27,0.21);
}

.cont-border-header {
  border-bottom: 3px solid #fe0000;
}

.cont-border-header-scroled {
  border-bottom: 2px solid #fe0000;
}

.scroled-64 {
  height: 64px !important;
}

.row-104 {
  height: 104px;
}

.div-logo {
  position: absolute;
  width: 326px;
  height: 166px;
  z-index: 10;
  left: 0px;
  top: 6px;
}

.div-logo-scrolled {
  top: 2px;
}

.img-logo {
  width: 316px;
}

.img-logo-scrolled {
  width: 200px;
}

.nav-item > a {
  color: #000000 !important;
  font-weight: bold !important;
}

.border-header {
  position: absolute;
  width: 100%;
  height: 6px;
  left: 0px;
  top: 99px;
  background: #fe0000;
  z-index: 11;
}

.border-header-scrolled {
  height: 3px !important;
  top: 62px;
}

.div-icon-top {
  padding: 0px 0px 13px 0px;
}

.div-icon-top i {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 18px;
}

.div-icon-top svg {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 18px;
}

.div-top-btns {
  padding: 0px 0px 13px 0px;
}

.div-top-btns a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.div-top-btns .dropdown button {
  font-size: 14px !important;
  padding: 4px !important;
  font-weight: bold !important;
  border-color: #0f73ae;
  background-color: #0f73ae;
}

.div-top-btns .dropdown-toggle::after {
  display: none;
}

.div-top-btns .list-group .list-group-item {
  padding: 0px;
  background-color: initial;
  border: none;
}

.div-top-btns .list-group .list-group-item-a {
  padding-top: 3px;
}

.h-top-home {
  color: #ffffff;
}

.button-get-in-game {
  background: #0f73ae;
  font-size: 29px;
  font-weight: bold;
  border-radius: 13px;
  border: 2px #fd0000 solid;
}

.button-get-in-game:hover {
  border: 2px #0f73ae solid;
  background: #fd0000;
}

.div-blue-sep {
  width: 100%;
  height: 10px;
  background: #065985;
}

.center-margin {
  display: block;
  margin: 0px auto;
}

.hamb-div {
  position: absolute;
  border: 2px #0f73ae solid;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  z-index: 40;
  display: none;
  right: 25px;
  top: 28px;
  cursor: pointer;
  font-size: 33px;
  color: #000;
  text-align: center;
}

.hamb-div-scrolled {
  top: 3px;
}

.list-menu-toogle {
  border-radius: 0px;
}

.list-menu-toogle .list-group-item {
  background: none;
  border: none;
}

.list-menu-toogle .list-group-item a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.bg-blue {
  background-color: #065985 !important;
  border: none;
}

.dropdown-item-nav {
  color: #000000 !important;
}

.bg-full {
  /*height: 100vh;*/
}

.div-register-titles {
  background: #00397a;
  width: 307px;
  margin: 0px auto;
  display: block;
  border-radius: 14px;
  border: 4px #ff0000 solid;
  padding-top: 15px;
  padding-bottom: 1px;
}

.div-register-titles h4 {
  color: #ffffff;
  font-weight: bold;
  font-size: 26px;
}

.regiter-titles {
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
}

.row-register label {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.row-register p {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
}

.row-register .col {
  padding-left: 20px;
  padding-right: 20px;
}

.row-register input[type="text"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register input[type="email"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register input[type="password"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register input[type="date"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;

}

.row-register input[type="number"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register select {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register input[type="file"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;
}

.row-register input[type="tel"] {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;

}

.sep-labels {
  height: 38px;
}

.sep-labels-20 {
  height: 20px;
}

.row-bts-profile-1 {
  margin-top: 54px;
}

.row-final-profile-1 {
  padding-bottom: 88px;
}

.btn-back {
  background: none;
  border: none;
  font-size: 32px;
  font-weight: bold;
  position: relative;
}

.btn-back-margin-fix {
  margin-left: 24px;
}

.btn-back:hover {
  background: none;
  border: none;
}

.btn-back:focus {
  background: none;
  border: none;
}

.btn-back span {
  position: absolute;
  top: 3px;
  left: -28px;
  width: 40px;
  height: 40px;
}

.btn-back span img {
  width: 40px;
  height: 40px;
}

.btn-back.next-btn  {
  margin-right: 32px;
}

.btn-back.next-btn span {
  position: absolute;
  top: 3px;
  left: initial;
  width: 40px;
  height: 40px;
  right: -28px !important;
}

/* AUTO COMPLET */

/* the container must be positioned relative: */

.autocomplete {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/* when hovering an item: */

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/* when navigating through the items using the arrow keys: */

.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.delay-1 {
  animation-delay: .10s;
}

.delay-2 {
  animation-delay: .20s;
}

.delay-3 {
  animation-delay: .30s;
}

.delay-4 {
  animation-delay: .40s;
}

.delay-5 {
  animation-delay: .50s;
}

.delay-6 {
  animation-delay: .60s;
}

.delay-7 {
  animation-delay: .70s;
}

.delay-8 {
  animation-delay: .80s;
}

.delay-9 {
  animation-delay: .90s;
}

.delay-10 {
  animation-delay: 1s;
}

.box-sport-main {
  position: relative;
  width: 142px;
  height: 156px;
  margin: 0px auto;
  display: block;
}

.box-sport {
  position: relative;
  width: 140px;
  height: 154px;
  background: linear-gradient(90deg, #094464, #0b567e 52%, #094464);
  border-radius: 10px;
  border: 4px solid #ffffff;
  box-shadow: inset 4px 4px 7px rgba(33,37,41,0.71);
  overflow: hidden;
}

.box-sport h4 {
  position: absolute;
  background: #fe0000;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 4px 4px 30px;
  bottom: -8px;
  text-align: left !important;
  z-index: 10;
}

.box-sport img {
  position: absolute;
  width: 140px;
  left: 0px;
  top: 0px;
}

.hold-radio {
  position: absolute;
  width: 100%;
  height: 154px;
  top: 0px;
  left: 0px;
  z-index: 11;
}

/* label-sports */

.container-label {
  display: block;
  width: 100%;
  height: 154px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  bottom: -4px;
  left: -9px;
  height: 38px;
  width: 38px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: inset 4px 4px 7px rgba(33,37,41,0.71);
  border: 4px solid #ffffff;
}

/* On mouse-over, add a grey background color */

.container-label:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

.container-label input:checked ~ .checkmark {
  background-color: #0c7ddb;
  box-shadow: inset 4px 4px 7px rgba(33,37,41,0.71);
  border: 4px solid #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container-label .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /*background: white;*/
}

.col-margin-bottom {
  margin-bottom: 73px;
}

/* normal label */

.container-n-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.container-n-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark-n-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: inset 4px 4px 7px rgba(33,37,41,0.71);
  border: 3px solid #ffffff;
}

/* On mouse-over, add a grey background color */

.container-n-label:hover input ~ .checkmark-n-label {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */

.container-n-label input:checked ~ .checkmark-n-label {
  background-color: #0c7ddb;
  box-shadow: inset 4px 4px 7px rgba(33,37,41,0.71);
  border: 3px solid #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark-n-label:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container-n-label input:checked ~ .checkmark-n-label:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container-n-label .checkmark-n-label:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.poligon-profile {
  display: inline-block;
  position: relative;
  background: red;
  box-sizing: border-box;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  width: 260px;
  height: 276px;
  color: #1f72c5;
  background: #326eb4;
}

.poligon-profile img {
  position: absolute;
  top: 5px;
  left: 4px;
  width: 252px;
  height: 265px;
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.hold-profile-divs {
  position: absolute;
  width: 211px;
  height: 316px;
  z-index: 12;
  top: -11px;
  left: -13px;
}

.q-item {
  position: absolute;
  left: 0px;
  top: 18px;
  width: 200px;
  height: 307px;
  float: left;
  margin-left: 58px;
  padding-right: 1%;
  border: 6px solid #0c5c89;
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -o-transform: skew(-10deg);
  border-radius: 5px;
  z-index: 10;
}

.q-item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /*background: url('http://jsfiddle.net/img/logo.png') no-repeat no-repeat 33px 1px;*/
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  -o-transform: skew(-10deg);
  transform: skew(-10deg);
}

.q-item-2 {
  position: absolute;
  left: -5px;
  top: 14px;
  width: 211px;
  height: 316px;
  float: left;
  margin-left: 58px;
  padding-right: 1%;
  border: 8px solid #ffffff;
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -o-transform: skew(-10deg);
  border-radius: 5px;
  z-index: 9;
}

.banner-ath {
  position: relative;
  height: 260px;
  width: 100%;
  border-bottom: 4px #ff0000 solid;
  z-index: 10;
}

.logo-ath {
  position: absolute;
  right: 0px;
  /*bottom: -52px;*/
  z-index: 11;
  top: 180px;
}

.logo-ath img {
  width: 250px;
}

.profile-ath-img {
  position: absolute;
  z-index: 12;
  top: 0px;
  left: 0px;
}

.ath-f-col {
  padding-top: 14px;
  max-width: 314px;
  /*position: sticky;*/
  /*top: 104px;*/
  height: 100vh;
}

.ath-m-col {
  padding-top: 32px;
  /*max-width: 314px;*/
}

.ath-m-col .f-div {
  padding-left: 20px;
}

.ath-min-title {
  color: rgb(124,125,127);
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.ath-min-title i {
  position: absolute;
  right: 0px;
  font-size: 17px;
  color: #262727;
}

.ath-min-title .icon-title-left {
  position: absolute;
  left: -53px !important;
  color: #555657;
  font-size: 35px;
  top: -11px;
}

.ath-min-text {
  font-size: 13px;
  color: rgb(124,125,127);
}

.col-mdl-max {
  width: 100%;
  max-width: 850px;
  margin: 0px auto;
}

.col-mdl-extra-max {
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
}

.ath-min-text-m {
  font-size: 12px;
  color: rgb(124,125,127);
}

.btn-blue-border {
  display: block;
  color: #1e71a1;
  border: 1px #1e71a1 solid;
  border-radius: 6px;
  width: 196px;
  font-size: 14px;
  margin: 0 auto;
}

.btn-blue-border-normal {
  /*display: block;*/
  color: #1e71a1;
  border: 1px #1e71a1 solid;
  border-radius: 6px;
  /*width: 196px;*/
  font-size: 14px;
  margin: 0 auto;
}



.btn-blue-border:hover {
  color: #ffffff;
  border: 1px #1e71a1 solid;
  background: #1e71a1;
}

.ath-lft-box-university-main {
  position: relative;
  width: 266px;
  height: 154px;
  margin: 0 auto;
}

.ath-lft-box-university-main .ath-lft-box-university {
  width: 266px;
  height: 154px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  position: relative;
}

.ath-lft-box-university-main .ath-lft-box-university .bg-img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.ath-lft-box-university-main .ath-lft-box-university .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(0,0,0,0.5);
}

.ath-lft-box-university-main .div-logo-univer {
  width: 80px;
  height: 80px;
  background: #ffffff;
  position: absolute;
  right: 10px;
  bottom: -28px;
  z-index: 2;
  border-radius: 2px;
}

.ath-lft-box-university-main .div-logo-univer img {
  width: 60px;
  margin: 0 auto;
  display: block;
  margin-top: 9px;
}

.div-margin-30 {
  width: 100%;
  height: 30px;
}

.div-margin-10 {
  width: 100%;
  height: 10px;
}

.div-margin-60 {
  width: 100%;
  height: 60px;
}

.ath-lft-box-university h1 {
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 6px 0px 10px;
  position: relative;
  z-index: 11;
}

.ath-lft-box-university h2 {
  color: #ffffff;
  font-size: 14px;
  padding: 0px 6px 0px 10px;
  position: relative;
  z-index: 11;
}

.ath-lft-box-university .btn-lock {
  position: absolute;
  z-index: 20;
  bottom: 10px;
  left: 10px;
  font-size: 14px;
  padding: 4px;
  background: #686a75;
  border: none;
  border-radius: 6px;
}

.btn-ath-comum {
  background: #0369a1;
  font-size: 13px;
  padding: 7px;
  border-radius: 6px;
  font-weight: bold;
  border: none;
  color: #ffffff;
}

.btn-ath-comum:hover {
  background: #024e77;
}

.btn-ath-comum:focus {
  background: #024e77;
}

.btn-ath-comum-white {
  background: #ffffff;
  font-size: 14px;
  padding: 7px;
  border-radius: 6px;
  font-weight: bold;
  border: 1px solid #0369a1;
  color: #0369a1;
}

.btn-ath-comum-white:hover {
  color: #024e77;
  border: 1px solid #024e77;
  background: #ffffff;
}

.btn-ath-comum-white:focus {
  color: #024e77;
  border: 1px solid #024e77;
  background: #ffffff;
}

.ath-white-menu {
  background: #ffffff;
  /*padding-top: 14px;*/
  /*padding-bottom: 14px;*/
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
}

.ath-white-menu a {
  text-decoration: none;
  padding: 20px 40px 20px 40px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}

.ath-white-menu a.active {
  color: #ea6d00;
  font-weight: bold;
  border-bottom: 2px #ea6d00 solid;
}

.ath-white-boxes {
  background: #ffffff;
  /*padding-top: 14px;*/
  /*padding-bottom: 14px;*/
  border-radius: 4px;
  margin-bottom: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
}

.ath-white-boxes .titles-min {
  font-size: 12px;
  color: #58606a;
  padding: 7px 7px 0px 7px;
}

.p-min {
  font-size: 12px;
  color: #58606a;
  padding: 7px 7px 0px 7px;
}

.a-min {
  font-size: 12px;
  color: #0369a1;
  padding: 7px 7px 0px 7px;
  text-decoration: none;
}

.ath-white-mdl-box {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  position: relative;
}

.row-mdl-titles-box {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 14px;
}

.row-mdl-titles-box a {
  color: #0369a1 !important;
  font-size: 14px;
  text-decoration: none;
}

.ath-white-mdl-box h2 .subtitle-min {
  color: #333c49;
  font-size: 13px;
  font-weight: 500 !important;
}

.ath-white-mdl-box h2 {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.ath-white-mdl-box h4 {
  color: #333c49;
  font-size: 14px;
}

.ath-l-col {
  max-width: 315px;
  height: 100vh;
}

.title-shapes-media {
  width: 92px;
  height: 30px;
  font-size: 14px;
  background: #0369a1;
  clip-path: polygon(0 0, 100% 0, 81% 100%, 0 100%);
  color: #ffffff;
  line-height: 27px;
  padding-right: 10px;
  text-align: center;
  font-weight: bold;
}

.btn-blue-border-media {
  display: block;
  color: #1e71a1;
  border: 1px #1e71a1 solid;
  border-radius: 6px;
  width: 80px;
  font-size: 13px;
  /*margin: 0 auto;*/
  text-align: center;
  padding: 0px !important;
  height: 30px;
  line-height: 29px;
  margin-top: 2px;
  margin-right: 2px;
}

.btn-blue-border-media:hover {
  color: #ffffff;
  border: 1px #1e71a1 solid;
  background: #1e71a1;
}

.btn-close-modal {
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 10;
  right: 10px;
  color: #ffffff;
  font-size: 37px;
}

.btn.btn-close-modal:hover {
  color: #ffffff;
}

.m-card-main {
  position: relative;
  width: 268px;
  height: 528px;
  overflow: hidden;
  margin: 0 auto;
}

.m-card-main .top {
  height: 366px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.m-card-main .top .logo {
  position: absolute;
  left: -15px;
  top: -10px;
  z-index: 1;
}

.m-card-main .top .detail-left {
  position: absolute;
  left: -8px;
  bottom: 3px;
  z-index: 1;
}

.m-card-main .top .detail-left h5 {
  position: absolute;
  top: 37px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  left: 14px;
}

.m-card-main .top .detail-left h4 {
  position: absolute;
  top: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  left: 14px;
}

.m-card-main .top .detail-right {
  position: absolute;
  right: -14px;
  bottom: -12px;
  z-index: 1;
}

.m-card-main .bottom {
  background: #202020;
  position: relative;
  width: 100%;
  height: 162px;
}

.m-card-main .bottom h2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.m-card-main .bottom h3 {
  font-size: 14px;
  color: #ffffff;
  text-align: left;
}

.ath-tml-thumb-main {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.ath-tml-thumb-main img {
  width: 40px;
  height: 40px;
}

.col-top-tml .info h3 {
  color: #333c49;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1px;
}

.col-top-tml .info h4 {
  color: #333c49;
  font-size: 12px;
}

.ath-white-mdl-tml-box {
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
}

.ath-white-mdl-tml-box .div-blue {
  width: 100%;
  background: radial-gradient(#0e6fa7, #031621 100%);
  height: 340px;
}

.ath-white-mdl-tml-box .div-white {
  width: 100%;
  background: #ffffff;
  height: 340px;
}

.ath-white-mdl-tml-box .div-bottom {
  width: 100%;
  background: #e2e8f0;
  padding: 20px 20px 10px 13px;
}

.ath-white-mdl-tml-box .div-bottom h2 {
  color: #333c49;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.ath-white-mdl-tml-box .mdl {
  width: 100%;
}

.ath-white-mdl-tml-box .mdl .blue {
  width: 120px;
  background: radial-gradient(#0e6fa7, #031621 100%);
  height: 120px;
}

.ath-white-mdl-tml-box .mdl .white {
  width: 120px;
  background: #ffffff;
  height: 120px;
}

.ath-white-mdl-tml-box .mdl .blue img {
  margin: 0 auto;
  width: 90px;
  display: block;
}

.ath-white-mdl-tml-box .mdl .white img {
  margin: 0 auto;
  width: 90px;
  display: block;
}

.ath-white-mdl-tml-box .mdl .main-text {
  padding-left: 20px;
}

.ath-white-mdl-tml-box .mdl .with-subtitle h2 {
  padding-top: 22px !important;
  margin-bottom: 0px !important;
}

.ath-white-mdl-tml-box .mdl .with-subtitle2 h2 {
  padding-top: 14px !important;
  margin-bottom: 0px !important;
}

.ath-white-mdl-tml-box .mdl .with-subtitle2 span {
  font-size: 14px !important;
  color: #333c49;
}

.ath-white-mdl-tml-box .mdl .with-subtitle span {
  font-size: 14px !important;
  color: #333c49;
}

.ath-white-mdl-tml-box .mdl .main-text h2 {
  color: #333c49;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  padding-top: 31px;
}

.commom-shadown {
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
}

#quemsomos {
  margin-top: 104px;
}

.top-loader{
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 44px;
  height: 44px;
  z-index: 10;
  background: rgba(255,255,255, 0.9);
  border-radius: 50%;
  padding: 6px 2px 2px 6px;
}

.top-loader .spinner-border{
  border: 1px #fe0000 solid;
  border-right: 2px solid #065985;
}

.ath-white-boxes.box-left{
  padding-bottom: 20px;
  padding-top: 6px;
  margin-right: 14px;
  margin-left: 10px;
}


.reg-mb {
  margin-bottom: 20px;
}

.ath-filter-wrapper .bootstrap-select > .dropdown-toggle {
  background: #fff;
  border: 1px solid #ced4da;
}

.row-register .bootstrap-select > .dropdown-toggle {
  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
}

.row-register .filter-option-inner-inner {
  color: #464646;
}

.row-register .bootstrap-select {
  width: 100% !important;
}

.box-sport-main .bs-popover-end{
  top: 132px !important;

}

.box-sport-main .popover-body{
  padding: 6px !important;

}

.btn-blue-border-normal.smr{
  padding: 4px;
  margin-right: 4px;
}

.box-sport-main .gender-wrapper{
  position: absolute;
  width: 100%;
  bottom: -24px;
  display: flex;
  justify-content: center;
  z-index: -1;
}

.box-sport-main .gender-wrapper .gender-box{
  background: #fff;
  color: #464646;
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.regiter-titles-min{
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
}

.list-menu.mbl-home {
  text-align: left;
}

.list-menu.mbl-home a {
  font-size: 16px;
  color: #333c49;

}

.list-menu.mbl-home .btn-primary {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  color: #333c49;
  font-weight: bold;
}

.list-menu.mbl-home .btn-primary:focus {
  background-color: transparent;
  border-color: transparent;
}

.list-menu.mbl-home .btn-primary:hover {
  background-color: transparent;
  border-color: transparent;
}



