.filter-box-mbl {
  display: none;
}

@media (max-width: 692px) {
  .filter-box-mbl {
    display: block;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .m-card-main {
    width: 100% !important;
    height: 370px;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart {
    width: 100% !important;
    border: none;
    height: auto;
  }
}

@media (max-width: 768px) {
  .col-card-ath-search {
    display: initial;
    justify-content: initial;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart img {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-grad-utr {
    top: 12px;
    left: 12px;
    width: 132px;
    height: 60px;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-grad-utr .content {
    width: 124px;
    height: 53px;
    background: #293239;
    border-radius: 6px;
    position: relative;
    top: 3px;
    left: 4px;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-grad-utr .content h2 {
    color: #fff;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0px;
  }
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content h1 {
  color: #fff;
  font-size: 22px;
  padding-top: 0px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers {
    display: none;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .wrapper-cats {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap!important;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-cats {
    width: 49%;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .m-card-main .top {
    height: 370px;
  }

  .card-dtls-ath .wrapper-chart .top.fix {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-cats h4 {
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .box-cats img {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    width: 100%;
    max-width: 85px;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .bottom {
    padding: 8px 10px 14px 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }
}

@media (max-width: 768px) {
  .card-dtls-ath .wrapper-chart .bottom .full {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ath-main-banner.coach {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .title-u.coach {
    margin-top: 2px;
  }
}

@media (max-width: 992px) {
  .colum-right-sep-ath-prof {
    height: 8px;
  }
}

@media (max-width: 992px) {
  .sidebar-prof-ath {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .sidebar-prof-ath-mb {
    display: block;
  }
}

@media (max-width: 768px) {
  .ath-main-banner.ath {
    height: 200px;
  }
}

