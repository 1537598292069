@media (max-width: 692px) {
  .home-div-logo {
    width: 116px;
  }
}

@media (max-width: 993px) {
  .home-div-logo {
    position: absolute;
    width: 218px;
    height: 136px;
    z-index: 10;
    left: 0px;
    top: 0px;
  }
}

@media (max-width: 993px) {
  .img-logo {
    width: 218px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .video-ply-btn a {
    height: 110px;
    width: 110px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .video-ply-btn a::before, .video-ply-btn a::after {
    height: 110px;
    width: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .video-ply-btn {
    padding-top: 100px;
    padding-left: 65px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .counter-single .icon:after {
    width: 92px;
    height: 92px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-ply-btn a {
    height: 110px;
    width: 110px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-single .icon-inner {
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: 17px auto 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-single .icon {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-single .icon::before {
    left: 7px;
    top: 7px;
    width: 95px;
    height: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-single .icon:after {
    width: 92px;
    height: 92px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-ply-btn a::before, .video-ply-btn a::after {
    height: 110px;
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-ply-btn {
    padding-top: 20%;
    padding-left: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single .icon-inner {
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: 17px auto 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single .icon {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single .icon::before {
    left: 7px;
    top: 7px;
    width: 95px;
    height: 95px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single h2 {
    margin: 20px 0 12px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-single .icon:after {
    width: 92px;
    height: 92px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-ply-btn a {
    height: 110px;
    width: 110px;
    line-height: 110px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-ply-btn a::before, .video-ply-btn a::after {
    height: 110px;
    width: 110px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .video-ply-btn {
    padding-top: 100px;
    padding-left: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .video-ply-btn {
    padding-top: 85px;
    padding-left: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon-inner {
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: 17px auto 0;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon::before {
    left: 7px;
    top: 7px;
    width: 95px;
    height: 95px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single h2 {
    margin: 20px 0 12px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon:after {
    width: 92px;
    height: 92px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon::before {
    left: 7px;
    top: 7px;
    width: 95px;
    height: 95px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single h2 {
    margin: 20px 0 12px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .counter-single .icon:after {
    width: 92px;
    height: 92px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .video-ply-btn {
    text-align: center;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-img-top {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .home-img-top {
    display: none;
  }
}

@media (max-width: 767px) {
  .video-ply-btn {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  #home-quemsomos {
    padding-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .col-right-header {
    background: url('./../../assets/imgs/home-banner-2.jpg');
    background-position: center;
    background-size: cover;
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  #home-quemsomos .btn-red {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .home-blue-title {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .home-wrapper-coach-txts {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .home-blue-right-title {
    font-size: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 492px) {
  #home-quemsomos .btn-red {
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

@media (max-width: 492px) {
  .home-btn-red {
    display: block;
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  .home-blue-right-title {
    border-radius: 12px;
    text-align: center;
    right: initial;
  }
}

@media (max-width: 767px) {
  .home-wrapper-coach-txts p {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .home-wrapper-btn-red {
    padding-left: initial;
  }
}

@media (max-width: 767px) {
  .home-btn-red {
    margin-right: 0px;
    display: block;
  }
}

@media (max-width: 767px) {
  .home-wrapper-sponsor-txts {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .home-coach-img {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .home-sponsor-img {
    width: 60%;
    float: right;
  }
}

@media (max-width: 767px) {
  .home-red-left-title {
    font-size: 20px;
    margin-top: 20px;
    border-radius: 12px;
    text-align: center;
    left: initial;
  }
}

@media (max-width: 767px) {
  .home-wrapper-bts-sponsor .home-btn-blue {
    display: block;
    margin-right: 0px;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .home-wrapper-bts-sponsor .home-btn-white-red {
    display: block;
  }
}

@media (max-width: 767px) {
  .home-wrapper-sponsor-txts p {
    padding-left: 12px;
    padding-right: 12px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  #section-price .main-title {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  #section-price .main-title::before {
    width: 242px;
  }
}

@media (max-width: 992px) {
  #section-price {
    padding-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .home-pricing-tbl-single {
    margin-bottom: 80px;
  }
}

@media (max-width: 992px) {
  .home-pricing-btm {
    padding-bottom: 20px;
  }
}



@media (max-width: 767px) {
  .home-header-gray {
    background: #e8ebef !important;
    box-shadow: 0px 0px 5px 5px rgba(23,25,27, 0.21);
    height: 64px !important;
  }
}

@media (max-width: 767px) {
  .row-104 {
    height: 64px !important;
  }
}

@media (max-width: 767px) {
  .hamb-div {
    top: 3px;
  }
}

@media (max-width: 767px) {
  .home-img-logo {
    width: 126px;
  }
}

@media (max-width: 767px) {
  .home-f-section {
    display: none;
  }
}

@media (max-width: 767px) {
  .section-mbl-quemsomos {
    display: block;
  }
}

@media (max-width: 767px) {
  .video-ply-btn {
    padding-top: 18px;
    padding-left: 0;
    text-align: center;
    padding-bottom: 42px;
  }
}

@media (max-width: 767px) {
  .video-ply-btn a {
    width: 90px;
    height: 90px;
    line-height: 90px;
  }
}

@media (max-width: 767px) {
  .video-ply-btn a:before {
    height: 90px;
    width: 90px;
  }
}

@media (max-width: 767px) {
  .video-ply-btn a:after {
    height: 90px;
    width: 90px;
  }
}

@media (max-width: 767px) {
  .home-box-cats {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 767px) {
  .home-box-cats img {
    width: 34px;
    height: 34px;
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .home-box-cats h3 {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .home-pricing-top {
    padding: 71px 0 265px;
  }
}

.btn-menu-mbl-register button  {
  background: #0e6fa5 !important;
  border-radius: 30px;
  color: #fff !important;
  border: none;
  text-decoration: none;
  padding: 10px 20px 10px 20px !important;
  display: block;
  font-size: 16px;
  font-weight: bold;
}

.btn-menu-mbl-register button:after  {
  display: none;
}

.btn-menu-mbl-login a  {
  background: #ff0e21 !important;
  border-radius: 30px;
  color: #fff !important;
  border: none;
  text-decoration: none;
  padding: 10px 20px 10px 20px !important;
  display: block;
  font-size: 16px;
  font-weight: bold;
  width: 116px;
  text-align: center;
}

@media (max-width: 767px) {
  .sep-welcome-first {
    display: none;
  }
}

.home-launch-h-mbl {
  padding-left: 20px;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.home-launch-date-mbl {
  padding-left: 20px;
  font-size: 46px;
  margin-bottom: 0px;
  font-weight: 700;
  position: relative;
  color: #fff;
  text-align: center;
}

.home-launch-date-mbl span {
  font-size: 16px;
  top: -20px;
  position: relative;
}

@media (max-width: 767px) {
  .home-blue-right-title.about {
    border-radius: 12px;
    text-align: center;
    right: initial;
  }
}

@media (max-width: 767px) {
  .home-blue-right-title.about {
    font-size: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .home-wrapper-coach-txts.about {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .home-blue-right-title.contact {
    border-radius: 12px;
    text-align: center;
    right: initial;
  }
}

@media (max-width: 767px) {
  .home-blue-right-title.contact {
    font-size: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .home-wrapper-coach-txts.contact {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .home-contact-topics {
    position: relative;
    background: #08476b;
    width: 100%;
    color: #fff;
    padding: 0px;
    right: initial;
  }
}

@media (max-width: 767px) {
  .home-contact-topics h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    padding: 12px 4px 12px 0px;
    overflow: hidden;
  }
}

.btn-menu-mbl-register .dropdown-item{
  background: #fff !important;
  color: #000 !important;
}



