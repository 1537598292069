.ath-mb-menu {
  display: none;
  position: absolute;
  width: 115px;
  height: 50px;
  top: 6px;
  right: 20px;
}

.ath-mb-menu button {
  border: none;
}

.ath-mb-menu button i {
  font-size: 29px;
  color: #0c6899;
}

.list-group-top-menu {
  margin-right: 22px;
  background: rgba(131,131,131, 0.0) !important;
  margin-top: 4px;
  border-radius: 10px !important;
}

.list-group-top-menu:hover {
  background: #000;
  background: rgba(131,131,131, 0.1) !important;
}

.list-group-top-menu a {
  margin-top: 2px;
}

.list-group-top-menu a .div-img {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
}

.list-group-top-menu a .div-img img {
  margin-top: 4px;
  width: 30px;
  display: block;
}

.list-group-top-menu a span {
  font-size: 13px;
  font-weight: bold;
  color: #333c49;
  padding-top: 8px;
}

.icon-not-top {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(131,131,131, 0.1);
  padding-top: 8px;
  text-align: center;
  position: relative;
  border: none !important;
}

.icon-not-top i {
  font-size: 24px;
  color: #0c6899;
}

.icon-not-top svg {
  font-size: 24px;
  color: #0c6899;
}

.icon-not-top span {
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 24px;
  height: 24px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50%;
  background: #fe0000;
  text-align: center;
  font-size: 13px;
  padding-top: 3px;
}

.f-section {
  margin-top: 64px;
}

.col-menu-top a {
  text-decoration: none;
  padding: 20px 40px 20px 40px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}

.col-menu-top a .tooltiptext-BCK {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0,0,0, 0.0);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.col-menu-top a:hover .tooltiptext-BCK {
  visibility: visible;
  background-color: rgba(0,0,0, 0.8);
}

.col-menu-top a i {
  font-size: 22px;
}

.col-menu-top a svg {
  font-size: 22px;
}

.col-menu-top a.active {
  color: #0c6899;
  font-weight: bold;
  /*border-bottom: 3px #0c6899 solid;*/
  position: relative;
}

.col-menu-top .active:after {
  height: 3px;
  width: 100%;
  background: #0c6899;
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  bottom: -3px;
}

.div-logo {
  width: 266px;
  height: auto;
  top: 23px;
}

.div-logo-scrolled {
  top: 2px;
}

.img-logo {
  width: 266px;
}

.img-logo-scrolled {
  width: 200px;
}

.ath-f-col {
  padding-top: 0px !important;
  position: relative;
}

.side-left-col {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 300px;
  left: 0px;
}

.side-right-col {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 280px;
  right: 15px;
}

.list-group-ath-left {
  border-radius: 0px;
  width: 92%;
}

.list-group-ath-left li {
  margin-bottom: 1px;
  background: rgba(131,131,131, 0.0);
  border: none;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px 6px !important;
  padding: 0px;
}

.list-group-ath-left li:hover {
  background: rgba(131,131,131, 0.1);
}

.list-group-ath-left li a {
  display: block;
  /*background: #fff;*/
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  cursor: pointer;
}

.list-group-ath-left li a div {
  width: 32px;
  height: 32px;
  position: relative;
}

.list-group-ath-left li a div .tag {
  width: 15px;
  height: 15px;
  color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  background: #fe0000;
  text-align: center;
  position: absolute;
  left: 25px;
  top: -4px;
  z-index: 10;
}

.list-group-ath-left li a div img {
  width: 32px;
}

.list-group-ath-left li a span {
  font-size: 14px;
  font-weight: bold;
  color: #333c49;
  text-decoration: none;
  padding-top: 4px;
  padding-left: 6px;
}

.list-group-ath-left li:hover a span {
  padding-left: 10px;
}

.div-top-btns {
  margin-right: 20px;
}

.ath-main-banner {
  width: 100%;
  height: 300px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  position: relative;
}

.ath-card-main .bottom h3 {
  font-size: 14px;
  color: #ffffff;
  text-align: left;
}

.ath-card-main {
  position: relative;
  width: 220px;
  height: 220px;
  overflow: hidden;
  margin: 0 auto;
  top: 51%;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #0c6899 solid;
}

.ath-card-main-profile {
  overflow: hidden;
  margin: 0 auto;
  top: 20px;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #0c6899 solid;
}

.ath-card-main-profile .top .detail-left {
  display: none;
}

.ath-card-main .top {
  height: 220px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: radial-gradient(#0e6fa7, #031621 100%);
}

.ath-card-main .top .logo {
  position: absolute;
  left: -15px;
  top: -10px;
  z-index: 1;
}

.ath-card-main .top .logo img {
  width: 110px;
}

.ath-card-main .top .detail-left {
  position: absolute;
  left: -8px;
  bottom: 3px;
  z-index: 1;
}

.ath-card-main .top .detail-left h4 {
  position: absolute;
  top: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  left: 14px;
  line-height: 16px;
}

.ath-card-main .top .detail-right {
  position: absolute;
  right: -14px;
  bottom: -12px;
  z-index: 1;
}

.ath-card-main .top .detail-right img {
  width: 67px;
}

.ath-card-main .bottom {
  background: #202020;
  position: relative;
  width: 100%;
  height: 162px;
}

.ath-card-main .bottom h2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.ath-sep-90 {
  width: 100%;
  height: 90px;
}

.row-mdl-titles-box {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 4px;
}

.ath-home-tscholl {
  /*width: 100%;*/
  background: #ccc;
}

.ath-lft-box-university-main {
  position: relative;
  /*width: 266px;*/
  height: 198px;
  margin: 0 auto;
  width: 100%;
}

.ath-lft-box-university-main .ath-lft-box-university {
  /*width: 266px;*/
  height: 186px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-width: 266px;
}

.ath-lft-box-university-main .ath-lft-box-university .bg-img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.ath-lft-box-university-main .ath-lft-box-university .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(0,0,0,0.5);
}

.ath-lft-box-university-main .div-logo-univer {
  width: 80px;
  height: 80px;
  background: #ffffff;
  position: absolute;
  right: 10px;
  bottom: 27px;
  z-index: 2;
  border-radius: 2px;
}

.ath-lft-box-university-main .div-logo-univer img {
  width: 60px;
  margin: 0 auto;
  display: block;
  margin-top: 9px;
}

.ath-menu-bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  background: #e8ebef;
  z-index: 50;
  display: none;
}

.col-menu-bottom a {
  text-decoration: none;
  padding: 4px 15px 20px 15px;
  display: block;
  color: #434c58;
  font-size: 23px;
  font-weight: bold;
}

.col-menu-bottom a.active {
  color: #0c6899;
  font-weight: bold;
  position: relative;
}

.col-menu-bottom .active:after {
  height: 3px;
  width: 100%;
  background: #0c6899;
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
}

.modal-menu {
  background: #e8ebef !important;
  z-index: 1059 !important;
}

.modal-menu .modal-dialog {
  background: #e8ebef !important;
}

.modal-menu .modal-content {
  background: #e8ebef !important;
}

.btn-close-modal-menu {
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 10;
  right: 10px;
  color: #fe0000;
  font-size: 37px;
}

.btn.btn-close-modal-menu:hover {
  color: #fe0000;
}

.side-left-col-modal {
  width: 100%;
  max-width: 300px;
  left: 0px;
}

.sponsored-mb {
  margin-bottom: 16px;
  display: none;
}

.ath-pages-main-titles {
  font-size: 1.25rem !important;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 4px;
}

.ath-profile-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 21px;
  text-align: center;
}

.ath-white-mdl-box hr {
  background-color: #bbbbbb !important;
}

.ath-white-mdl-tml-box hr {
  background-color: #bbbbbb !important;
}

.ath-control-info-texts {
  margin-bottom: 2px;
}

.ath-control-info-texts h4 {
  font-size: 13px;
  color: rgb(124,125,127);
  margin-bottom: 0px;
}

.ath-control-info-texts h3 {
  font-size: 16px;
  color: #333c49;
  font-weight: bold;
}

.heading-titles {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 21px;
  text-align: left;
}

.ath-white-mdl-box .un-shield {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 40px;
  height: 40px;
}

.ath-white-mdl-box .un-shield img {
  width: 40px;
  height: 40px;
}

.btn-add-target {
  border: none;
  border-radius: 6px;
  font-size: 13px;
  padding: 2px 6px 2px 6px;
  color: #58606a;
  background: rgba(131,131,131, 0.1);
  font-weight: bold;
}

.btn-add-target img {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

.btn-add-target span {
  padding-left: 0px;
}

.btn-add-target:hover span {
  padding-left: 6px;
}

.btn-add-target.active {
  color: #2fa72f !important;
  font-weight: bold;
}

.ath-card-univ {
  position: absolute;
  width: 220px;
  height: 220px;
  /*overflow: hidden;*/
  /*margin: 0 auto;*/
  top: 44%;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #ffffff solid;
  background: #ffffff;
  right: 30px !important;
}

.ath-card-univ .inner {
  width: 210px;
  height: 210px;
  display: block;
  margin: 0 auto;
  background: #ffffff;
  border: 5px #0c6899 solid;
  border-radius: 16px;
}

.ath-u-gray-box {
  border-radius: 6px;
  background: #333333;
  width: 100%;
}

.ath-u-gray-box .first {
  width: 35%;
  mIN-width: 166px;
  background: #474948;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ath-u-gray-box .first h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 0px;
}

.ath-u-gray-box .first h2 {
  font-size: 13px;
  color: #ffffff;
}

.ath-u-gray-box .circle-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  background: #3a3a3a;
  margin-bottom: 2px;
  line-height: 40px;
}

.ath-u-gray-box .circle-box i {
  font-size: 18px;
}

.ath-u-gray-box .second {
  width: 67%;
  background: #333333;
  padding: 5px 36px 5px 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ath-u-gray-box .second span {
  font-size: 13px;
  color: #71777e;
  font-weight: bold;
}

.ath-u-gray-box .second .bar {
  height: 14px;
  position: relative;
  margin-bottom: 2px;
}

.ath-u-gray-box .second .bar .number {
  position: absolute;
  top: -6px;
  right: -36px;
  text-align: left;
  width: 30px;
}

.ath-u-gray-box .second .bar .number span {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}

.ath-sep-u {
  margin-bottom: 30px;
}

.title-u {
  font-weight: bold;
  color: #333c49;
  text-align: left;
  margin-top: 24px;
  font-size: 30px;
  margin-bottom: 4px;
}

.subtitle-u {
  margin-top: 0px;
  font-size: 18px;
  color: #333c49;
  margin-bottom: 10px;
}

.ath-u-banner-mdl {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.ath-u-banner-mdl .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.ath-u-banner-mdl .row-mdl {
  position: relative;
  z-index: 2;
}

.ath-u-banner-mdl .row-mdl h2 {
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
}

.ath-u-banner-mdl .row-mdl p {
  color: #ffffff;
}

.white-btns {
  border: 1px #ffffff solid;
  border-radius: 4px;
  padding: 4px 10px 4px 10px;
  color: #ffffff;
  font-size: 13px;
  margin-right: 4px;
}

.white-btns:hover {
  color: #ffffff;
}

.ath-u-news-row .left-img {
  margin-left: 10px;
}

.ath-min-title-blue {
  color: #021132;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ath-u-news-row .ath-min-title {
  font-size: 13px;
}

.ath-nav-media > li > a {
  color: #1e71a1 !important;
  border: 1px #ffffff solid !important;
  border-radius: 6px;
  font-size: 14px;
  /*margin: 0 auto;*/
  background: #ffffff;
}

.ath-nav-media > li > a.active {
  color: #1e71a1 !important;
  border: 1px #1e71a1 solid !important;
  border-radius: 6px;
  font-size: 14px;
  margin: 0 auto;
  background: #ffffff !important;
}

.ath-btn-media-erase {
  position: absolute;
  top: 4px;
  right: 12px;
  background: rgba(255,255,255,0.5);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  padding: 0px;
  border: none;
}

.ath-btn-media-erase:hover {
  background: rgba(255,255,255,1);
}

.ath-btn-media-erase i {
  font-size: 18px;
  color: darkred;
}

.ath-btn-media-erase svg {
  font-size: 18px;
  color: darkred;
}

.m-video-l {
  cursor: pointer;
}

.noti-drop {
  border: none;
  border-radius: 6px;
}

.noti-drop li a {
  font-size: 12px;
  color: #58606a;
  padding: 10px;
  text-transform: uppercase;
}

.noti-drop hr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.noti-drop .btn-blue-border-normal {
  color: #1e71a1 !important;
  font-weight: normal;
}

.row-mdl-box {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 4px;
}

.ath-contact-f {
  width: 76px;
}

.ath-contact-f .ath-contact-tbn-circle-shield {
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
}

.ath-contact-f .ath-contact-tbn-circle {
  width: 60px;
  height: 60px;
  background: #1e71a1;
  border-radius: 50%;
  position: relative;
}

.ath-contact-f .ath-contact-tbn-circle-shield .thumb-shield {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
  border: 2px #fff solid;
}

.ath-contact-f .ath-contact-tbn-circle .thumb {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
  border: 2px #fff solid;
}

.ath-contact-f .ath-contact-tbn-circle .thumb .shield {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -17px;
  right: -7px;
  background: #fff;
  border-radius: 50%;
}

.ath-contact-f .ath-contact-tbn-circle .thumb .shield img {
  width: 83%;
  margin: 0 auto;
  display: block;
  margin-top: 3px;
}

.ath-contact-s h2 {
  color: rgb(124,125,127);
  font-size: 16px;
  font-weight: bold;
  position: relative;
  margin-top: 9px;
  margin-bottom: 0px;
}

.ath-contact-s h3 {
  font-size: 13px;
  color: rgb(124,125,127);
}

.ath-contact-t a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(124,125,127,0.1);
  color: #1e71a1;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 22px;
  margin-top: 6px;
}

.ath-contact-t a:hover {
  background: rgba(124,125,127,0.15);
}

.no-padding {
  padding: 0px;
}

.ath-white-mdl-box .div-img-event {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top-right-radius: 4px;
  position: relative;
  border-top-left-radius: 4px;
}

.left-scroller {
  height: 100vh;
}

.box-left .progress{
  margin-left: 7px;
  margin-right: 10px;
}

.box-left .progress-bar.bg-success{
  background: #5cb85c !important;
}

.right-scroller {
  height: 100vh;
}

.hold-un-bars {
  padding: 2px 36px 2px 16px;
}

.ath-mb-menu {
  display: none;
  position: absolute;
  width: 115px;
  height: 50px;
  top: 6px;
  right: 20px;

}

.ath-mb-menu button {
  border: none;
}

.ath-mb-menu button i {
  font-size: 29px;
  color: #0c6899;
}

.ath-mb-menu button svg {
  font-size: 29px;
  color: #0c6899;
}

.list-group-top-menu {
  margin-right: 22px;
  background: rgba(131,131,131, 0.0) !important;
  margin-top: 4px;
  border-radius: 10px !important;
}

.list-group-top-menu:hover {
  background: #000;
  background: rgba(131,131,131, 0.1) !important;
}

.list-group-top-menu a {
  margin-top: 2px;
}

.list-group-top-menu a .div-img {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
}

.list-group-top-menu a .div-img img {
  margin-top: 4px;
  width: 30px;
  display: block;
}

.list-group-top-menu a span {
  font-size: 13px;
  font-weight: bold;
  color: #333c49;
  padding-top: 8px;
}

.icon-not-top {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(131,131,131, 0.1);
  padding-top: 8px;
  text-align: center;
  position: relative;
  border: none !important;
}

.icon-not-top i {
  font-size: 24px;
  color: #0c6899;
}

.icon-not-top span {
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 24px;
  height: 24px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50%;
  background: #fe0000;
  text-align: center;
  font-size: 13px;
  padding-top: 3px;
}

.f-section {
  margin-top: 64px;
}

.col-menu-top a {
  text-decoration: none;
  padding: 20px 40px 20px 40px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}

.col-menu-top a .tooltiptext-BCK {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0,0,0, 0.0);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.col-menu-top a:hover .tooltiptext-BCK {
  visibility: visible;
  background-color: rgba(0,0,0, 0.8);
}

.col-menu-top a i {
  font-size: 22px;
}

.col-menu-top a.active {
  color: #0c6899;
  font-weight: bold;
  /*border-bottom: 3px #0c6899 solid;*/
  position: relative;
}

.col-menu-top .active:after {
  height: 3px;
  width: 100%;
  background: #0c6899;
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  bottom: -3px;
}

.div-logo {
  width: 266px;
  height: auto;
  top: 23px;
}

.div-logo-scrolled {
  top: 2px;
}

.img-logo {
  width: 266px;
}

.img-logo-scrolled {
  width: 200px;
}

.ath-f-col {
  padding-top: 0px !important;
  position: relative;
}

.side-left-col {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 300px;
  left: 0px;
}

.side-right-col {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 280px;
  right: 15px;
}

.list-group-ath-left {
  border-radius: 0px;
  width: 92%;
}

.list-group-ath-left li {
  margin-bottom: 1px;
  background: rgba(131,131,131, 0.0);
  border: none;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px 6px !important;
  padding: 0px;
}

.list-group-ath-left li:hover {
  background: rgba(131,131,131, 0.1);
}

.list-group-ath-left li a {
  display: block;
  /*background: #fff;*/
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  cursor: pointer;
}

.list-group-ath-left li a div {
  width: 32px;
  height: 32px;
  position: relative;
}

.list-group-ath-left li a div .tag {
  width: 15px;
  height: 15px;
  color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  background: #fe0000;
  text-align: center;
  position: absolute;
  left: 25px;
  top: -4px;
  z-index: 10;
}

.list-group-ath-left li a div img {
  width: 32px;
}

.list-group-ath-left li a span {
  font-size: 14px;
  font-weight: bold;
  color: #333c49;
  text-decoration: none;
  padding-top: 4px;
  padding-left: 6px;
}

.list-group-ath-left li:hover a span {
  padding-left: 10px;
}

.div-top-btns {
  margin-right: 20px;
}

.ath-main-banner {
  width: 100%;
  height: 300px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  position: relative;
}

.ath-card-main .bottom h3 {
  font-size: 14px;
  color: #ffffff;
  text-align: left;
}

.ath-card-main {
  position: relative;
  width: 220px;
  height: 220px;
  overflow: hidden;
  margin: 0 auto;
  top: 51%;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #0c6899 solid;
}

.ath-card-main-profile {
  overflow: hidden;
  margin: 0 auto;
  top: 20px;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #0c6899 solid;
}

.ath-card-main-profile .top .detail-left {
  display: none;
}

.ath-card-main .top {
  height: 220px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: radial-gradient(#0e6fa7, #031621 100%);
}

.ath-card-main .top .logo {
  position: absolute;
  left: -15px;
  top: -10px;
  z-index: 1;
}

.ath-card-main .top .logo img {
  width: 110px;
}

.ath-card-main .top .detail-left {
  position: absolute;
  left: -8px;
  bottom: 3px;
  z-index: 1;
}

.ath-card-main .top .detail-left h4 {
  position: absolute;
  top: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  left: 14px;
  line-height: 16px;
}

.ath-card-main .top .detail-right {
  position: absolute;
  right: -14px;
  bottom: -12px;
  z-index: 1;
}

.ath-card-main .top .detail-right img {
  width: 67px;
}

.ath-card-main .bottom {
  background: #202020;
  position: relative;
  width: 100%;
  height: 162px;
}

.ath-card-main .bottom h2 {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.ath-sep-90 {
  width: 100%;
  height: 90px;
}

.row-mdl-titles-box {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 4px;
}

.ath-home-tscholl {
  /*width: 100%;*/
  background: #ccc;
}

.ath-lft-box-university-main {
  position: relative;
  /*width: 266px;*/
  height: 198px;
  margin: 0 auto;
  width: 100%;
}

.ath-lft-box-university-main .ath-lft-box-university {
  /*width: 266px;*/
  height: 186px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-width: 266px;
}

.ath-lft-box-university-main .ath-lft-box-university .bg-img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.ath-lft-box-university-main .ath-lft-box-university .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(0,0,0,0.5);
}

.ath-lft-box-university-main .div-logo-univer {
  width: 80px;
  height: 80px;
  background: #ffffff;
  position: absolute;
  right: 10px;
  bottom: 27px;
  z-index: 2;
  border-radius: 2px;
}

.ath-lft-box-university-main .div-logo-univer img {
  width: 60px;
  margin: 0 auto;
  display: block;
  margin-top: 9px;
}

.ath-menu-bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  background: #e8ebef;
  z-index: 50;
  display: none;
}

.col-menu-bottom a {
  text-decoration: none;
  padding: 4px 15px 20px 15px;
  display: block;
  color: #434c58;
  font-size: 23px;
  font-weight: bold;
}

.col-menu-bottom a.active {
  color: #0c6899;
  font-weight: bold;
  position: relative;
}

.col-menu-bottom .active:after {
  height: 3px;
  width: 100%;
  background: #0c6899;
  display: block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
}

.modal-menu {
  background: #e8ebef !important;
  z-index: 1059 !important;
}

.modal-menu .modal-dialog {
  background: #e8ebef !important;
}

.modal-menu .modal-content {
  background: #e8ebef !important;
}

.btn-close-modal-menu {
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 10;
  right: 10px;
  color: #fe0000;
  font-size: 37px;
}

.btn.btn-close-modal-menu:hover {
  color: #fe0000;
}

.side-left-col-modal {
  width: 100%;
  max-width: 300px;
  left: 0px;
}

.sponsored-mb {
  margin-bottom: 16px;
  display: none;
}

.ath-pages-main-titles {
  font-size: 1.25rem !important;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 4px;
}

.ath-profile-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 21px;
  text-align: center;
}

.ath-white-mdl-box hr {
  background-color: #bbbbbb !important;
}

.ath-white-mdl-tml-box hr {
  background-color: #bbbbbb !important;
}

.ath-control-info-texts {
  margin-bottom: 2px;
}

.ath-control-info-texts h4 {
  font-size: 13px;
  color: rgb(124,125,127);
  margin-bottom: 0px;
}

.ath-control-info-texts h3 {
  font-size: 16px;
  color: #333c49;
  font-weight: bold;
}

.heading-titles {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333c49;
  margin-bottom: 21px;
  text-align: left;
}

.ath-white-mdl-box .un-shield {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 40px;
  height: 40px;
}

.ath-white-mdl-box .un-shield img {
  width: 40px;
  height: 40px;
}

.btn-add-target {
  border: none;
  border-radius: 6px;
  font-size: 13px;
  padding: 2px 6px 2px 6px;
  color: #58606a;
  background: rgba(131,131,131, 0.1);
  font-weight: bold;
}

.btn-add-target img {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

.btn-add-target span {
  padding-left: 0px;
}

.btn-add-target:hover span {
  padding-left: 6px;
}

.btn-add-target.active {
  color: #2fa72f !important;
  font-weight: bold;
}

.ath-card-univ {
  position: absolute;
  width: 220px;
  height: 220px;
  /*overflow: hidden;*/
  /*margin: 0 auto;*/
  top: 44%;
  border-radius: 16px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  border: 5px #ffffff solid;
  background: #ffffff;
  right: 30px !important;
}

.ath-card-univ .inner {
  width: 210px;
  height: 210px;
  display: block;
  margin: 0 auto;
  background: #ffffff;
  border: 5px #0c6899 solid;
  border-radius: 16px;
}

.ath-u-gray-box {
  border-radius: 6px;
  background: #333333;
  width: 100%;
}

.ath-u-gray-box .first {
  width: 35%;
  mIN-width: 166px;
  background: #474948;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ath-u-gray-box .first h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 0px;
}

.ath-u-gray-box .first h2 {
  font-size: 13px;
  color: #ffffff;
}

.ath-u-gray-box .circle-box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  background: #3a3a3a;
  margin-bottom: 2px;
  line-height: 40px;
}

.ath-u-gray-box .circle-box i {
  font-size: 18px;
}

.ath-u-gray-box .second {
  width: 67%;
  background: #333333;
  padding: 5px 36px 5px 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ath-u-gray-box .second span {
  font-size: 13px;
  color: #71777e;
  font-weight: bold;
}

.ath-u-gray-box .second .bar {
  height: 14px;
  position: relative;
  margin-bottom: 2px;
}

.ath-u-gray-box .second .bar .number {
  position: absolute;
  top: -6px;
  right: -36px;
  text-align: left;
  width: 30px;
}

.ath-u-gray-box .second .bar .number span {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}

.ath-sep-u {
  margin-bottom: 30px;
}

.title-u {
  font-weight: bold;
  color: #333c49;
  text-align: left;
  margin-top: 24px;
  font-size: 30px;
  margin-bottom: 4px;
}

.subtitle-u {
  margin-top: 0px;
  font-size: 18px;
  color: #333c49;
  margin-bottom: 10px;
}



.white-btns {
  border: 1px #ffffff solid;
  border-radius: 4px;
  padding: 4px 10px 4px 10px;
  color: #ffffff;
  font-size: 13px;
  margin-right: 4px;
}

.white-btns:hover {
  color: #ffffff;
}

.ath-u-news-row .left-img {
  margin-left: 10px;
}

.ath-min-title-blue {
  color: #021132;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ath-u-news-row .ath-min-title {
  font-size: 13px;
}

.ath-nav-media > li > a {
  color: #1e71a1 !important;
  border: 1px #ffffff solid !important;
  border-radius: 6px;
  font-size: 14px;
  /*margin: 0 auto;*/
  background: #ffffff;
}

.ath-nav-media > li > a.active {
  color: #1e71a1 !important;
  border: 1px #1e71a1 solid !important;
  border-radius: 6px;
  font-size: 14px;
  margin: 0 auto;
  background: #ffffff !important;
}

.ath-btn-media-erase {
  position: absolute;
  top: 4px;
  right: 12px;
  background: rgba(255,255,255,0.5);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  padding: 0px;
  border: none;
}

.ath-btn-media-erase:hover {
  background: rgba(255,255,255,1);
}

.ath-btn-media-erase i {
  font-size: 18px;
  color: darkred;
}

.m-video-l {
  cursor: pointer;
}

.noti-drop {
  border: none;
  border-radius: 6px;
}

.noti-drop li a {
  font-size: 12px;
  color: #58606a;
  padding: 10px;
  text-transform: uppercase;
}

.noti-drop hr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.noti-drop .btn-blue-border-normal {
  color: #1e71a1 !important;
  font-weight: normal;
}

.row-mdl-box {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 4px;
}

.ath-contact-f {
  width: 76px;
}

.ath-contact-f .ath-contact-tbn-circle-shield {
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
}

.ath-contact-f .ath-contact-tbn-circle {
  width: 60px;
  height: 60px;
  background: #1e71a1;
  border-radius: 50%;
  position: relative;
}

.ath-contact-f .ath-contact-tbn-circle-shield .thumb-shield {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
  border: 2px #fff solid;
}

.ath-contact-f .ath-contact-tbn-circle .thumb {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  border-radius: 50%;
  border: 2px #fff solid;
}

.ath-contact-f .ath-contact-tbn-circle .thumb .shield {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -17px;
  right: -7px;
  background: #fff;
  border-radius: 50%;
}

.ath-contact-f .ath-contact-tbn-circle .thumb .shield img {
  width: 83%;
  margin: 0 auto;
  display: block;
  margin-top: 3px;
}

.ath-contact-s h2 {
  color: rgb(124,125,127);
  font-size: 16px;
  font-weight: bold;
  position: relative;
  margin-top: 9px;
  margin-bottom: 0px;
}

.ath-contact-s h3 {
  font-size: 13px;
  color: rgb(124,125,127);
}

.ath-contact-t a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(124,125,127,0.1);
  color: #1e71a1;
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 22px;
  margin-top: 6px;
}

.ath-contact-t a:hover {
  background: rgba(124,125,127,0.15);
}

.no-padding {
  padding: 0px;
}

.ath-white-mdl-box .div-img-event {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top-right-radius: 4px;
  position: relative;
  border-top-left-radius: 4px;
}

.hold-un-bars {
  padding: 2px 36px 2px 16px;
}

.hold-un-bars span {
  color: #333c49;
  font-size: 13px;
  font-weight: bold;
}

.hold-un-bars .bar{
  height: 14px;
  position: relative;
  margin-bottom: 2px;
}

.hold-un-bars .bar .number{
  position: absolute;
  top: -7px;
  right: -36px;
  text-align: left;
  width: 30px;
}


.ath-filter-wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.ath-filter-wrapper .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.ath-filter-wrapper label {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.ath-filter-wrapper .label-select {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.ath-filter-wrapper .bootstrap-select > .dropdown-toggle {
  background: #fff;
  border: 1px solid #ced4da;
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.ath-filter-wrapper input[type="number"] {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.ath-filter-wrapper input[type="text"] {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.ath-filter-wrapper input[type="date"] {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.selectpicker {
}

.btn-edit-card-photo-sport {
  background: rgba(255,255,255,0.4);
  border: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 14px;
  right: 4px;
  text-align: center;
  padding: 0px;
}

.btn-edit-card-photo-sport:hover {
  background: rgba(255,255,255,1);
}

.btn-edit-cover-photo-sport {
  background: rgba(255,255,255,0.4);
  border: none;
  border-radius: 6px;
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 6px;
  font-size: 13px;
  font-weight: bold;
}

.btn-edit-cover-photo-sport i {
  font-size: 16px;
}

.btn-edit-cover-photo-sport:hover {
  background: rgba(255,255,255,1);
}

.form-floating-select {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
}

.m-card-main.ath-card-spo .bottom {
  height: 163px;
}

.m-card-main.ath-card-spo .top {
  height: 320px;
}

.m-card-main.ath-card-spo .bottom h2 {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.m-card-main.ath-card-spo .bottom h2 span {
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  font-weight: normal;
}

.ath-main-banner.event {
  height: 400px;
  margin-bottom: 2px !important;
}

.ath-top-titles-event {
  margin-bottom: 20px;
  text-align: left;
}

.ath-top-titles-event i {
  color: rgba(51,60,73,0.30);
  font-size: 20px;
  padding-right: 5px;
}

.ath-top-titles-event svg {
  color: rgba(51,60,73,0.30);
  font-size: 24px;
  padding-right: 5px;
}

.ath-top-titles-event h1 {
  font-size: 32px !important;
  font-weight: bold;
  color: #333c49;
  margin-top: 24px;
  margin-bottom: 0px;
}

.ath-top-titles-event h2 {
  font-size: 24px !important;
  font-weight: 100 !important;
  color: #333c49;
  margin-top: 10px;
  margin-bottom: 0px;
}

.ath-top-titles-event h3 {
  font-size: 20px !important;
  font-weight: 100 !important;
  color: #333c49;
  margin-top: 10px;
  margin-bottom: 11px;
}

.ath-event-sponsores img {
  width: 138px;
}

.btn-blue-border-normal-no-margin {
  color: #1e71a1;
  border: 1px #1e71a1 solid;
  border-radius: 6px;
  font-size: 14px;
}

.floating-upload-field {
  padding-bottom: 0px !important;
  padding-top: 31px !important;
  height: 58px !important;
}

.btn-remove-line {
  border: none;
  border-radius: 6px;
  font-size: 19px;
  color: red;
  padding: 3px;
}

.btn-remove-line:hover {
  color: red;
}

.btn-ath-comum.large {
  font-size: 16px;
}

.btn-rgt-top-sport{
  position: relative;
  color: #ffffff;
  font-weight: bold;
  border-radius: 6px;
  border: 2px solid #ffffff;
  margin-right: 6px;
  margin-left: 6px;
  background: #000;
  line-height: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn-rgt-top-sport:hover{
  color: #ffffff;
}

.btn-rgt-top-sport span {
  font-size: 12px;
}

.btn-rgt-top-sport.active {
  color: #000000;
  background: #ffffff;
}

.btn-rgt-top-sport.active:hover {
  color: #000000;
}

.row-register .basic-multi-select{
  position: relative;
  z-index: 20 !important;
}

.row-register .selectpicker{

  border: none;
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;

}


.row-register .__indicator-separator{
  opacity: 0;
}

.row-register .basic-multi-selectGroup{
  position: relative;
  z-index: 20 !important;
}

.row-register .selectpickerGroup{
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 16px;

}

.row-register .selectpickerGroup .__placeholder{
  color: #464646 !important;
}

.prf-left-space{
  height: 64px;
}

.ath-card-univ.ath {
  top: 55%;
}

.ath-stats-details .nav-link{
  padding: 6px;
  cursor: pointer;
}

.m-card-main .bottom .ath-col-chcard-bottom h2{
  font-size: 14px;
  margin-bottom: 2px;
}

.m-card-main .bottom .ath-col-chcard-bottom h3{
  font-size: 13px;
  margin-bottom: 4px;
}

.m-card-main .bottom .ath-col-chcard-bottom .match-option{
  position: relative;
  border-radius: 6px;
  /*border: 1px rgba(255,255,255,0.1) solid;*/
  /*background: rgba(255,255,255,0.0);*/
  /*color: rgba(255,255,255,0.1);*/
  border: 1px red solid;
  background: red;
  color: #fff;
  padding: 1px 2px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
}

.m-card-main .bottom .ath-col-chcard-bottom .match-option .icon{
  position: absolute;
  width: 12px;
  top: 2px;
  left: 2px;
}

.m-card-main .bottom .ath-col-chcard-bottom .match-option.active{
  border: 1px #5cb85c solid;
  background: #5cb85c;
  color: #fff;
}







