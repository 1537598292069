.main-chat-container{


  hr {
    margin: 1rem 0;
    color: #f5f8fb;
    background-color: currentColor;
    border: 0;
    opacity: 1;
  }
  hr:not([size]) {
    height: 2px;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.5;
    color: #293951;
  }
  .h1,
  h1 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 1.5rem;
    }
  }
  .h2,
  h2 {
    font-size: calc(1.2625rem + 0.15vw);
  }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 1.375rem;
    }
  }
  .h3,
  h3 {
    font-size: 1.125rem;
  }
  .h4,
  h4 {
    font-size: 1rem;
  }
  .h5,
  h5 {
    font-size: 0.9375rem;
  }
  .h6,
  h6 {
    font-size: 0.875rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  abbr[data-bs-original-title],
  abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  ol,
  ul {
    padding-left: 2rem;
  }
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  .small,
  small {
    font-size: 0.875em;
  }
  .mark,
  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #2787f5;
    text-decoration: underline;
  }
  a:hover {
    color: #1f6cc4;
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override;
  }
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #293951;
    border-radius: 0.4rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }
  figure {
    margin: 0 0 1rem;
  }
  img,
  svg {
    vertical-align: middle;
  }
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #bfccdf;
    text-align: left;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  label {
    display: inline-block;
  }
  button {
    border-radius: 0;
  }
  button:focus:not(:focus-visible) {
    outline: 0;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  select:disabled {
    opacity: 1;
  }
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }
  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }
  legend + * {
    clear: left;
  }
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  ::-webkit-inner-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::file-selector-button {
    font: inherit;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  iframe {
    border: 0;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden] {
    display: none !important;
  }
  .lead {
    font-size: 1.125rem;
    font-weight: 400;
  }
  .display-1 {
    font-size: calc(1.325rem + 0.9vw);
    font-weight: 500;
    line-height: 1.5;
  }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 2rem;
    }
  }
  .display-2 {
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 500;
    line-height: 1.5;
  }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 1.5rem;
    }
  }
  .display-3 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .display-4 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .display-5 {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .display-6 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 0.875em;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 0.5rem;
    font-size: inherit;
  }
  .blockquote > :last-child {
    margin-bottom: 0;
  }
  .blockquote-footer {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.875em;
    color: #95aac9;
  }
  .blockquote-footer::before {
    content: "— ";
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 2px solid #f5f8fb;
    border-radius: 0.6rem;
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 0.875em;
    color: #95aac9;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1.25rem);
    padding-left: var(--bs-gutter-x, 1.25rem);
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container,
    .container-sm {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1040px;
    }
  }
  @media (min-width: 1440px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1080px;
    }
  }
  .row {
    --bs-gutter-x: 2.5rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
  .row > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-1 {
    margin-left: 8.33333333%;
  }
  .offset-2 {
    margin-left: 16.66666667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333333%;
  }
  .offset-5 {
    margin-left: 41.66666667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333333%;
  }
  .offset-8 {
    margin-left: 66.66666667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333333%;
  }
  .offset-11 {
    margin-left: 91.66666667%;
  }
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0;
  }
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0;
  }
  .g-1,
  .gx-1 {
    --bs-gutter-x: 0.125rem;
  }
  .g-1,
  .gy-1 {
    --bs-gutter-y: 0.125rem;
  }
  .g-2,
  .gx-2 {
    --bs-gutter-x: 0.25rem;
  }
  .g-2,
  .gy-2 {
    --bs-gutter-y: 0.25rem;
  }
  .g-3,
  .gx-3 {
    --bs-gutter-x: 0.5rem;
  }
  .g-3,
  .gy-3 {
    --bs-gutter-y: 0.5rem;
  }
  .g-4,
  .gx-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-4,
  .gy-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-5,
  .gx-5 {
    --bs-gutter-x: 1rem;
  }
  .g-5,
  .gy-5 {
    --bs-gutter-y: 1rem;
  }
  .g-6,
  .gx-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-6,
  .gy-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-7,
  .gx-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-7,
  .gy-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-8,
  .gx-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-8,
  .gy-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-9,
  .gx-9 {
    --bs-gutter-x: 2rem;
  }
  .g-9,
  .gy-9 {
    --bs-gutter-y: 2rem;
  }
  .g-10,
  .gx-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-10,
  .gy-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-11,
  .gx-11 {
    --bs-gutter-x: 3rem;
  }
  .g-11,
  .gy-11 {
    --bs-gutter-y: 3rem;
  }
  .g-12,
  .gx-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-12,
  .gy-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-13,
  .gx-13 {
    --bs-gutter-x: 4rem;
  }
  .g-13,
  .gy-13 {
    --bs-gutter-y: 4rem;
  }
  .g-14,
  .gx-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-14,
  .gy-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-15,
  .gx-15 {
    --bs-gutter-x: 5rem;
  }
  .g-15,
  .gy-15 {
    --bs-gutter-y: 5rem;
  }
  @media (min-width: 576px) {
    .col-sm {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-sm-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-sm-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-sm-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-sm-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-sm-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-sm-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333333%;
    }
    .offset-sm-2 {
      margin-left: 16.66666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333333%;
    }
    .offset-sm-5 {
      margin-left: 41.66666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333333%;
    }
    .offset-sm-8 {
      margin-left: 66.66666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333333%;
    }
    .offset-sm-11 {
      margin-left: 91.66666667%;
    }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 0.125rem;
    }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 0.125rem;
    }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 0.25rem;
    }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 0.25rem;
    }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 0.5rem;
    }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 0.5rem;
    }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 0.75rem;
    }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 0.75rem;
    }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 1rem;
    }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 1rem;
    }
    .g-sm-6,
    .gx-sm-6 {
      --bs-gutter-x: 1.25rem;
    }
    .g-sm-6,
    .gy-sm-6 {
      --bs-gutter-y: 1.25rem;
    }
    .g-sm-7,
    .gx-sm-7 {
      --bs-gutter-x: 1.5rem;
    }
    .g-sm-7,
    .gy-sm-7 {
      --bs-gutter-y: 1.5rem;
    }
    .g-sm-8,
    .gx-sm-8 {
      --bs-gutter-x: 1.75rem;
    }
    .g-sm-8,
    .gy-sm-8 {
      --bs-gutter-y: 1.75rem;
    }
    .g-sm-9,
    .gx-sm-9 {
      --bs-gutter-x: 2rem;
    }
    .g-sm-9,
    .gy-sm-9 {
      --bs-gutter-y: 2rem;
    }
    .g-sm-10,
    .gx-sm-10 {
      --bs-gutter-x: 2.5rem;
    }
    .g-sm-10,
    .gy-sm-10 {
      --bs-gutter-y: 2.5rem;
    }
    .g-sm-11,
    .gx-sm-11 {
      --bs-gutter-x: 3rem;
    }
    .g-sm-11,
    .gy-sm-11 {
      --bs-gutter-y: 3rem;
    }
    .g-sm-12,
    .gx-sm-12 {
      --bs-gutter-x: 3.5rem;
    }
    .g-sm-12,
    .gy-sm-12 {
      --bs-gutter-y: 3.5rem;
    }
    .g-sm-13,
    .gx-sm-13 {
      --bs-gutter-x: 4rem;
    }
    .g-sm-13,
    .gy-sm-13 {
      --bs-gutter-y: 4rem;
    }
    .g-sm-14,
    .gx-sm-14 {
      --bs-gutter-x: 4.5rem;
    }
    .g-sm-14,
    .gy-sm-14 {
      --bs-gutter-y: 4.5rem;
    }
    .g-sm-15,
    .gx-sm-15 {
      --bs-gutter-x: 5rem;
    }
    .g-sm-15,
    .gy-sm-15 {
      --bs-gutter-y: 5rem;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-md-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-md-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-md-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-md-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-md-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-md-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333333%;
    }
    .offset-md-2 {
      margin-left: 16.66666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333333%;
    }
    .offset-md-5 {
      margin-left: 41.66666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333333%;
    }
    .offset-md-8 {
      margin-left: 66.66666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333333%;
    }
    .offset-md-11 {
      margin-left: 91.66666667%;
    }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 0.125rem;
    }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 0.125rem;
    }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 0.25rem;
    }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 0.25rem;
    }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 0.5rem;
    }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 0.5rem;
    }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 0.75rem;
    }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 0.75rem;
    }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 1rem;
    }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 1rem;
    }
    .g-md-6,
    .gx-md-6 {
      --bs-gutter-x: 1.25rem;
    }
    .g-md-6,
    .gy-md-6 {
      --bs-gutter-y: 1.25rem;
    }
    .g-md-7,
    .gx-md-7 {
      --bs-gutter-x: 1.5rem;
    }
    .g-md-7,
    .gy-md-7 {
      --bs-gutter-y: 1.5rem;
    }
    .g-md-8,
    .gx-md-8 {
      --bs-gutter-x: 1.75rem;
    }
    .g-md-8,
    .gy-md-8 {
      --bs-gutter-y: 1.75rem;
    }
    .g-md-9,
    .gx-md-9 {
      --bs-gutter-x: 2rem;
    }
    .g-md-9,
    .gy-md-9 {
      --bs-gutter-y: 2rem;
    }
    .g-md-10,
    .gx-md-10 {
      --bs-gutter-x: 2.5rem;
    }
    .g-md-10,
    .gy-md-10 {
      --bs-gutter-y: 2.5rem;
    }
    .g-md-11,
    .gx-md-11 {
      --bs-gutter-x: 3rem;
    }
    .g-md-11,
    .gy-md-11 {
      --bs-gutter-y: 3rem;
    }
    .g-md-12,
    .gx-md-12 {
      --bs-gutter-x: 3.5rem;
    }
    .g-md-12,
    .gy-md-12 {
      --bs-gutter-y: 3.5rem;
    }
    .g-md-13,
    .gx-md-13 {
      --bs-gutter-x: 4rem;
    }
    .g-md-13,
    .gy-md-13 {
      --bs-gutter-y: 4rem;
    }
    .g-md-14,
    .gx-md-14 {
      --bs-gutter-x: 4.5rem;
    }
    .g-md-14,
    .gy-md-14 {
      --bs-gutter-y: 4.5rem;
    }
    .g-md-15,
    .gx-md-15 {
      --bs-gutter-x: 5rem;
    }
    .g-md-15,
    .gy-md-15 {
      --bs-gutter-y: 5rem;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-lg-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-lg-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-lg-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-lg-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-lg-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-lg-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333333%;
    }
    .offset-lg-2 {
      margin-left: 16.66666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333333%;
    }
    .offset-lg-5 {
      margin-left: 41.66666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333333%;
    }
    .offset-lg-8 {
      margin-left: 66.66666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333333%;
    }
    .offset-lg-11 {
      margin-left: 91.66666667%;
    }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 0.125rem;
    }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 0.125rem;
    }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 0.25rem;
    }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 0.25rem;
    }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 0.5rem;
    }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 0.5rem;
    }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 0.75rem;
    }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 0.75rem;
    }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 1rem;
    }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 1rem;
    }
    .g-lg-6,
    .gx-lg-6 {
      --bs-gutter-x: 1.25rem;
    }
    .g-lg-6,
    .gy-lg-6 {
      --bs-gutter-y: 1.25rem;
    }
    .g-lg-7,
    .gx-lg-7 {
      --bs-gutter-x: 1.5rem;
    }
    .g-lg-7,
    .gy-lg-7 {
      --bs-gutter-y: 1.5rem;
    }
    .g-lg-8,
    .gx-lg-8 {
      --bs-gutter-x: 1.75rem;
    }
    .g-lg-8,
    .gy-lg-8 {
      --bs-gutter-y: 1.75rem;
    }
    .g-lg-9,
    .gx-lg-9 {
      --bs-gutter-x: 2rem;
    }
    .g-lg-9,
    .gy-lg-9 {
      --bs-gutter-y: 2rem;
    }
    .g-lg-10,
    .gx-lg-10 {
      --bs-gutter-x: 2.5rem;
    }
    .g-lg-10,
    .gy-lg-10 {
      --bs-gutter-y: 2.5rem;
    }
    .g-lg-11,
    .gx-lg-11 {
      --bs-gutter-x: 3rem;
    }
    .g-lg-11,
    .gy-lg-11 {
      --bs-gutter-y: 3rem;
    }
    .g-lg-12,
    .gx-lg-12 {
      --bs-gutter-x: 3.5rem;
    }
    .g-lg-12,
    .gy-lg-12 {
      --bs-gutter-y: 3.5rem;
    }
    .g-lg-13,
    .gx-lg-13 {
      --bs-gutter-x: 4rem;
    }
    .g-lg-13,
    .gy-lg-13 {
      --bs-gutter-y: 4rem;
    }
    .g-lg-14,
    .gx-lg-14 {
      --bs-gutter-x: 4.5rem;
    }
    .g-lg-14,
    .gy-lg-14 {
      --bs-gutter-y: 4.5rem;
    }
    .g-lg-15,
    .gx-lg-15 {
      --bs-gutter-x: 5rem;
    }
    .g-lg-15,
    .gy-lg-15 {
      --bs-gutter-y: 5rem;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xl-11 {
      margin-left: 91.66666667%;
    }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 0.125rem;
    }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 0.125rem;
    }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 0.75rem;
    }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 0.75rem;
    }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 1rem;
    }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 1rem;
    }
    .g-xl-6,
    .gx-xl-6 {
      --bs-gutter-x: 1.25rem;
    }
    .g-xl-6,
    .gy-xl-6 {
      --bs-gutter-y: 1.25rem;
    }
    .g-xl-7,
    .gx-xl-7 {
      --bs-gutter-x: 1.5rem;
    }
    .g-xl-7,
    .gy-xl-7 {
      --bs-gutter-y: 1.5rem;
    }
    .g-xl-8,
    .gx-xl-8 {
      --bs-gutter-x: 1.75rem;
    }
    .g-xl-8,
    .gy-xl-8 {
      --bs-gutter-y: 1.75rem;
    }
    .g-xl-9,
    .gx-xl-9 {
      --bs-gutter-x: 2rem;
    }
    .g-xl-9,
    .gy-xl-9 {
      --bs-gutter-y: 2rem;
    }
    .g-xl-10,
    .gx-xl-10 {
      --bs-gutter-x: 2.5rem;
    }
    .g-xl-10,
    .gy-xl-10 {
      --bs-gutter-y: 2.5rem;
    }
    .g-xl-11,
    .gx-xl-11 {
      --bs-gutter-x: 3rem;
    }
    .g-xl-11,
    .gy-xl-11 {
      --bs-gutter-y: 3rem;
    }
    .g-xl-12,
    .gx-xl-12 {
      --bs-gutter-x: 3.5rem;
    }
    .g-xl-12,
    .gy-xl-12 {
      --bs-gutter-y: 3.5rem;
    }
    .g-xl-13,
    .gx-xl-13 {
      --bs-gutter-x: 4rem;
    }
    .g-xl-13,
    .gy-xl-13 {
      --bs-gutter-y: 4rem;
    }
    .g-xl-14,
    .gx-xl-14 {
      --bs-gutter-x: 4.5rem;
    }
    .g-xl-14,
    .gy-xl-14 {
      --bs-gutter-y: 4.5rem;
    }
    .g-xl-15,
    .gx-xl-15 {
      --bs-gutter-x: 5rem;
    }
    .g-xl-15,
    .gy-xl-15 {
      --bs-gutter-y: 5rem;
    }
  }
  @media (min-width: 1440px) {
    .col-xxl {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-xxl-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xxl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xxl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xxl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xxl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xxl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xxl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xxl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-xxl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xxl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xxl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xxl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xxl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xxl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xxl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xxl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xxl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xxl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xxl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xxl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.66666667%;
    }
    .g-xxl-0,
    .gx-xxl-0 {
      --bs-gutter-x: 0;
    }
    .g-xxl-0,
    .gy-xxl-0 {
      --bs-gutter-y: 0;
    }
    .g-xxl-1,
    .gx-xxl-1 {
      --bs-gutter-x: 0.125rem;
    }
    .g-xxl-1,
    .gy-xxl-1 {
      --bs-gutter-y: 0.125rem;
    }
    .g-xxl-2,
    .gx-xxl-2 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xxl-2,
    .gy-xxl-2 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xxl-3,
    .gx-xxl-3 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xxl-3,
    .gy-xxl-3 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xxl-4,
    .gx-xxl-4 {
      --bs-gutter-x: 0.75rem;
    }
    .g-xxl-4,
    .gy-xxl-4 {
      --bs-gutter-y: 0.75rem;
    }
    .g-xxl-5,
    .gx-xxl-5 {
      --bs-gutter-x: 1rem;
    }
    .g-xxl-5,
    .gy-xxl-5 {
      --bs-gutter-y: 1rem;
    }
    .g-xxl-6,
    .gx-xxl-6 {
      --bs-gutter-x: 1.25rem;
    }
    .g-xxl-6,
    .gy-xxl-6 {
      --bs-gutter-y: 1.25rem;
    }
    .g-xxl-7,
    .gx-xxl-7 {
      --bs-gutter-x: 1.5rem;
    }
    .g-xxl-7,
    .gy-xxl-7 {
      --bs-gutter-y: 1.5rem;
    }
    .g-xxl-8,
    .gx-xxl-8 {
      --bs-gutter-x: 1.75rem;
    }
    .g-xxl-8,
    .gy-xxl-8 {
      --bs-gutter-y: 1.75rem;
    }
    .g-xxl-9,
    .gx-xxl-9 {
      --bs-gutter-x: 2rem;
    }
    .g-xxl-9,
    .gy-xxl-9 {
      --bs-gutter-y: 2rem;
    }
    .g-xxl-10,
    .gx-xxl-10 {
      --bs-gutter-x: 2.5rem;
    }
    .g-xxl-10,
    .gy-xxl-10 {
      --bs-gutter-y: 2.5rem;
    }
    .g-xxl-11,
    .gx-xxl-11 {
      --bs-gutter-x: 3rem;
    }
    .g-xxl-11,
    .gy-xxl-11 {
      --bs-gutter-y: 3rem;
    }
    .g-xxl-12,
    .gx-xxl-12 {
      --bs-gutter-x: 3.5rem;
    }
    .g-xxl-12,
    .gy-xxl-12 {
      --bs-gutter-y: 3.5rem;
    }
    .g-xxl-13,
    .gx-xxl-13 {
      --bs-gutter-x: 4rem;
    }
    .g-xxl-13,
    .gy-xxl-13 {
      --bs-gutter-y: 4rem;
    }
    .g-xxl-14,
    .gx-xxl-14 {
      --bs-gutter-x: 4.5rem;
    }
    .g-xxl-14,
    .gy-xxl-14 {
      --bs-gutter-y: 4.5rem;
    }
    .g-xxl-15,
    .gx-xxl-15 {
      --bs-gutter-x: 5rem;
    }
    .g-xxl-15,
    .gy-xxl-15 {
      --bs-gutter-y: 5rem;
    }
  }
  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #95aac9;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #95aac9;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #95aac9;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #95aac9;
    vertical-align: top;
    border-color: #f5f8fb;
  }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 2px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  .table > tbody {
    vertical-align: inherit;
  }
  .table > thead {
    vertical-align: bottom;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor;
  }
  .caption-top {
    caption-side: top;
  }
  .table-sm > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
  }
  .table-bordered > :not(caption) > * {
    border-width: 2px 0;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 2px;
  }
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
  }
  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
  .table-primary {
    --bs-table-bg: #2787f5;
    --bs-table-striped-bg: #328df6;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #3d93f6;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #3790f6;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #3d93f6;
  }
  .table-secondary {
    --bs-table-bg: #d9e4f0;
    --bs-table-striped-bg: #d6e1ee;
    --bs-table-striped-color: #95aac9;
    --bs-table-active-bg: #d2deec;
    --bs-table-active-color: #95aac9;
    --bs-table-hover-bg: #d4e0ed;
    --bs-table-hover-color: #95aac9;
    color: #95aac9;
    border-color: #d2deec;
  }
  .table-success {
    --bs-table-bg: #ecad8f;
    --bs-table-striped-bg: #edb195;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #eeb59a;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #edb397;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #eeb59a;
  }
  .table-info {
    --bs-table-bg: #17a2b8;
    --bs-table-striped-bg: #23a7bc;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #2eabbf;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #28a9bd;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #2eabbf;
  }
  .table-warning {
    --bs-table-bg: #ffc107;
    --bs-table-striped-bg: #ffc413;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #ffc720;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #ffc61a;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #ffc720;
  }
  .table-danger {
    --bs-table-bg: #fe6571;
    --bs-table-striped-bg: #fe6d78;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #fe747f;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #fe717c;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #fe747f;
  }
  .table-light {
    --bs-table-bg: #f6f9fb;
    --bs-table-striped-bg: #f1f5f9;
    --bs-table-striped-color: #95aac9;
    --bs-table-active-bg: #ecf1f6;
    --bs-table-active-color: #95aac9;
    --bs-table-hover-bg: #eff3f7;
    --bs-table-hover-color: #95aac9;
    color: #95aac9;
    border-color: #ecf1f6;
  }
  .table-dark {
    --bs-table-bg: #ebf1f7;
    --bs-table-striped-bg: #e7edf5;
    --bs-table-striped-color: #95aac9;
    --bs-table-active-bg: #e2eaf2;
    --bs-table-active-color: #95aac9;
    --bs-table-hover-bg: #e5ecf4;
    --bs-table-hover-color: #95aac9;
    color: #95aac9;
    border-color: #e2eaf2;
  }
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1439.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .form-label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  .col-form-label {
    padding-top: calc(0.59375rem + 2px);
    padding-bottom: calc(0.59375rem + 2px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
  }
  .col-form-label-lg {
    padding-top: calc(0.875rem + 2px);
    padding-bottom: calc(0.875rem + 2px);
    font-size: 0.9375rem;
  }
  .col-form-label-sm {
    padding-top: calc(0.375rem + 2px);
    padding-bottom: calc(0.375rem + 2px);
    font-size: 0.75rem;
  }
  .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #bfccdf;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.59375rem 1.125rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.6;
    color: #a0b3ce;
    background-color: #ebf1f7;
    background-clip: padding-box;
    border: 2px solid #ebf1f7;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.6rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-control[type="file"] {
    overflow: hidden;
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control:focus {
    color: #a0b3ce;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .form-control::-webkit-date-and-time-value {
    height: 1.6em;
  }
  .form-control::-webkit-input-placeholder {
    color: #95aac9;
    opacity: 1;
  }
  .form-control::-moz-placeholder {
    color: #95aac9;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #95aac9;
    opacity: 1;
  }
  .form-control::-ms-input-placeholder {
    color: #95aac9;
    opacity: 1;
  }
  .form-control::placeholder {
    color: #95aac9;
    opacity: 1;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e2ecf3;
    opacity: 1;
  }
  .form-control::file-selector-button {
    padding: 0.59375rem 1.125rem;
    margin: -0.59375rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #a0b3ce;
    background-color: #ebf1f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 2px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dfe5eb;
  }
  .form-control::-webkit-file-upload-button {
    padding: 0.59375rem 1.125rem;
    margin: -0.59375rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
    color: #a0b3ce;
    background-color: #ebf1f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 2px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dfe5eb;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.59375rem 0;
    margin-bottom: 0;
    line-height: 1.6;
    color: #95aac9;
    background-color: transparent;
    border: solid transparent;
    border-width: 2px 0;
  }
  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    min-height: calc(1.6em + 0.75rem + 4px);
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    border-radius: 0.4rem;
  }
  .form-control-sm::file-selector-button {
    padding: 0.375rem 1rem;
    margin: -0.375rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.375rem 1rem;
    margin: -0.375rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem;
  }
  .form-control-lg {
    min-height: calc(1.6em + 1.75rem + 4px);
    padding: 0.875rem 1.125rem;
    font-size: 0.9375rem;
    border-radius: 0.6rem;
  }
  .form-control-lg::file-selector-button {
    padding: 0.875rem 1.125rem;
    margin: -0.875rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
  }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.875rem 1.125rem;
    margin: -0.875rem -1.125rem;
    -webkit-margin-end: 1.125rem;
    margin-inline-end: 1.125rem;
  }
  textarea.form-control {
    min-height: calc(1.6em + 1.1875rem + 4px);
  }
  textarea.form-control-sm {
    min-height: calc(1.6em + 0.75rem + 4px);
  }
  textarea.form-control-lg {
    min-height: calc(1.6em + 1.75rem + 4px);
  }
  .form-control-color {
    width: 3rem;
    height: auto;
    padding: 0.59375rem;
  }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control-color::-moz-color-swatch {
    height: 1.6em;
    border-radius: 0.6rem;
  }
  .form-control-color::-webkit-color-swatch {
    height: 1.6em;
    border-radius: 0.6rem;
  }
  .form-select {
    display: block;
    width: 100%;
    padding: 0.59375rem 1rem 0.59375rem 1.125rem;
    -moz-padding-start: calc(1.125rem - 3px);
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.6;
    color: #a0b3ce;
    background-color: #ebf1f7;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a0b3ce' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1.125rem center;
    background-size: 12px 9px;
    border: 2px solid #ebf1f7;
    border-radius: 0.6rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-select:focus {
    border-color: #ebf1f7;
    outline: 0;
    -webkit-box-shadow: 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding-right: 1.125rem;
    background-image: none;
  }
  .form-select:disabled {
    color: #95aac9;
    background-color: #e2ecf3;
  }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #a0b3ce;
  }
  .form-select-sm {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1rem;
    font-size: 0.75rem;
  }
  .form-select-lg {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 1.125rem;
    font-size: 0.9375rem;
  }
  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.75em;
    margin-bottom: 0.125rem;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.75em;
  }
  .form-check-input {
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.175em;
    vertical-align: top;
    background-color: #ebf1f7;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-check-input[type="checkbox"] {
    border-radius: 30%;
  }
  .form-check-input[type="radio"] {
    border-radius: 50%;
  }
  .form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
  }
  .form-check-input:focus {
    border-color: #ebf1f7;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .form-check-input:checked {
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
  }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #2787f5;
    border-color: #2787f5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
  .form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.5;
  }
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: 0.5;
  }
  .form-switch {
    padding-left: 2.5em;
  }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    -webkit-transition: background-position 0.15s ease-in-out;
    transition: background-position 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  }
  .form-check-inline {
    display: inline-block;
    margin-right: 1rem;
  }
  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .btn-check:disabled + .btn,
  .btn-check[disabled] + .btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.65;
  }
  .form-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-range:focus {
    outline: 0;
  }
  .form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
  }
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff;
  }
  .form-range::-moz-focus-outer {
    border: 0;
  }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2787f5;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-range::-webkit-slider-thumb:active {
    background-color: #73b1f9;
  }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ebf1f7;
    border-color: transparent;
    border-radius: 1rem;
  }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2787f5;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
  }
  .form-range::-moz-range-thumb:active {
    background-color: #73b1f9;
  }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ebf1f7;
    border-color: transparent;
    border-radius: 1rem;
  }
  .form-range:disabled {
    pointer-events: none;
  }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  .form-floating {
    position: relative;
  }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 4px);
    line-height: 1.25;
  }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 1.125rem;
    pointer-events: none;
    border: 2px solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      -webkit-transition: none;
      transition: none;
    }
  }
  .form-floating > .form-control {
    padding: 1rem 1.125rem;
  }
  .form-floating > .form-control::-webkit-input-placeholder {
    color: transparent;
  }
  .form-floating > .form-control::-moz-placeholder {
    color: transparent;
  }
  .form-floating > .form-control:-ms-input-placeholder {
    color: transparent;
  }
  .form-floating > .form-control::-ms-input-placeholder {
    color: transparent;
  }
  .form-floating > .form-control::placeholder {
    color: transparent;
  }
  .form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3;
  }
  .input-group .btn {
    position: relative;
    z-index: 2;
  }
  .input-group .btn:focus {
    z-index: 3;
  }
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.59375rem 1.125rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.6;
    color: #a0b3ce;
    text-align: center;
    white-space: nowrap;
    background-color: #ebf1f7;
    border: 2px solid #ebf1f7;
    border-radius: 0.6rem;
  }
  .input-group-lg > .btn,
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text {
    padding: 0.875rem 1.125rem;
    font-size: 0.9375rem;
    border-radius: 0.6rem;
  }
  .input-group-sm > .btn,
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    border-radius: 0.4rem;
  }
  .input-group-lg > .form-select,
  .input-group-sm > .form-select {
    padding-right: 2.125rem;
  }
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
  .input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #ecad8f;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.35rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.6796875rem;
    color: #fff;
    background-color: rgba(236, 173, 143, 0.8);
    border-radius: 0.4rem;
  }
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip {
    display: block;
  }
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #ecad8f;
    padding-right: calc(1.6em + 1.1875rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ecad8f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.296875rem) center;
    background-size: calc(0.8em + 0.59375rem) calc(0.8em + 0.59375rem);
  }
  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #ecad8f;
    -webkit-box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
    box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
  }
  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: calc(1.6em + 1.1875rem);
    background-position: top calc(0.4em + 0.296875rem) right calc(0.4em + 0.296875rem);
  }
  .form-select.is-valid,
  .was-validated .form-select:valid {
    border-color: #ecad8f;
  }
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size="1"],
  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 3.015625rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a0b3ce' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ecad8f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1.125rem center, center right 2.125rem;
    background-size: 12px 9px, calc(0.8em + 0.59375rem) calc(0.8em + 0.59375rem);
  }
  .form-select.is-valid:focus,
  .was-validated .form-select:valid:focus {
    border-color: #ecad8f;
    -webkit-box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
    box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
  }
  .form-check-input.is-valid,
  .was-validated .form-check-input:valid {
    border-color: #ecad8f;
  }
  .form-check-input.is-valid:checked,
  .was-validated .form-check-input:valid:checked {
    background-color: #ecad8f;
  }
  .form-check-input.is-valid:focus,
  .was-validated .form-check-input:valid:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
    box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.25);
  }
  .form-check-input.is-valid ~ .form-check-label,
  .was-validated .form-check-input:valid ~ .form-check-label {
    color: #ecad8f;
  }
  .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: 0.5em;
  }
  .input-group .form-control.is-valid,
  .input-group .form-select.is-valid,
  .was-validated .input-group .form-control:valid,
  .was-validated .input-group .form-select:valid {
    z-index: 1;
  }
  .input-group .form-control.is-valid:focus,
  .input-group .form-select.is-valid:focus,
  .was-validated .input-group .form-control:valid:focus,
  .was-validated .input-group .form-select:valid:focus {
    z-index: 3;
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #fe6571;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.35rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.6796875rem;
    color: #fff;
    background-color: rgba(254, 101, 113, 0.8);
    border-radius: 0.4rem;
  }
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip {
    display: block;
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #fe6571;
    padding-right: calc(1.6em + 1.1875rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fe6571'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fe6571' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.296875rem) center;
    background-size: calc(0.8em + 0.59375rem) calc(0.8em + 0.59375rem);
  }
  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #fe6571;
    -webkit-box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
    box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
  }
  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: calc(1.6em + 1.1875rem);
    background-position: top calc(0.4em + 0.296875rem) right calc(0.4em + 0.296875rem);
  }
  .form-select.is-invalid,
  .was-validated .form-select:invalid {
    border-color: #fe6571;
  }
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size="1"],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 3.015625rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a0b3ce' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fe6571'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fe6571' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1.125rem center, center right 2.125rem;
    background-size: 12px 9px, calc(0.8em + 0.59375rem) calc(0.8em + 0.59375rem);
  }
  .form-select.is-invalid:focus,
  .was-validated .form-select:invalid:focus {
    border-color: #fe6571;
    -webkit-box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
    box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
  }
  .form-check-input.is-invalid,
  .was-validated .form-check-input:invalid {
    border-color: #fe6571;
  }
  .form-check-input.is-invalid:checked,
  .was-validated .form-check-input:invalid:checked {
    background-color: #fe6571;
  }
  .form-check-input.is-invalid:focus,
  .was-validated .form-check-input:invalid:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
    box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.25);
  }
  .form-check-input.is-invalid ~ .form-check-label,
  .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #fe6571;
  }
  .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em;
  }
  .input-group .form-control.is-invalid,
  .input-group .form-select.is-invalid,
  .was-validated .input-group .form-control:invalid,
  .was-validated .input-group .form-select:invalid {
    z-index: 2;
  }
  .input-group .form-control.is-invalid:focus,
  .input-group .form-select.is-invalid:focus,
  .was-validated .input-group .form-control:invalid:focus,
  .was-validated .input-group .form-select:invalid:focus {
    z-index: 3;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.6;
    color: #95aac9;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    padding: 0.59375rem 1.125rem;
    font-size: 0.9375rem;
    border-radius: 0.6rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none;
    }
  }
  .btn:hover {
    color: #95aac9;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
  }
  .btn-primary {
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #2173d0;
    border-color: #1f6cc4;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: #fff;
    background-color: #2173d0;
    border-color: #1f6cc4;
    -webkit-box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1f6cc4;
    border-color: #1d65b8;
  }
  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .btn-secondary {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-secondary:hover {
    color: #95aac9;
    background-color: #dfe8f2;
    border-color: #dde7f2;
  }
  .btn-check:focus + .btn-secondary,
  .btn-secondary:focus {
    color: #95aac9;
    background-color: #dfe8f2;
    border-color: #dde7f2;
    -webkit-box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
  }
  .btn-check:active + .btn-secondary,
  .btn-check:checked + .btn-secondary,
  .btn-secondary.active,
  .btn-secondary:active,
  .show > .btn-secondary.dropdown-toggle {
    color: #95aac9;
    background-color: #e1e9f3;
    border-color: #dde7f2;
  }
  .btn-check:active + .btn-secondary:focus,
  .btn-check:checked + .btn-secondary:focus,
  .btn-secondary.active:focus,
  .btn-secondary:active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-success {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #c9937a;
    border-color: #bd8a72;
  }
  .btn-check:focus + .btn-success,
  .btn-success:focus {
    color: #fff;
    background-color: #c9937a;
    border-color: #bd8a72;
    -webkit-box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
  }
  .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #bd8a72;
    border-color: #b1826b;
  }
  .btn-check:active + .btn-success:focus,
  .btn-check:checked + .btn-success:focus,
  .btn-success.active:focus,
  .btn-success:active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #148a9c;
    border-color: #128293;
  }
  .btn-check:focus + .btn-info,
  .btn-info:focus {
    color: #fff;
    background-color: #148a9c;
    border-color: #128293;
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-check:active + .btn-info,
  .btn-check:checked + .btn-info,
  .btn-info.active,
  .btn-info:active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #128293;
    border-color: #117a8a;
  }
  .btn-check:active + .btn-info:focus,
  .btn-check:checked + .btn-info:focus,
  .btn-info.active:focus,
  .btn-info:active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-warning {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:hover {
    color: #fff;
    background-color: #d9a406;
    border-color: #cc9a06;
  }
  .btn-check:focus + .btn-warning,
  .btn-warning:focus {
    color: #fff;
    background-color: #d9a406;
    border-color: #cc9a06;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
  }
  .btn-check:active + .btn-warning,
  .btn-check:checked + .btn-warning,
  .btn-warning.active,
  .btn-warning:active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc9a06;
    border-color: #bf9105;
  }
  .btn-check:active + .btn-warning:focus,
  .btn-check:checked + .btn-warning:focus,
  .btn-warning.active:focus,
  .btn-warning:active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-danger {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #d85660;
    border-color: #cb515a;
  }
  .btn-check:focus + .btn-danger,
  .btn-danger:focus {
    color: #fff;
    background-color: #d85660;
    border-color: #cb515a;
    -webkit-box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
  }
  .btn-check:active + .btn-danger,
  .btn-check:checked + .btn-danger,
  .btn-danger.active,
  .btn-danger:active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cb515a;
    border-color: #bf4c55;
  }
  .btn-check:active + .btn-danger:focus,
  .btn-check:checked + .btn-danger:focus,
  .btn-danger.active:focus,
  .btn-danger:active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-light {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-light:hover {
    color: #95aac9;
    background-color: #f7fafc;
    border-color: #f7fafb;
  }
  .btn-check:focus + .btn-light,
  .btn-light:focus {
    color: #95aac9;
    background-color: #f7fafc;
    border-color: #f7fafb;
    -webkit-box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
  }
  .btn-check:active + .btn-light,
  .btn-check:checked + .btn-light,
  .btn-light.active,
  .btn-light:active,
  .show > .btn-light.dropdown-toggle {
    color: #95aac9;
    background-color: #f8fafc;
    border-color: #f7fafb;
  }
  .btn-check:active + .btn-light:focus,
  .btn-check:checked + .btn-light:focus,
  .btn-light.active:focus,
  .btn-light:active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-dark {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-dark:hover {
    color: #95aac9;
    background-color: #eef3f8;
    border-color: #edf2f8;
  }
  .btn-check:focus + .btn-dark,
  .btn-dark:focus {
    color: #95aac9;
    background-color: #eef3f8;
    border-color: #edf2f8;
    -webkit-box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
  }
  .btn-check:active + .btn-dark,
  .btn-check:checked + .btn-dark,
  .btn-dark.active,
  .btn-dark:active,
  .show > .btn-dark.dropdown-toggle {
    color: #95aac9;
    background-color: #eff4f9;
    border-color: #edf2f8;
  }
  .btn-check:active + .btn-dark:focus,
  .btn-check:checked + .btn-dark:focus,
  .btn-dark.active:focus,
  .btn-dark:active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-white {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-white:hover {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:focus + .btn-white,
  .btn-white:focus {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
    -webkit-box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
  }
  .btn-check:active + .btn-white,
  .btn-check:checked + .btn-white,
  .btn-white.active,
  .btn-white:active,
  .show > .btn-white.dropdown-toggle {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:active + .btn-white:focus,
  .btn-check:checked + .btn-white:focus,
  .btn-white.active:focus,
  .btn-white:active:focus,
  .show > .btn-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
  }
  .btn-white.disabled,
  .btn-white:disabled {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-outline-primary {
    color: #2787f5;
    border-color: #2787f5;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .btn-check:focus + .btn-outline-primary,
  .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.5);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.5);
  }
  .btn-check:active + .btn-outline-primary,
  .btn-check:checked + .btn-outline-primary,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show,
  .btn-outline-primary:active {
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .btn-check:active + .btn-outline-primary:focus,
  .btn-check:checked + .btn-outline-primary:focus,
  .btn-outline-primary.active:focus,
  .btn-outline-primary.dropdown-toggle.show:focus,
  .btn-outline-primary:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.5);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.5);
  }
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #2787f5;
    background-color: transparent;
  }
  .btn-outline-secondary {
    color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-outline-secondary:hover {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-check:focus + .btn-outline-secondary,
  .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(217, 228, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(217, 228, 240, 0.5);
  }
  .btn-check:active + .btn-outline-secondary,
  .btn-check:checked + .btn-outline-secondary,
  .btn-outline-secondary.active,
  .btn-outline-secondary.dropdown-toggle.show,
  .btn-outline-secondary:active {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-check:active + .btn-outline-secondary:focus,
  .btn-check:checked + .btn-outline-secondary:focus,
  .btn-outline-secondary.active:focus,
  .btn-outline-secondary.dropdown-toggle.show:focus,
  .btn-outline-secondary:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(217, 228, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(217, 228, 240, 0.5);
  }
  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #d9e4f0;
    background-color: transparent;
  }
  .btn-outline-success {
    color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-check:focus + .btn-outline-success,
  .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.5);
    box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.5);
  }
  .btn-check:active + .btn-outline-success,
  .btn-check:checked + .btn-outline-success,
  .btn-outline-success.active,
  .btn-outline-success.dropdown-toggle.show,
  .btn-outline-success:active {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-check:active + .btn-outline-success:focus,
  .btn-check:checked + .btn-outline-success:focus,
  .btn-outline-success.active:focus,
  .btn-outline-success.dropdown-toggle.show:focus,
  .btn-outline-success:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.5);
    box-shadow: 0 0 0 0 rgba(236, 173, 143, 0.5);
  }
  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #ecad8f;
    background-color: transparent;
  }
  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-check:focus + .btn-outline-info,
  .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  .btn-check:active + .btn-outline-info,
  .btn-check:checked + .btn-outline-info,
  .btn-outline-info.active,
  .btn-outline-info.dropdown-toggle.show,
  .btn-outline-info:active {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-check:active + .btn-outline-info:focus,
  .btn-check:checked + .btn-outline-info:focus,
  .btn-outline-info.active:focus,
  .btn-outline-info.dropdown-toggle.show:focus,
  .btn-outline-info:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
  }
  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
  }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-check:focus + .btn-outline-warning,
  .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  .btn-check:active + .btn-outline-warning,
  .btn-check:checked + .btn-outline-warning,
  .btn-outline-warning.active,
  .btn-outline-warning.dropdown-toggle.show,
  .btn-outline-warning:active {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-check:active + .btn-outline-warning:focus,
  .btn-check:checked + .btn-outline-warning:focus,
  .btn-outline-warning.active:focus,
  .btn-outline-warning.dropdown-toggle.show:focus,
  .btn-outline-warning:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
  }
  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  .btn-outline-danger {
    color: #fe6571;
    border-color: #fe6571;
  }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-check:focus + .btn-outline-danger,
  .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.5);
  }
  .btn-check:active + .btn-outline-danger,
  .btn-check:checked + .btn-outline-danger,
  .btn-outline-danger.active,
  .btn-outline-danger.dropdown-toggle.show,
  .btn-outline-danger:active {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-check:active + .btn-outline-danger:focus,
  .btn-check:checked + .btn-outline-danger:focus,
  .btn-outline-danger.active:focus,
  .btn-outline-danger.dropdown-toggle.show:focus,
  .btn-outline-danger:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 101, 113, 0.5);
  }
  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #fe6571;
    background-color: transparent;
  }
  .btn-outline-light {
    color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-outline-light:hover {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(246, 249, 251, 0.5);
    box-shadow: 0 0 0 0 rgba(246, 249, 251, 0.5);
  }
  .btn-check:active + .btn-outline-light,
  .btn-check:checked + .btn-outline-light,
  .btn-outline-light.active,
  .btn-outline-light.dropdown-toggle.show,
  .btn-outline-light:active {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-check:active + .btn-outline-light:focus,
  .btn-check:checked + .btn-outline-light:focus,
  .btn-outline-light.active:focus,
  .btn-outline-light.dropdown-toggle.show:focus,
  .btn-outline-light:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(246, 249, 251, 0.5);
    box-shadow: 0 0 0 0 rgba(246, 249, 251, 0.5);
  }
  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #f6f9fb;
    background-color: transparent;
  }
  .btn-outline-dark {
    color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-outline-dark:hover {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-check:focus + .btn-outline-dark,
  .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(235, 241, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(235, 241, 247, 0.5);
  }
  .btn-check:active + .btn-outline-dark,
  .btn-check:checked + .btn-outline-dark,
  .btn-outline-dark.active,
  .btn-outline-dark.dropdown-toggle.show,
  .btn-outline-dark:active {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-check:active + .btn-outline-dark:focus,
  .btn-check:checked + .btn-outline-dark:focus,
  .btn-outline-dark.active:focus,
  .btn-outline-dark.dropdown-toggle.show:focus,
  .btn-outline-dark:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(235, 241, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(235, 241, 247, 0.5);
  }
  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #ebf1f7;
    background-color: transparent;
  }
  .btn-outline-white {
    color: #fff;
    border-color: #fff;
  }
  .btn-outline-white:hover {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:focus + .btn-outline-white,
  .btn-outline-white:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .btn-check:active + .btn-outline-white,
  .btn-check:checked + .btn-outline-white,
  .btn-outline-white.active,
  .btn-outline-white.dropdown-toggle.show,
  .btn-outline-white:active {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-check:active + .btn-outline-white:focus,
  .btn-check:checked + .btn-outline-white:focus,
  .btn-outline-white.active:focus,
  .btn-outline-white.dropdown-toggle.show:focus,
  .btn-outline-white:active:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .btn-outline-white.disabled,
  .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
  }
  .btn-link {
    font-weight: 400;
    color: #2787f5;
    text-decoration: underline;
  }
  .btn-link:hover {
    color: #1f6cc4;
  }
  .btn-link.disabled,
  .btn-link:disabled {
    color: #95aac9;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0.875rem 1.125rem;
    font-size: 0.9375rem;
    border-radius: 0.6rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    border-radius: 0.4rem;
  }
  .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none;
    }
  }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    -webkit-transition: width 0.35s ease;
    transition: width 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      -webkit-transition: none;
      transition: none;
    }
  }
  .dropdown,
  .dropend,
  .dropstart,
  .dropup {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.15em;
    vertical-align: 0.15em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 0.9375rem;
    color: #95aac9;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid #f5f8fb;
    border-radius: 0.6rem;
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem;
  }
  .dropdown-menu-start {
    --bs-position: start;
  }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-end {
    --bs-position: end;
  }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-end {
      --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-end {
      --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-end {
      --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-end {
      --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1440px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xxl-end {
      --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.15em;
    vertical-align: 0.15em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.15em;
    vertical-align: 0.15em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.15em;
    vertical-align: 0.15em;
    content: "";
  }
  .dropstart .dropdown-toggle::after {
    display: none;
  }
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.15em;
    vertical-align: 0.15em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #f5f8fb;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    clear: both;
    font-weight: 400;
    color: #95aac9;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #596679;
    background-color: #f5f8fb;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #95aac9;
    text-decoration: none;
    background-color: #f5f8fb;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #95aac9;
    pointer-events: none;
    background-color: transparent;
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.6796875rem;
    color: #95aac9;
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: 0.5rem 1rem;
    color: #95aac9;
  }
  .dropdown-menu-dark {
    color: #ebf1f7;
    background-color: #343a40;
    border-color: #f5f8fb;
  }
  .dropdown-menu-dark .dropdown-item {
    color: #ebf1f7;
  }
  .dropdown-menu-dark .dropdown-item:focus,
  .dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .dropdown-menu-dark .dropdown-item.active,
  .dropdown-menu-dark .dropdown-item:active {
    color: #95aac9;
    background-color: #f5f8fb;
  }
  .dropdown-menu-dark .dropdown-item.disabled,
  .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd;
  }
  .dropdown-menu-dark .dropdown-divider {
    border-color: #f5f8fb;
  }
  .dropdown-menu-dark .dropdown-item-text {
    color: #ebf1f7;
  }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
  }
  .btn-group-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn.active,
  .btn-group > .btn:active,
  .btn-group > .btn:focus,
  .btn-group > .btn:hover {
    z-index: 1;
  }
  .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .btn-toolbar .input-group {
    width: auto;
  }
  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: -2px;
  }
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-toggle-split {
    padding-right: 0.84375rem;
    padding-left: 0.84375rem;
  }
  .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0;
  }
  .btn-group-sm > .btn + .dropdown-toggle-split,
  .btn-sm + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .btn-group-lg > .btn + .dropdown-toggle-split,
  .btn-lg + .dropdown-toggle-split {
    padding-right: 0.84375rem;
    padding-left: 0.84375rem;
  }
  .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
  }
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -2px;
  }
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn ~ .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .nav-link {
    display: block;
    padding: 0.625rem 1rem;
    color: #2787f5;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      -webkit-transition: none;
      transition: none;
    }
  }
  .nav-link:focus,
  .nav-link:hover {
    color: #1f6cc4;
  }
  .nav-link.disabled {
    color: rgba(149, 170, 201, 0.3);
    pointer-events: none;
    cursor: default;
  }
  .nav-tabs {
    border-bottom: 2px solid #f5f8fb;
  }
  .nav-tabs .nav-link {
    margin-bottom: -2px;
    background: 0 0;
    border: 2px solid transparent;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #f5f8fb #f5f8fb #f5f8fb;
    isolation: isolate;
  }
  .nav-tabs .nav-link.disabled {
    color: rgba(149, 170, 201, 0.3);
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #2787f5;
    background-color: transparent;
    border-color: #f5f8fb #f5f8fb #fff;
  }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.6rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #293951;
    background-color: #fff;
  }
  .nav-fill .nav-item,
  .nav-fill > .nav-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%;
  }
  .tab-content > .tab-pane {
    display: none;
  }
  .tab-content > .active {
    display: block;
  }
  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .navbar-brand {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    margin-right: 1rem;
    font-size: 1.171875rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
  }
  .navbar-text {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.171875rem;
    line-height: 1;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 0.6rem;
    -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      -webkit-transition: none;
      transition: none;
    }
  }
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0;
    box-shadow: 0 0;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto;
  }
  @media (min-width: 576px) {
    .navbar-expand-sm {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .offcanvas-header {
      display: none;
    }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }
    .navbar-expand-sm .offcanvas-bottom,
    .navbar-expand-sm .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-sm .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
    .navbar-expand-md .offcanvas-header {
      display: none;
    }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }
    .navbar-expand-md .offcanvas-bottom,
    .navbar-expand-md .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-md .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .offcanvas-header {
      display: none;
    }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }
    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-lg .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .offcanvas-header {
      display: none;
    }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }
    .navbar-expand-xl .offcanvas-bottom,
    .navbar-expand-xl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-xl .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  @media (min-width: 1440px) {
    .navbar-expand-xxl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible;
    }
    .navbar-expand-xxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xxl .offcanvas-header {
      display: none;
    }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;
    }
    .navbar-expand-xxl .offcanvas-bottom,
    .navbar-expand-xxl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0;
    }
    .navbar-expand-xxl .offcanvas-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
  .navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand .navbar-toggler {
    display: none;
  }
  .navbar-expand .offcanvas-header {
    display: none;
  }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
  }
  .navbar-expand .offcanvas-bottom,
  .navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
  .navbar-light .navbar-brand {
    color: #2787f5;
  }
  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: #2787f5;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #95aac9;
  }
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: #2787f5;
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(149, 170, 201, 0.3);
  }
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show > .nav-link {
    color: #2787f5;
  }
  .navbar-light .navbar-toggler {
    color: #95aac9;
    border-color: transparent;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2395aac9' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: #95aac9;
  }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:focus,
  .navbar-light .navbar-text a:hover {
    color: #2787f5;
  }
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  .navbar-dark .navbar-brand:focus,
  .navbar-dark .navbar-brand:hover {
    color: #fff;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55);
  }
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.55);
  }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:focus,
  .navbar-dark .navbar-text a:hover {
    color: #fff;
  }
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 2px solid #f5f8fb;
    border-radius: 4px;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.6rem - 2px);
    border-top-right-radius: calc(0.6rem - 2px);
  }
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.6rem - 2px);
    border-bottom-left-radius: calc(0.6rem - 2px);
  }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem 1.5rem;
  }
  .card-title {
    margin-bottom: 0.5rem;
  }
  .card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0;
  }
  .card-text:last-child {
    margin-bottom: 0;
  }
  .card-link + .card-link {
    margin-left: 1.5rem;
  }
  .card-header {
    padding: 0.75rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 2px solid #f5f8fb;
  }
  .card-header:first-child {
    border-radius: calc(0.6rem - 2px) calc(0.6rem - 2px) 0 0;
  }
  .card-footer {
    padding: 0.75rem 1.5rem;
    background-color: transparent;
    border-top: 2px solid #f5f8fb;
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.6rem - 2px) calc(0.6rem - 2px);
  }
  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -0.75rem;
    margin-left: -0.75rem;
    border-bottom: 0;
  }
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff;
  }
  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.6rem - 2px);
  }
  .card-img,
  .card-img-bottom,
  .card-img-top {
    width: 100%;
  }
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.6rem - 2px);
    border-top-right-radius: calc(0.6rem - 2px);
  }
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.6rem - 2px);
    border-bottom-left-radius: calc(0.6rem - 2px);
  }
  .card-group > .card {
    margin-bottom: 1.25rem;
  }
  @media (min-width: 576px) {
    .card-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0;
    }
  }
  .accordion-button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 1.25rem 1.5rem;
    font-size: 0.9375rem;
    color: #95aac9;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      -webkit-transition: none;
      transition: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: #95aac9;
    background-color: #fff;
    -webkit-box-shadow: inset 0 -2px 0 #f5f8fb;
    box-shadow: inset 0 -2px 0 #f5f8fb;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23bfccdf' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='9 18 15 12 9 6'%3e%3c/polyline%3e%3c/svg%3e");
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23bfccdf' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='9 18 15 12 9 6'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      -webkit-transition: none;
      transition: none;
    }
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    z-index: 3;
    border-color: #f5f8fb;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .accordion-header {
    margin-bottom: 0;
  }
  .accordion-item {
    background-color: #fff;
    border: 2px solid #f5f8fb;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.6rem - 2px);
    border-top-right-radius: calc(0.6rem - 2px);
  }
  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.6rem - 2px);
    border-bottom-left-radius: calc(0.6rem - 2px);
  }
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  .accordion-body {
    padding: 1.25rem 1.5rem;
  }
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
  }
  .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #95aac9;
    content: var(--bs-breadcrumb-divider, "https://offsetcode.com/");
  }
  .breadcrumb-item.active {
    color: #95aac9;
  }
  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  .page-link {
    position: relative;
    display: block;
    color: #2787f5;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid #f5f8fb;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      -webkit-transition: none;
      transition: none;
    }
  }
  .page-link:hover {
    z-index: 2;
    color: #1f6cc4;
    background-color: #e2ecf3;
    border-color: #f5f8fb;
  }
  .page-link:focus {
    z-index: 3;
    color: #1f6cc4;
    background-color: #e2ecf3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -2px;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .page-item.disabled .page-link {
    color: #95aac9;
    pointer-events: none;
    background-color: #fff;
    border-color: #f5f8fb;
  }
  .page-link {
    padding: 0.375rem 0.75rem;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.171875rem;
  }
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.6796875rem;
  }
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
  .badge {
    display: inline-block;
    padding: 0.389em 0.6em;
    font-size: 0.6em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.3125rem;
  }
  .badge:empty {
    display: none;
  }
  .btn .badge {
    position: relative;
    top: -1px;
  }
  .alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 2px solid transparent;
    border-radius: 0.6rem;
  }
  .alert-heading {
    color: inherit;
  }
  .alert-link {
    font-weight: 700;
  }
  .alert-dismissible {
    padding-right: 3rem;
  }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
  }
  .alert-primary {
    color: #2173d0;
    background-color: #b3d5fc;
    border-color: #b3d5fc;
  }
  .alert-primary .alert-link {
    color: #1a5ca6;
  }
  .alert-secondary {
    color: #b8c2cc;
    background-color: #f2f6fa;
    border-color: #f2f6fa;
  }
  .alert-secondary .alert-link {
    color: #939ba3;
  }
  .alert-success {
    color: #c9937a;
    background-color: #f8e2d8;
    border-color: #f8e2d8;
  }
  .alert-success .alert-link {
    color: #a17662;
  }
  .alert-info {
    color: #148a9c;
    background-color: #aedee6;
    border-color: #aedee6;
  }
  .alert-info .alert-link {
    color: #106e7d;
  }
  .alert-warning {
    color: #d9a406;
    background-color: #ffe9a8;
    border-color: #ffe9a8;
  }
  .alert-warning .alert-link {
    color: #ae8305;
  }
  .alert-danger {
    color: #d85660;
    background-color: #ffc9cd;
    border-color: #ffc9cd;
  }
  .alert-danger .alert-link {
    color: #ad454d;
  }
  .alert-light {
    color: #a4b6d1;
    background-color: #fcfdfe;
    border-color: #fcfdfe;
  }
  .alert-light .alert-link {
    color: #8392a7;
  }
  .alert-dark {
    color: #c8cdd2;
    background-color: #f8fafc;
    border-color: #f8fafc;
  }
  .alert-dark .alert-link {
    color: #a0a4a8;
  }
  .alert-white {
    color: #a5b7d1;
    background-color: #fff;
    border-color: #fff;
  }
  .alert-white .alert-link {
    color: #8492a7;
  }
  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position-x: 0.5rem;
    }
  }
  @keyframes progress-bar-stripes {
    0% {
      background-position-x: 0.5rem;
    }
  }
  .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.46875rem;
    background-color: #e2ecf3;
    border-radius: 0.6rem;
  }
  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #2787f5;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none;
    }
  }
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 0.5rem 0.5rem;
  }
  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
  }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }
  .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.6rem;
  }
  .list-group-numbered {
    list-style-type: none;
    counter-reset: section;
  }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
  }
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f5f8fb;
  }
  .list-group-item-action:active {
    color: #95aac9;
    background-color: #f6f9fb;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 1.25rem 1.5rem;
    color: #95aac9;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid #f5f8fb;
  }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #95aac9;
    pointer-events: none;
    background-color: #fff;
  }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }
  .list-group-item + .list-group-item.active {
    margin-top: -2px;
    border-top-width: 2px;
  }
  .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 2px;
    border-left-width: 0;
  }
  .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -2px;
    border-left-width: 2px;
  }
  @media (min-width: 576px) {
    .list-group-horizontal-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 2px;
      border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -2px;
      border-left-width: 2px;
    }
  }
  @media (min-width: 768px) {
    .list-group-horizontal-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 2px;
      border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -2px;
      border-left-width: 2px;
    }
  }
  @media (min-width: 992px) {
    .list-group-horizontal-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 2px;
      border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -2px;
      border-left-width: 2px;
    }
  }
  @media (min-width: 1200px) {
    .list-group-horizontal-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 2px;
      border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -2px;
      border-left-width: 2px;
    }
  }
  @media (min-width: 1440px) {
    .list-group-horizontal-xxl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.6rem;
      border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 2px;
      border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -2px;
      border-left-width: 2px;
    }
  }
  .list-group-flush {
    border-radius: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 2px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .list-group-item-primary {
    color: #175193;
    background-color: #d4e7fd;
  }
  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #175193;
    background-color: #bfd0e4;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #175193;
    border-color: #175193;
  }
  .list-group-item-secondary {
    color: #828990;
    background-color: #f7fafc;
  }
  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #828990;
    background-color: #dee1e3;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #828990;
    border-color: #828990;
  }
  .list-group-item-success {
    color: #8e6856;
    background-color: #fbefe9;
  }
  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #8e6856;
    background-color: #e2d7d2;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #8e6856;
    border-color: #8e6856;
  }
  .list-group-item-info {
    color: #0e616e;
    background-color: #d1ecf1;
  }
  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #0e616e;
    background-color: #bcd4d9;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0e616e;
    border-color: #0e616e;
  }
  .list-group-item-warning {
    color: #997404;
    background-color: #fff3cd;
  }
  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #997404;
    background-color: #e6dbb9;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #997404;
    border-color: #997404;
  }
  .list-group-item-danger {
    color: #983d44;
    background-color: #ffe0e3;
  }
  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #983d44;
    background-color: #e6cacc;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #983d44;
    border-color: #983d44;
  }
  .list-group-item-light {
    color: #949597;
    background-color: #fdfefe;
  }
  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #949597;
    background-color: #e4e5e5;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #949597;
    border-color: #949597;
  }
  .list-group-item-dark {
    color: #8d9194;
    background-color: #fbfcfd;
  }
  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #8d9194;
    background-color: #e2e3e4;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #8d9194;
    border-color: #8d9194;
  }
  .list-group-item-white {
    color: #999;
    background-color: #fff;
  }
  .list-group-item-white.list-group-item-action:focus,
  .list-group-item-white.list-group-item-action:hover {
    color: #999;
    background-color: #e6e6e6;
  }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #999;
    border-color: #999;
  }
  .btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1.2em;
    height: 1.2em;
    padding: 0.25em 0.25em;
    color: #95aac9;
    background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%2395aac9' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e")
    center/1.2em auto no-repeat;
    border: 0;
    border-radius: 0.6rem;
    opacity: 0.5;
  }
  .btn-close:hover {
    color: #95aac9;
    text-decoration: none;
    opacity: 0.75;
  }
  .btn-close:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    box-shadow: 0 0 0 0 rgba(39, 135, 245, 0.25);
    opacity: 1;
  }
  .btn-close.disabled,
  .btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.25;
  }
  .btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(400%);
    filter: invert(1) grayscale(100%) brightness(400%);
  }
  .toast {
    width: 390px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.9);
    background-clip: padding-box;
    border: 2px solid #f5f8fb;
    -webkit-box-shadow: rgba(99, 99, 99, 0.1) 0 2px 8px 0;
    box-shadow: rgba(99, 99, 99, 0.1) 0 2px 8px 0;
    border-radius: 0.6rem;
  }
  .toast.showing {
    opacity: 0;
  }
  .toast:not(.show) {
    display: none;
  }
  .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }
  .toast-container > :not(:last-child) {
    margin-bottom: 1.25rem;
  }
  .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #95aac9;
    background-color: rgba(255, 255, 255, 0.5);
    background-clip: padding-box;
    border-bottom: 2px solid #f5f8fb;
    border-top-left-radius: calc(0.6rem - 2px);
    border-top-right-radius: calc(0.6rem - 2px);
  }
  .toast-header .btn-close {
    margin-right: -0.5rem;
    margin-left: 1rem;
  }
  .toast-body {
    padding: 1rem;
    word-wrap: break-word;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
  }
  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  .modal-dialog-scrollable {
    height: calc(100% - 1rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid #f5f8fb;
    border-radius: 0.8rem;
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.1;
  }
  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
    border-bottom: 2px solid #f5f8fb;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }
  .modal-header .btn-close {
    padding: 0.75rem 0.75rem;
    margin: -0.75rem -0.75rem -0.75rem auto;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.6;
  }
  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 2px solid #f5f8fb;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }
  .modal-footer > * {
    margin: 0.25rem;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
      height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 700px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen .modal-footer {
    border-radius: 0;
  }
  @media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0;
    }
  }
  @media (max-width: 1439.98px) {
    .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
    }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto;
    }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0;
    }
  }
  .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.6796875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.8;
  }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-tooltip-auto[data-popper-placement^="top"],
  .bs-tooltip-top {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
  .bs-tooltip-top .tooltip-arrow {
    bottom: 0;
  }
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
  .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  .bs-tooltip-auto[data-popper-placement^="right"],
  .bs-tooltip-end {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
  .bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
  .bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  .bs-tooltip-auto[data-popper-placement^="bottom"],
  .bs-tooltip-bottom {
    padding: 0.4rem 0;
  }
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
  .bs-tooltip-bottom .tooltip-arrow {
    top: 0;
  }
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
  .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  .bs-tooltip-auto[data-popper-placement^="left"],
  .bs-tooltip-start {
    padding: 0 0.4rem;
  }
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
  .bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
  .bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  .tooltip-inner {
    max-width: 200px;
    padding: 0.35rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.4rem;
  }
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.6796875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #f5f8fb;
    border-radius: 0.8rem;
  }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  .popover .popover-arrow::after,
  .popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
  .bs-popover-top > .popover-arrow {
    bottom: calc(-0.4rem - 2px);
  }
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
  .bs-popover-top > .popover-arrow::before {
    bottom: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #f5f8fb;
  }
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
  .bs-popover-top > .popover-arrow::after {
    bottom: 2px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
  }
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
  .bs-popover-end > .popover-arrow {
    left: calc(-0.4rem - 2px);
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
  .bs-popover-end > .popover-arrow::before {
    left: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #f5f8fb;
  }
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
  .bs-popover-end > .popover-arrow::after {
    left: 2px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #fff;
  }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
  .bs-popover-bottom > .popover-arrow {
    top: calc(-0.4rem - 2px);
  }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
  .bs-popover-bottom > .popover-arrow::before {
    top: 0;
    border-width: 0 0.4rem 0.4rem 0.4rem;
    border-bottom-color: #f5f8fb;
  }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
  .bs-popover-bottom > .popover-arrow::after {
    top: 2px;
    border-width: 0 0.4rem 0.4rem 0.4rem;
    border-bottom-color: #fff;
  }
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0.8rem;
    margin-left: -0.4rem;
    content: "";
    border-bottom: 2px solid #f5f8fb;
  }
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
  .bs-popover-start > .popover-arrow {
    right: calc(-0.4rem - 2px);
    width: 0.4rem;
    height: 0.8rem;
  }
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
  .bs-popover-start > .popover-arrow::before {
    right: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #f5f8fb;
  }
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
  .bs-popover-start > .popover-arrow::after {
    right: 2px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #fff;
  }
  .popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    color: #293951;
    background-color: #f5f8fb;
    border-bottom: 2px solid #f5f8fb;
    border-top-left-radius: calc(0.8rem - 2px);
    border-top-right-radius: calc(0.8rem - 2px);
  }
  .popover-header:empty {
    display: none;
  }
  .popover-body {
    padding: 1rem 1rem;
    color: #95aac9;
  }
  .carousel {
    position: relative;
  }
  .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none;
    }
  }
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: block;
  }
  .active.carousel-item-end,
  .carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .active.carousel-item-start,
  .carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
  }
  .carousel-fade .carousel-item-next.carousel-item-start,
  .carousel-fade .carousel-item-prev.carousel-item-end,
  .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
  }
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
      -webkit-transition: none;
      transition: none;
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-next,
    .carousel-control-prev {
      -webkit-transition: none;
      transition: none;
    }
  }
  .carousel-control-next:focus,
  .carousel-control-next:hover,
  .carousel-control-prev:focus,
  .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .carousel-indicators [data-bs-target] {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
      -webkit-transition: none;
      transition: none;
    }
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
  .carousel-dark .carousel-control-next-icon,
  .carousel-dark .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);
  }
  .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
  }
  .carousel-dark .carousel-caption {
    color: #000;
  }
  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
  }
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  @media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
  }
  .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      -webkit-transition: none;
      transition: none;
    }
  }
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  .offcanvas-backdrop.fade {
    opacity: 0;
  }
  .offcanvas-backdrop.show {
    opacity: 0.1;
  }
  .offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 1.5rem;
  }
  .offcanvas-header .btn-close {
    padding: 0.75rem 0.75rem;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.6;
  }
  .offcanvas-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 1.5rem 1.5rem;
    overflow-y: auto;
  }
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 2px solid #f5f8fb;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 2px solid #f5f8fb;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 2px solid #f5f8fb;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 2px solid #f5f8fb;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  .offcanvas.show {
    -webkit-transform: none;
    transform: none;
  }
  .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5;
  }
  .placeholder.btn::before {
    display: inline-block;
    content: "";
  }
  .placeholder-xs {
    min-height: 0.6em;
  }
  .placeholder-sm {
    min-height: 0.8em;
  }
  .placeholder-lg {
    min-height: 1.2em;
  }
  .placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
  }
  @-webkit-keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  .placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite;
  }
  @-webkit-keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0;
    }
  }
  @keyframes placeholder-wave {
    100% {
      -webkit-mask-position: -200% 0;
      mask-position: -200% 0;
    }
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .link-primary {
    color: #2787f5;
  }
  .link-primary:focus,
  .link-primary:hover {
    color: #1f6cc4;
  }
  .link-secondary {
    color: #d9e4f0;
  }
  .link-secondary:focus,
  .link-secondary:hover {
    color: #e1e9f3;
  }
  .link-success {
    color: #ecad8f;
  }
  .link-success:focus,
  .link-success:hover {
    color: #bd8a72;
  }
  .link-info {
    color: #17a2b8;
  }
  .link-info:focus,
  .link-info:hover {
    color: #128293;
  }
  .link-warning {
    color: #ffc107;
  }
  .link-warning:focus,
  .link-warning:hover {
    color: #cc9a06;
  }
  .link-danger {
    color: #fe6571;
  }
  .link-danger:focus,
  .link-danger:hover {
    color: #cb515a;
  }
  .link-light {
    color: #f6f9fb;
  }
  .link-light:focus,
  .link-light:hover {
    color: #f8fafc;
  }
  .link-dark {
    color: #ebf1f7;
  }
  .link-dark:focus,
  .link-dark:hover {
    color: #eff4f9;
  }
  .link-white {
    color: #fff;
  }
  .link-white:focus,
  .link-white:hover {
    color: #fff;
  }
  .ratio {
    position: relative;
    width: 100%;
  }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ratio-1x1 {
    --bs-aspect-ratio: 100%;
  }
  .ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%);
  }
  .ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%);
  }
  .ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%);
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1440px) {
    .sticky-xxl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  .hstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .vstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  .visually-hidden,
  .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .vr {
    display: inline-block;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 1;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .float-start {
    float: left !important;
  }
  .float-end {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
  .opacity-25 {
    opacity: 0.25 !important;
  }
  .opacity-50 {
    opacity: 0.5 !important;
  }
  .opacity-75 {
    opacity: 0.75 !important;
  }
  .opacity-100 {
    opacity: 1 !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-grid {
    display: grid !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-none {
    display: none !important;
  }
  .shadow {
    -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg {
    -webkit-box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.1) !important;
  }
  .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: sticky !important;
  }
  .top-0 {
    top: 0 !important;
  }
  .top-50 {
    top: 50% !important;
  }
  .top-100 {
    top: 100% !important;
  }
  .bottom-0 {
    bottom: 0 !important;
  }
  .bottom-50 {
    bottom: 50% !important;
  }
  .bottom-100 {
    bottom: 100% !important;
  }
  .start-0 {
    left: 0 !important;
  }
  .start-50 {
    left: 50% !important;
  }
  .start-100 {
    left: 100% !important;
  }
  .end-0 {
    right: 0 !important;
  }
  .end-50 {
    right: 50% !important;
  }
  .end-100 {
    right: 100% !important;
  }
  .translate-middle {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-x {
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }
  .translate-middle-y {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }
  .border {
    border: 2px solid #f5f8fb !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top {
    border-top: 2px solid #f5f8fb !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-end {
    border-right: 2px solid #f5f8fb !important;
  }
  .border-end-0 {
    border-right: 0 !important;
  }
  .border-bottom {
    border-bottom: 2px solid #f5f8fb !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-start {
    border-left: 2px solid #f5f8fb !important;
  }
  .border-start-0 {
    border-left: 0 !important;
  }
  .border-primary {
    border-color: #2787f5 !important;
  }
  .border-secondary {
    border-color: #d9e4f0 !important;
  }
  .border-success {
    border-color: #ecad8f !important;
  }
  .border-info {
    border-color: #17a2b8 !important;
  }
  .border-warning {
    border-color: #ffc107 !important;
  }
  .border-danger {
    border-color: #fe6571 !important;
  }
  .border-light {
    border-color: #f6f9fb !important;
  }
  .border-dark {
    border-color: #ebf1f7 !important;
  }
  .border-white {
    border-color: #fff !important;
  }
  .border-1 {
    border-width: 1px !important;
  }
  .border-2 {
    border-width: 2px !important;
  }
  .border-3 {
    border-width: 3px !important;
  }
  .border-4 {
    border-width: 4px !important;
  }
  .border-5 {
    border-width: 5px !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-0 {
    gap: 0 !important;
  }
  .gap-1 {
    gap: 0.125rem !important;
  }
  .gap-2 {
    gap: 0.25rem !important;
  }
  .gap-3 {
    gap: 0.5rem !important;
  }
  .gap-4 {
    gap: 0.75rem !important;
  }
  .gap-5 {
    gap: 1rem !important;
  }
  .gap-6 {
    gap: 1.25rem !important;
  }
  .gap-7 {
    gap: 1.5rem !important;
  }
  .gap-8 {
    gap: 1.75rem !important;
  }
  .gap-9 {
    gap: 2rem !important;
  }
  .gap-10 {
    gap: 2.5rem !important;
  }
  .gap-11 {
    gap: 3rem !important;
  }
  .gap-12 {
    gap: 3.5rem !important;
  }
  .gap-13 {
    gap: 4rem !important;
  }
  .gap-14 {
    gap: 4.5rem !important;
  }
  .gap-15 {
    gap: 5rem !important;
  }
  .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.125rem !important;
  }
  .m-2 {
    margin: 0.25rem !important;
  }
  .m-3 {
    margin: 0.5rem !important;
  }
  .m-4 {
    margin: 0.75rem !important;
  }
  .m-5 {
    margin: 1rem !important;
  }
  .m-6 {
    margin: 1.25rem !important;
  }
  .m-7 {
    margin: 1.5rem !important;
  }
  .m-8 {
    margin: 1.75rem !important;
  }
  .m-9 {
    margin: 2rem !important;
  }
  .m-10 {
    margin: 2.5rem !important;
  }
  .m-11 {
    margin: 3rem !important;
  }
  .m-12 {
    margin: 3.5rem !important;
  }
  .m-13 {
    margin: 4rem !important;
  }
  .m-14 {
    margin: 4.5rem !important;
  }
  .m-15 {
    margin: 5rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-1 {
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important;
  }
  .mx-2 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-3 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-1 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-2 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-3 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-1 {
    margin-top: 0.125rem !important;
  }
  .mt-2 {
    margin-top: 0.25rem !important;
  }
  .mt-3 {
    margin-top: 0.5rem !important;
  }
  .mt-4 {
    margin-top: 0.75rem !important;
  }
  .mt-5 {
    margin-top: 1rem !important;
  }
  .mt-6 {
    margin-top: 1.25rem !important;
  }
  .mt-7 {
    margin-top: 1.5rem !important;
  }
  .mt-8 {
    margin-top: 1.75rem !important;
  }
  .mt-9 {
    margin-top: 2rem !important;
  }
  .mt-10 {
    margin-top: 2.5rem !important;
  }
  .mt-11 {
    margin-top: 3rem !important;
  }
  .mt-12 {
    margin-top: 3.5rem !important;
  }
  .mt-13 {
    margin-top: 4rem !important;
  }
  .mt-14 {
    margin-top: 4.5rem !important;
  }
  .mt-15 {
    margin-top: 5rem !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .me-0 {
    margin-right: 0 !important;
  }
  .me-1 {
    margin-right: 0.125rem !important;
  }
  .me-2 {
    margin-right: 0.25rem !important;
  }
  .me-3 {
    margin-right: 0.5rem !important;
  }
  .me-4 {
    margin-right: 0.75rem !important;
  }
  .me-5 {
    margin-right: 1rem !important;
  }
  .me-6 {
    margin-right: 1.25rem !important;
  }
  .me-7 {
    margin-right: 1.5rem !important;
  }
  .me-8 {
    margin-right: 1.75rem !important;
  }
  .me-9 {
    margin-right: 2rem !important;
  }
  .me-10 {
    margin-right: 2.5rem !important;
  }
  .me-11 {
    margin-right: 3rem !important;
  }
  .me-12 {
    margin-right: 3.5rem !important;
  }
  .me-13 {
    margin-right: 4rem !important;
  }
  .me-14 {
    margin-right: 4.5rem !important;
  }
  .me-15 {
    margin-right: 5rem !important;
  }
  .me-auto {
    margin-right: auto !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.125rem !important;
  }
  .mb-2 {
    margin-bottom: 0.25rem !important;
  }
  .mb-3 {
    margin-bottom: 0.5rem !important;
  }
  .mb-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-5 {
    margin-bottom: 1rem !important;
  }
  .mb-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-9 {
    margin-bottom: 2rem !important;
  }
  .mb-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-11 {
    margin-bottom: 3rem !important;
  }
  .mb-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-13 {
    margin-bottom: 4rem !important;
  }
  .mb-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-15 {
    margin-bottom: 5rem !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ms-0 {
    margin-left: 0 !important;
  }
  .ms-1 {
    margin-left: 0.125rem !important;
  }
  .ms-2 {
    margin-left: 0.25rem !important;
  }
  .ms-3 {
    margin-left: 0.5rem !important;
  }
  .ms-4 {
    margin-left: 0.75rem !important;
  }
  .ms-5 {
    margin-left: 1rem !important;
  }
  .ms-6 {
    margin-left: 1.25rem !important;
  }
  .ms-7 {
    margin-left: 1.5rem !important;
  }
  .ms-8 {
    margin-left: 1.75rem !important;
  }
  .ms-9 {
    margin-left: 2rem !important;
  }
  .ms-10 {
    margin-left: 2.5rem !important;
  }
  .ms-11 {
    margin-left: 3rem !important;
  }
  .ms-12 {
    margin-left: 3.5rem !important;
  }
  .ms-13 {
    margin-left: 4rem !important;
  }
  .ms-14 {
    margin-left: 4.5rem !important;
  }
  .ms-15 {
    margin-left: 5rem !important;
  }
  .ms-auto {
    margin-left: auto !important;
  }
  .m-n1 {
    margin: -0.125rem !important;
  }
  .m-n2 {
    margin: -0.25rem !important;
  }
  .m-n3 {
    margin: -0.5rem !important;
  }
  .m-n4 {
    margin: -0.75rem !important;
  }
  .m-n5 {
    margin: -1rem !important;
  }
  .m-n6 {
    margin: -1.25rem !important;
  }
  .m-n7 {
    margin: -1.5rem !important;
  }
  .m-n8 {
    margin: -1.75rem !important;
  }
  .m-n9 {
    margin: -2rem !important;
  }
  .m-n10 {
    margin: -2.5rem !important;
  }
  .m-n11 {
    margin: -3rem !important;
  }
  .m-n12 {
    margin: -3.5rem !important;
  }
  .m-n13 {
    margin: -4rem !important;
  }
  .m-n14 {
    margin: -4.5rem !important;
  }
  .m-n15 {
    margin: -5rem !important;
  }
  .mx-n1 {
    margin-right: -0.125rem !important;
    margin-left: -0.125rem !important;
  }
  .mx-n2 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-n3 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-n4 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-n5 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-n6 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-n7 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-n8 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }
  .mx-n9 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-n11 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-n12 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-n13 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-n14 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-n15 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .my-n1 {
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }
  .my-n2 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-n3 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-n4 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-n5 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-n6 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-n7 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-n8 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }
  .my-n9 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-n11 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-n12 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-n13 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-n14 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-n15 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mt-n1 {
    margin-top: -0.125rem !important;
  }
  .mt-n2 {
    margin-top: -0.25rem !important;
  }
  .mt-n3 {
    margin-top: -0.5rem !important;
  }
  .mt-n4 {
    margin-top: -0.75rem !important;
  }
  .mt-n5 {
    margin-top: -1rem !important;
  }
  .mt-n6 {
    margin-top: -1.25rem !important;
  }
  .mt-n7 {
    margin-top: -1.5rem !important;
  }
  .mt-n8 {
    margin-top: -1.75rem !important;
  }
  .mt-n9 {
    margin-top: -2rem !important;
  }
  .mt-n10 {
    margin-top: -2.5rem !important;
  }
  .mt-n11 {
    margin-top: -3rem !important;
  }
  .mt-n12 {
    margin-top: -3.5rem !important;
  }
  .mt-n13 {
    margin-top: -4rem !important;
  }
  .mt-n14 {
    margin-top: -4.5rem !important;
  }
  .mt-n15 {
    margin-top: -5rem !important;
  }
  .me-n1 {
    margin-right: -0.125rem !important;
  }
  .me-n2 {
    margin-right: -0.25rem !important;
  }
  .me-n3 {
    margin-right: -0.5rem !important;
  }
  .me-n4 {
    margin-right: -0.75rem !important;
  }
  .me-n5 {
    margin-right: -1rem !important;
  }
  .me-n6 {
    margin-right: -1.25rem !important;
  }
  .me-n7 {
    margin-right: -1.5rem !important;
  }
  .me-n8 {
    margin-right: -1.75rem !important;
  }
  .me-n9 {
    margin-right: -2rem !important;
  }
  .me-n10 {
    margin-right: -2.5rem !important;
  }
  .me-n11 {
    margin-right: -3rem !important;
  }
  .me-n12 {
    margin-right: -3.5rem !important;
  }
  .me-n13 {
    margin-right: -4rem !important;
  }
  .me-n14 {
    margin-right: -4.5rem !important;
  }
  .me-n15 {
    margin-right: -5rem !important;
  }
  .mb-n1 {
    margin-bottom: -0.125rem !important;
  }
  .mb-n2 {
    margin-bottom: -0.25rem !important;
  }
  .mb-n3 {
    margin-bottom: -0.5rem !important;
  }
  .mb-n4 {
    margin-bottom: -0.75rem !important;
  }
  .mb-n5 {
    margin-bottom: -1rem !important;
  }
  .mb-n6 {
    margin-bottom: -1.25rem !important;
  }
  .mb-n7 {
    margin-bottom: -1.5rem !important;
  }
  .mb-n8 {
    margin-bottom: -1.75rem !important;
  }
  .mb-n9 {
    margin-bottom: -2rem !important;
  }
  .mb-n10 {
    margin-bottom: -2.5rem !important;
  }
  .mb-n11 {
    margin-bottom: -3rem !important;
  }
  .mb-n12 {
    margin-bottom: -3.5rem !important;
  }
  .mb-n13 {
    margin-bottom: -4rem !important;
  }
  .mb-n14 {
    margin-bottom: -4.5rem !important;
  }
  .mb-n15 {
    margin-bottom: -5rem !important;
  }
  .ms-n1 {
    margin-left: -0.125rem !important;
  }
  .ms-n2 {
    margin-left: -0.25rem !important;
  }
  .ms-n3 {
    margin-left: -0.5rem !important;
  }
  .ms-n4 {
    margin-left: -0.75rem !important;
  }
  .ms-n5 {
    margin-left: -1rem !important;
  }
  .ms-n6 {
    margin-left: -1.25rem !important;
  }
  .ms-n7 {
    margin-left: -1.5rem !important;
  }
  .ms-n8 {
    margin-left: -1.75rem !important;
  }
  .ms-n9 {
    margin-left: -2rem !important;
  }
  .ms-n10 {
    margin-left: -2.5rem !important;
  }
  .ms-n11 {
    margin-left: -3rem !important;
  }
  .ms-n12 {
    margin-left: -3.5rem !important;
  }
  .ms-n13 {
    margin-left: -4rem !important;
  }
  .ms-n14 {
    margin-left: -4.5rem !important;
  }
  .ms-n15 {
    margin-left: -5rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.125rem !important;
  }
  .p-2 {
    padding: 0.25rem !important;
  }
  .p-3 {
    padding: 0.5rem !important;
  }
  .p-4 {
    padding: 0.75rem !important;
  }
  .p-5 {
    padding: 1rem !important;
  }
  .p-6 {
    padding: 1.25rem !important;
  }
  .p-7 {
    padding: 1.5rem !important;
  }
  .p-8 {
    padding: 1.75rem !important;
  }
  .p-9 {
    padding: 2rem !important;
  }
  .p-10 {
    padding: 2.5rem !important;
  }
  .p-11 {
    padding: 3rem !important;
  }
  .p-12 {
    padding: 3.5rem !important;
  }
  .p-13 {
    padding: 4rem !important;
  }
  .p-14 {
    padding: 4.5rem !important;
  }
  .p-15 {
    padding: 5rem !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.125rem !important;
    padding-left: 0.125rem !important;
  }
  .px-2 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-3 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-2 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-3 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.125rem !important;
  }
  .pt-2 {
    padding-top: 0.25rem !important;
  }
  .pt-3 {
    padding-top: 0.5rem !important;
  }
  .pt-4 {
    padding-top: 0.75rem !important;
  }
  .pt-5 {
    padding-top: 1rem !important;
  }
  .pt-6 {
    padding-top: 1.25rem !important;
  }
  .pt-7 {
    padding-top: 1.5rem !important;
  }
  .pt-8 {
    padding-top: 1.75rem !important;
  }
  .pt-9 {
    padding-top: 2rem !important;
  }
  .pt-10 {
    padding-top: 2.5rem !important;
  }
  .pt-11 {
    padding-top: 3rem !important;
  }
  .pt-12 {
    padding-top: 3.5rem !important;
  }
  .pt-13 {
    padding-top: 4rem !important;
  }
  .pt-14 {
    padding-top: 4.5rem !important;
  }
  .pt-15 {
    padding-top: 5rem !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  .pe-1 {
    padding-right: 0.125rem !important;
  }
  .pe-2 {
    padding-right: 0.25rem !important;
  }
  .pe-3 {
    padding-right: 0.5rem !important;
  }
  .pe-4 {
    padding-right: 0.75rem !important;
  }
  .pe-5 {
    padding-right: 1rem !important;
  }
  .pe-6 {
    padding-right: 1.25rem !important;
  }
  .pe-7 {
    padding-right: 1.5rem !important;
  }
  .pe-8 {
    padding-right: 1.75rem !important;
  }
  .pe-9 {
    padding-right: 2rem !important;
  }
  .pe-10 {
    padding-right: 2.5rem !important;
  }
  .pe-11 {
    padding-right: 3rem !important;
  }
  .pe-12 {
    padding-right: 3.5rem !important;
  }
  .pe-13 {
    padding-right: 4rem !important;
  }
  .pe-14 {
    padding-right: 4.5rem !important;
  }
  .pe-15 {
    padding-right: 5rem !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.125rem !important;
  }
  .pb-2 {
    padding-bottom: 0.25rem !important;
  }
  .pb-3 {
    padding-bottom: 0.5rem !important;
  }
  .pb-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-5 {
    padding-bottom: 1rem !important;
  }
  .pb-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-9 {
    padding-bottom: 2rem !important;
  }
  .pb-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-11 {
    padding-bottom: 3rem !important;
  }
  .pb-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-13 {
    padding-bottom: 4rem !important;
  }
  .pb-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-15 {
    padding-bottom: 5rem !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .ps-1 {
    padding-left: 0.125rem !important;
  }
  .ps-2 {
    padding-left: 0.25rem !important;
  }
  .ps-3 {
    padding-left: 0.5rem !important;
  }
  .ps-4 {
    padding-left: 0.75rem !important;
  }
  .ps-5 {
    padding-left: 1rem !important;
  }
  .ps-6 {
    padding-left: 1.25rem !important;
  }
  .ps-7 {
    padding-left: 1.5rem !important;
  }
  .ps-8 {
    padding-left: 1.75rem !important;
  }
  .ps-9 {
    padding-left: 2rem !important;
  }
  .ps-10 {
    padding-left: 2.5rem !important;
  }
  .ps-11 {
    padding-left: 3rem !important;
  }
  .ps-12 {
    padding-left: 3.5rem !important;
  }
  .ps-13 {
    padding-left: 4rem !important;
  }
  .ps-14 {
    padding-left: 4.5rem !important;
  }
  .ps-15 {
    padding-left: 5rem !important;
  }
  .font-monospace {
    font-family: var(--bs-font-monospace) !important;
  }
  .fs-1 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-2 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-3 {
    font-size: 1.125rem !important;
  }
  .fs-4 {
    font-size: 1rem !important;
  }
  .fs-5 {
    font-size: 0.9375rem !important;
  }
  .fs-6 {
    font-size: 0.875rem !important;
  }
  .fst-italic {
    font-style: italic !important;
  }
  .fst-normal {
    font-style: normal !important;
  }
  .fw-light {
    font-weight: 300 !important;
  }
  .fw-lighter {
    font-weight: lighter !important;
  }
  .fw-normal {
    font-weight: 400 !important;
  }
  .fw-bold {
    font-weight: 700 !important;
  }
  .fw-bolder {
    font-weight: bolder !important;
  }
  .lh-1 {
    line-height: 1 !important;
  }
  .lh-sm {
    line-height: 1.3 !important;
  }
  .lh-base {
    line-height: 1.6 !important;
  }
  .lh-lg {
    line-height: 2 !important;
  }
  .text-start {
    text-align: left !important;
  }
  .text-end {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-decoration-underline {
    text-decoration: underline !important;
  }
  .text-decoration-line-through {
    text-decoration: line-through !important;
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
  }
  .text-muted {
    --bs-text-opacity: 1;
    color: #bfccdf !important;
  }
  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-reset {
    --bs-text-opacity: 1;
    color: inherit !important;
  }
  .text-opacity-25 {
    --bs-text-opacity: 0.25;
  }
  .text-opacity-50 {
    --bs-text-opacity: 0.5;
  }
  .text-opacity-75 {
    --bs-text-opacity: 0.75;
  }
  .text-opacity-100 {
    --bs-text-opacity: 1;
  }
  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(235, 241, 247 , 1) !important;
  }
  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .bg-opacity-10 {
    --bs-bg-opacity: 0.1;
  }
  .bg-opacity-25 {
    --bs-bg-opacity: 0.25;
  }
  .bg-opacity-50 {
    --bs-bg-opacity: 0.5;
  }
  .bg-opacity-75 {
    --bs-bg-opacity: 0.75;
  }
  .bg-opacity-100 {
    --bs-bg-opacity: 1;
  }
  .bg-gradient {
    background-image: var(--bs-gradient) !important;
  }
  .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
  }
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  .pe-none {
    pointer-events: none !important;
  }
  .pe-auto {
    pointer-events: auto !important;
  }
  .rounded {
    border-radius: 0.6rem !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .rounded-1 {
    border-radius: 0.4rem !important;
  }
  .rounded-2 {
    border-radius: 0.6rem !important;
  }
  .rounded-3 {
    border-radius: 0.8rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-pill {
    border-radius: 2.25rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
  }
  .rounded-end {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important;
  }
  .rounded-start {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  .visibility-visible {
    visibility: visible !important;
  }
  .visibility-invisible {
    visibility: hidden !important;
  }
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important;
    }
    .float-sm-end {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-sm-0 {
      gap: 0 !important;
    }
    .gap-sm-1 {
      gap: 0.125rem !important;
    }
    .gap-sm-2 {
      gap: 0.25rem !important;
    }
    .gap-sm-3 {
      gap: 0.5rem !important;
    }
    .gap-sm-4 {
      gap: 0.75rem !important;
    }
    .gap-sm-5 {
      gap: 1rem !important;
    }
    .gap-sm-6 {
      gap: 1.25rem !important;
    }
    .gap-sm-7 {
      gap: 1.5rem !important;
    }
    .gap-sm-8 {
      gap: 1.75rem !important;
    }
    .gap-sm-9 {
      gap: 2rem !important;
    }
    .gap-sm-10 {
      gap: 2.5rem !important;
    }
    .gap-sm-11 {
      gap: 3rem !important;
    }
    .gap-sm-12 {
      gap: 3.5rem !important;
    }
    .gap-sm-13 {
      gap: 4rem !important;
    }
    .gap-sm-14 {
      gap: 4.5rem !important;
    }
    .gap-sm-15 {
      gap: 5rem !important;
    }
    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-sm-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-sm-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-sm-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-sm-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-sm-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-sm-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-sm-0 {
      margin: 0 !important;
    }
    .m-sm-1 {
      margin: 0.125rem !important;
    }
    .m-sm-2 {
      margin: 0.25rem !important;
    }
    .m-sm-3 {
      margin: 0.5rem !important;
    }
    .m-sm-4 {
      margin: 0.75rem !important;
    }
    .m-sm-5 {
      margin: 1rem !important;
    }
    .m-sm-6 {
      margin: 1.25rem !important;
    }
    .m-sm-7 {
      margin: 1.5rem !important;
    }
    .m-sm-8 {
      margin: 1.75rem !important;
    }
    .m-sm-9 {
      margin: 2rem !important;
    }
    .m-sm-10 {
      margin: 2.5rem !important;
    }
    .m-sm-11 {
      margin: 3rem !important;
    }
    .m-sm-12 {
      margin: 3.5rem !important;
    }
    .m-sm-13 {
      margin: 4rem !important;
    }
    .m-sm-14 {
      margin: 4.5rem !important;
    }
    .m-sm-15 {
      margin: 5rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-sm-1 {
      margin-right: 0.125rem !important;
      margin-left: 0.125rem !important;
    }
    .mx-sm-2 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-sm-3 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-sm-4 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-sm-5 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-sm-6 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-sm-7 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-sm-8 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-sm-9 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-sm-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-sm-11 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-sm-12 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-sm-13 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-sm-14 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-sm-15 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-sm-1 {
      margin-top: 0.125rem !important;
      margin-bottom: 0.125rem !important;
    }
    .my-sm-2 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-sm-3 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-sm-4 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-sm-5 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-sm-6 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-sm-7 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-sm-8 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-sm-9 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-sm-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-sm-11 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-sm-12 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-sm-13 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-sm-14 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-sm-15 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-sm-0 {
      margin-top: 0 !important;
    }
    .mt-sm-1 {
      margin-top: 0.125rem !important;
    }
    .mt-sm-2 {
      margin-top: 0.25rem !important;
    }
    .mt-sm-3 {
      margin-top: 0.5rem !important;
    }
    .mt-sm-4 {
      margin-top: 0.75rem !important;
    }
    .mt-sm-5 {
      margin-top: 1rem !important;
    }
    .mt-sm-6 {
      margin-top: 1.25rem !important;
    }
    .mt-sm-7 {
      margin-top: 1.5rem !important;
    }
    .mt-sm-8 {
      margin-top: 1.75rem !important;
    }
    .mt-sm-9 {
      margin-top: 2rem !important;
    }
    .mt-sm-10 {
      margin-top: 2.5rem !important;
    }
    .mt-sm-11 {
      margin-top: 3rem !important;
    }
    .mt-sm-12 {
      margin-top: 3.5rem !important;
    }
    .mt-sm-13 {
      margin-top: 4rem !important;
    }
    .mt-sm-14 {
      margin-top: 4.5rem !important;
    }
    .mt-sm-15 {
      margin-top: 5rem !important;
    }
    .mt-sm-auto {
      margin-top: auto !important;
    }
    .me-sm-0 {
      margin-right: 0 !important;
    }
    .me-sm-1 {
      margin-right: 0.125rem !important;
    }
    .me-sm-2 {
      margin-right: 0.25rem !important;
    }
    .me-sm-3 {
      margin-right: 0.5rem !important;
    }
    .me-sm-4 {
      margin-right: 0.75rem !important;
    }
    .me-sm-5 {
      margin-right: 1rem !important;
    }
    .me-sm-6 {
      margin-right: 1.25rem !important;
    }
    .me-sm-7 {
      margin-right: 1.5rem !important;
    }
    .me-sm-8 {
      margin-right: 1.75rem !important;
    }
    .me-sm-9 {
      margin-right: 2rem !important;
    }
    .me-sm-10 {
      margin-right: 2.5rem !important;
    }
    .me-sm-11 {
      margin-right: 3rem !important;
    }
    .me-sm-12 {
      margin-right: 3.5rem !important;
    }
    .me-sm-13 {
      margin-right: 4rem !important;
    }
    .me-sm-14 {
      margin-right: 4.5rem !important;
    }
    .me-sm-15 {
      margin-right: 5rem !important;
    }
    .me-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
    .mb-sm-1 {
      margin-bottom: 0.125rem !important;
    }
    .mb-sm-2 {
      margin-bottom: 0.25rem !important;
    }
    .mb-sm-3 {
      margin-bottom: 0.5rem !important;
    }
    .mb-sm-4 {
      margin-bottom: 0.75rem !important;
    }
    .mb-sm-5 {
      margin-bottom: 1rem !important;
    }
    .mb-sm-6 {
      margin-bottom: 1.25rem !important;
    }
    .mb-sm-7 {
      margin-bottom: 1.5rem !important;
    }
    .mb-sm-8 {
      margin-bottom: 1.75rem !important;
    }
    .mb-sm-9 {
      margin-bottom: 2rem !important;
    }
    .mb-sm-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-sm-11 {
      margin-bottom: 3rem !important;
    }
    .mb-sm-12 {
      margin-bottom: 3.5rem !important;
    }
    .mb-sm-13 {
      margin-bottom: 4rem !important;
    }
    .mb-sm-14 {
      margin-bottom: 4.5rem !important;
    }
    .mb-sm-15 {
      margin-bottom: 5rem !important;
    }
    .mb-sm-auto {
      margin-bottom: auto !important;
    }
    .ms-sm-0 {
      margin-left: 0 !important;
    }
    .ms-sm-1 {
      margin-left: 0.125rem !important;
    }
    .ms-sm-2 {
      margin-left: 0.25rem !important;
    }
    .ms-sm-3 {
      margin-left: 0.5rem !important;
    }
    .ms-sm-4 {
      margin-left: 0.75rem !important;
    }
    .ms-sm-5 {
      margin-left: 1rem !important;
    }
    .ms-sm-6 {
      margin-left: 1.25rem !important;
    }
    .ms-sm-7 {
      margin-left: 1.5rem !important;
    }
    .ms-sm-8 {
      margin-left: 1.75rem !important;
    }
    .ms-sm-9 {
      margin-left: 2rem !important;
    }
    .ms-sm-10 {
      margin-left: 2.5rem !important;
    }
    .ms-sm-11 {
      margin-left: 3rem !important;
    }
    .ms-sm-12 {
      margin-left: 3.5rem !important;
    }
    .ms-sm-13 {
      margin-left: 4rem !important;
    }
    .ms-sm-14 {
      margin-left: 4.5rem !important;
    }
    .ms-sm-15 {
      margin-left: 5rem !important;
    }
    .ms-sm-auto {
      margin-left: auto !important;
    }
    .m-sm-n1 {
      margin: -0.125rem !important;
    }
    .m-sm-n2 {
      margin: -0.25rem !important;
    }
    .m-sm-n3 {
      margin: -0.5rem !important;
    }
    .m-sm-n4 {
      margin: -0.75rem !important;
    }
    .m-sm-n5 {
      margin: -1rem !important;
    }
    .m-sm-n6 {
      margin: -1.25rem !important;
    }
    .m-sm-n7 {
      margin: -1.5rem !important;
    }
    .m-sm-n8 {
      margin: -1.75rem !important;
    }
    .m-sm-n9 {
      margin: -2rem !important;
    }
    .m-sm-n10 {
      margin: -2.5rem !important;
    }
    .m-sm-n11 {
      margin: -3rem !important;
    }
    .m-sm-n12 {
      margin: -3.5rem !important;
    }
    .m-sm-n13 {
      margin: -4rem !important;
    }
    .m-sm-n14 {
      margin: -4.5rem !important;
    }
    .m-sm-n15 {
      margin: -5rem !important;
    }
    .mx-sm-n1 {
      margin-right: -0.125rem !important;
      margin-left: -0.125rem !important;
    }
    .mx-sm-n2 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-sm-n3 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-sm-n4 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-sm-n5 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-sm-n6 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-sm-n7 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-sm-n8 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-sm-n9 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-sm-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-sm-n11 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-sm-n12 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-sm-n13 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-sm-n14 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-sm-n15 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .my-sm-n1 {
      margin-top: -0.125rem !important;
      margin-bottom: -0.125rem !important;
    }
    .my-sm-n2 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-sm-n3 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-sm-n4 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-sm-n5 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-sm-n6 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-sm-n7 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-sm-n8 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-sm-n9 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-sm-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-sm-n11 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-sm-n12 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-sm-n13 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-sm-n14 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-sm-n15 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .mt-sm-n1 {
      margin-top: -0.125rem !important;
    }
    .mt-sm-n2 {
      margin-top: -0.25rem !important;
    }
    .mt-sm-n3 {
      margin-top: -0.5rem !important;
    }
    .mt-sm-n4 {
      margin-top: -0.75rem !important;
    }
    .mt-sm-n5 {
      margin-top: -1rem !important;
    }
    .mt-sm-n6 {
      margin-top: -1.25rem !important;
    }
    .mt-sm-n7 {
      margin-top: -1.5rem !important;
    }
    .mt-sm-n8 {
      margin-top: -1.75rem !important;
    }
    .mt-sm-n9 {
      margin-top: -2rem !important;
    }
    .mt-sm-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-sm-n11 {
      margin-top: -3rem !important;
    }
    .mt-sm-n12 {
      margin-top: -3.5rem !important;
    }
    .mt-sm-n13 {
      margin-top: -4rem !important;
    }
    .mt-sm-n14 {
      margin-top: -4.5rem !important;
    }
    .mt-sm-n15 {
      margin-top: -5rem !important;
    }
    .me-sm-n1 {
      margin-right: -0.125rem !important;
    }
    .me-sm-n2 {
      margin-right: -0.25rem !important;
    }
    .me-sm-n3 {
      margin-right: -0.5rem !important;
    }
    .me-sm-n4 {
      margin-right: -0.75rem !important;
    }
    .me-sm-n5 {
      margin-right: -1rem !important;
    }
    .me-sm-n6 {
      margin-right: -1.25rem !important;
    }
    .me-sm-n7 {
      margin-right: -1.5rem !important;
    }
    .me-sm-n8 {
      margin-right: -1.75rem !important;
    }
    .me-sm-n9 {
      margin-right: -2rem !important;
    }
    .me-sm-n10 {
      margin-right: -2.5rem !important;
    }
    .me-sm-n11 {
      margin-right: -3rem !important;
    }
    .me-sm-n12 {
      margin-right: -3.5rem !important;
    }
    .me-sm-n13 {
      margin-right: -4rem !important;
    }
    .me-sm-n14 {
      margin-right: -4.5rem !important;
    }
    .me-sm-n15 {
      margin-right: -5rem !important;
    }
    .mb-sm-n1 {
      margin-bottom: -0.125rem !important;
    }
    .mb-sm-n2 {
      margin-bottom: -0.25rem !important;
    }
    .mb-sm-n3 {
      margin-bottom: -0.5rem !important;
    }
    .mb-sm-n4 {
      margin-bottom: -0.75rem !important;
    }
    .mb-sm-n5 {
      margin-bottom: -1rem !important;
    }
    .mb-sm-n6 {
      margin-bottom: -1.25rem !important;
    }
    .mb-sm-n7 {
      margin-bottom: -1.5rem !important;
    }
    .mb-sm-n8 {
      margin-bottom: -1.75rem !important;
    }
    .mb-sm-n9 {
      margin-bottom: -2rem !important;
    }
    .mb-sm-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-sm-n11 {
      margin-bottom: -3rem !important;
    }
    .mb-sm-n12 {
      margin-bottom: -3.5rem !important;
    }
    .mb-sm-n13 {
      margin-bottom: -4rem !important;
    }
    .mb-sm-n14 {
      margin-bottom: -4.5rem !important;
    }
    .mb-sm-n15 {
      margin-bottom: -5rem !important;
    }
    .ms-sm-n1 {
      margin-left: -0.125rem !important;
    }
    .ms-sm-n2 {
      margin-left: -0.25rem !important;
    }
    .ms-sm-n3 {
      margin-left: -0.5rem !important;
    }
    .ms-sm-n4 {
      margin-left: -0.75rem !important;
    }
    .ms-sm-n5 {
      margin-left: -1rem !important;
    }
    .ms-sm-n6 {
      margin-left: -1.25rem !important;
    }
    .ms-sm-n7 {
      margin-left: -1.5rem !important;
    }
    .ms-sm-n8 {
      margin-left: -1.75rem !important;
    }
    .ms-sm-n9 {
      margin-left: -2rem !important;
    }
    .ms-sm-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-sm-n11 {
      margin-left: -3rem !important;
    }
    .ms-sm-n12 {
      margin-left: -3.5rem !important;
    }
    .ms-sm-n13 {
      margin-left: -4rem !important;
    }
    .ms-sm-n14 {
      margin-left: -4.5rem !important;
    }
    .ms-sm-n15 {
      margin-left: -5rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .p-sm-1 {
      padding: 0.125rem !important;
    }
    .p-sm-2 {
      padding: 0.25rem !important;
    }
    .p-sm-3 {
      padding: 0.5rem !important;
    }
    .p-sm-4 {
      padding: 0.75rem !important;
    }
    .p-sm-5 {
      padding: 1rem !important;
    }
    .p-sm-6 {
      padding: 1.25rem !important;
    }
    .p-sm-7 {
      padding: 1.5rem !important;
    }
    .p-sm-8 {
      padding: 1.75rem !important;
    }
    .p-sm-9 {
      padding: 2rem !important;
    }
    .p-sm-10 {
      padding: 2.5rem !important;
    }
    .p-sm-11 {
      padding: 3rem !important;
    }
    .p-sm-12 {
      padding: 3.5rem !important;
    }
    .p-sm-13 {
      padding: 4rem !important;
    }
    .p-sm-14 {
      padding: 4.5rem !important;
    }
    .p-sm-15 {
      padding: 5rem !important;
    }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-sm-1 {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important;
    }
    .px-sm-2 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-sm-3 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-sm-4 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-sm-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-sm-6 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-sm-7 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-sm-8 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-sm-9 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-sm-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-sm-11 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-sm-12 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-sm-13 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-sm-14 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-sm-15 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-sm-1 {
      padding-top: 0.125rem !important;
      padding-bottom: 0.125rem !important;
    }
    .py-sm-2 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-sm-3 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-sm-4 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-sm-5 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-sm-6 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-sm-7 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-sm-8 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-sm-9 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-sm-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-sm-11 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-sm-12 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-sm-13 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-sm-14 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-sm-15 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .pt-sm-0 {
      padding-top: 0 !important;
    }
    .pt-sm-1 {
      padding-top: 0.125rem !important;
    }
    .pt-sm-2 {
      padding-top: 0.25rem !important;
    }
    .pt-sm-3 {
      padding-top: 0.5rem !important;
    }
    .pt-sm-4 {
      padding-top: 0.75rem !important;
    }
    .pt-sm-5 {
      padding-top: 1rem !important;
    }
    .pt-sm-6 {
      padding-top: 1.25rem !important;
    }
    .pt-sm-7 {
      padding-top: 1.5rem !important;
    }
    .pt-sm-8 {
      padding-top: 1.75rem !important;
    }
    .pt-sm-9 {
      padding-top: 2rem !important;
    }
    .pt-sm-10 {
      padding-top: 2.5rem !important;
    }
    .pt-sm-11 {
      padding-top: 3rem !important;
    }
    .pt-sm-12 {
      padding-top: 3.5rem !important;
    }
    .pt-sm-13 {
      padding-top: 4rem !important;
    }
    .pt-sm-14 {
      padding-top: 4.5rem !important;
    }
    .pt-sm-15 {
      padding-top: 5rem !important;
    }
    .pe-sm-0 {
      padding-right: 0 !important;
    }
    .pe-sm-1 {
      padding-right: 0.125rem !important;
    }
    .pe-sm-2 {
      padding-right: 0.25rem !important;
    }
    .pe-sm-3 {
      padding-right: 0.5rem !important;
    }
    .pe-sm-4 {
      padding-right: 0.75rem !important;
    }
    .pe-sm-5 {
      padding-right: 1rem !important;
    }
    .pe-sm-6 {
      padding-right: 1.25rem !important;
    }
    .pe-sm-7 {
      padding-right: 1.5rem !important;
    }
    .pe-sm-8 {
      padding-right: 1.75rem !important;
    }
    .pe-sm-9 {
      padding-right: 2rem !important;
    }
    .pe-sm-10 {
      padding-right: 2.5rem !important;
    }
    .pe-sm-11 {
      padding-right: 3rem !important;
    }
    .pe-sm-12 {
      padding-right: 3.5rem !important;
    }
    .pe-sm-13 {
      padding-right: 4rem !important;
    }
    .pe-sm-14 {
      padding-right: 4.5rem !important;
    }
    .pe-sm-15 {
      padding-right: 5rem !important;
    }
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
    .pb-sm-1 {
      padding-bottom: 0.125rem !important;
    }
    .pb-sm-2 {
      padding-bottom: 0.25rem !important;
    }
    .pb-sm-3 {
      padding-bottom: 0.5rem !important;
    }
    .pb-sm-4 {
      padding-bottom: 0.75rem !important;
    }
    .pb-sm-5 {
      padding-bottom: 1rem !important;
    }
    .pb-sm-6 {
      padding-bottom: 1.25rem !important;
    }
    .pb-sm-7 {
      padding-bottom: 1.5rem !important;
    }
    .pb-sm-8 {
      padding-bottom: 1.75rem !important;
    }
    .pb-sm-9 {
      padding-bottom: 2rem !important;
    }
    .pb-sm-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-sm-11 {
      padding-bottom: 3rem !important;
    }
    .pb-sm-12 {
      padding-bottom: 3.5rem !important;
    }
    .pb-sm-13 {
      padding-bottom: 4rem !important;
    }
    .pb-sm-14 {
      padding-bottom: 4.5rem !important;
    }
    .pb-sm-15 {
      padding-bottom: 5rem !important;
    }
    .ps-sm-0 {
      padding-left: 0 !important;
    }
    .ps-sm-1 {
      padding-left: 0.125rem !important;
    }
    .ps-sm-2 {
      padding-left: 0.25rem !important;
    }
    .ps-sm-3 {
      padding-left: 0.5rem !important;
    }
    .ps-sm-4 {
      padding-left: 0.75rem !important;
    }
    .ps-sm-5 {
      padding-left: 1rem !important;
    }
    .ps-sm-6 {
      padding-left: 1.25rem !important;
    }
    .ps-sm-7 {
      padding-left: 1.5rem !important;
    }
    .ps-sm-8 {
      padding-left: 1.75rem !important;
    }
    .ps-sm-9 {
      padding-left: 2rem !important;
    }
    .ps-sm-10 {
      padding-left: 2.5rem !important;
    }
    .ps-sm-11 {
      padding-left: 3rem !important;
    }
    .ps-sm-12 {
      padding-left: 3.5rem !important;
    }
    .ps-sm-13 {
      padding-left: 4rem !important;
    }
    .ps-sm-14 {
      padding-left: 4.5rem !important;
    }
    .ps-sm-15 {
      padding-left: 5rem !important;
    }
    .text-sm-start {
      text-align: left !important;
    }
    .text-sm-end {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
    .rounded-top-sm {
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
    }
    .visibility-sm-visible {
      visibility: visible !important;
    }
    .visibility-sm-invisible {
      visibility: hidden !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important;
    }
    .float-md-end {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .flex-md-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-md-0 {
      gap: 0 !important;
    }
    .gap-md-1 {
      gap: 0.125rem !important;
    }
    .gap-md-2 {
      gap: 0.25rem !important;
    }
    .gap-md-3 {
      gap: 0.5rem !important;
    }
    .gap-md-4 {
      gap: 0.75rem !important;
    }
    .gap-md-5 {
      gap: 1rem !important;
    }
    .gap-md-6 {
      gap: 1.25rem !important;
    }
    .gap-md-7 {
      gap: 1.5rem !important;
    }
    .gap-md-8 {
      gap: 1.75rem !important;
    }
    .gap-md-9 {
      gap: 2rem !important;
    }
    .gap-md-10 {
      gap: 2.5rem !important;
    }
    .gap-md-11 {
      gap: 3rem !important;
    }
    .gap-md-12 {
      gap: 3.5rem !important;
    }
    .gap-md-13 {
      gap: 4rem !important;
    }
    .gap-md-14 {
      gap: 4.5rem !important;
    }
    .gap-md-15 {
      gap: 5rem !important;
    }
    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-md-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-md-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-md-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-md-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-md-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-md-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-md-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-md-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-md-0 {
      margin: 0 !important;
    }
    .m-md-1 {
      margin: 0.125rem !important;
    }
    .m-md-2 {
      margin: 0.25rem !important;
    }
    .m-md-3 {
      margin: 0.5rem !important;
    }
    .m-md-4 {
      margin: 0.75rem !important;
    }
    .m-md-5 {
      margin: 1rem !important;
    }
    .m-md-6 {
      margin: 1.25rem !important;
    }
    .m-md-7 {
      margin: 1.5rem !important;
    }
    .m-md-8 {
      margin: 1.75rem !important;
    }
    .m-md-9 {
      margin: 2rem !important;
    }
    .m-md-10 {
      margin: 2.5rem !important;
    }
    .m-md-11 {
      margin: 3rem !important;
    }
    .m-md-12 {
      margin: 3.5rem !important;
    }
    .m-md-13 {
      margin: 4rem !important;
    }
    .m-md-14 {
      margin: 4.5rem !important;
    }
    .m-md-15 {
      margin: 5rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-md-1 {
      margin-right: 0.125rem !important;
      margin-left: 0.125rem !important;
    }
    .mx-md-2 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-md-3 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-md-4 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-md-5 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-md-6 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-md-7 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-md-8 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-md-9 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-md-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-md-11 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-md-12 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-md-13 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-md-14 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-md-15 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-md-1 {
      margin-top: 0.125rem !important;
      margin-bottom: 0.125rem !important;
    }
    .my-md-2 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-md-3 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-md-4 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-md-5 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-md-6 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-md-7 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-md-8 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-md-9 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-md-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-md-11 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-md-12 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-md-13 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-md-14 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-md-15 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-md-0 {
      margin-top: 0 !important;
    }
    .mt-md-1 {
      margin-top: 0.125rem !important;
    }
    .mt-md-2 {
      margin-top: 0.25rem !important;
    }
    .mt-md-3 {
      margin-top: 0.5rem !important;
    }
    .mt-md-4 {
      margin-top: 0.75rem !important;
    }
    .mt-md-5 {
      margin-top: 1rem !important;
    }
    .mt-md-6 {
      margin-top: 1.25rem !important;
    }
    .mt-md-7 {
      margin-top: 1.5rem !important;
    }
    .mt-md-8 {
      margin-top: 1.75rem !important;
    }
    .mt-md-9 {
      margin-top: 2rem !important;
    }
    .mt-md-10 {
      margin-top: 2.5rem !important;
    }
    .mt-md-11 {
      margin-top: 3rem !important;
    }
    .mt-md-12 {
      margin-top: 3.5rem !important;
    }
    .mt-md-13 {
      margin-top: 4rem !important;
    }
    .mt-md-14 {
      margin-top: 4.5rem !important;
    }
    .mt-md-15 {
      margin-top: 5rem !important;
    }
    .mt-md-auto {
      margin-top: auto !important;
    }
    .me-md-0 {
      margin-right: 0 !important;
    }
    .me-md-1 {
      margin-right: 0.125rem !important;
    }
    .me-md-2 {
      margin-right: 0.25rem !important;
    }
    .me-md-3 {
      margin-right: 0.5rem !important;
    }
    .me-md-4 {
      margin-right: 0.75rem !important;
    }
    .me-md-5 {
      margin-right: 1rem !important;
    }
    .me-md-6 {
      margin-right: 1.25rem !important;
    }
    .me-md-7 {
      margin-right: 1.5rem !important;
    }
    .me-md-8 {
      margin-right: 1.75rem !important;
    }
    .me-md-9 {
      margin-right: 2rem !important;
    }
    .me-md-10 {
      margin-right: 2.5rem !important;
    }
    .me-md-11 {
      margin-right: 3rem !important;
    }
    .me-md-12 {
      margin-right: 3.5rem !important;
    }
    .me-md-13 {
      margin-right: 4rem !important;
    }
    .me-md-14 {
      margin-right: 4.5rem !important;
    }
    .me-md-15 {
      margin-right: 5rem !important;
    }
    .me-md-auto {
      margin-right: auto !important;
    }
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
    .mb-md-1 {
      margin-bottom: 0.125rem !important;
    }
    .mb-md-2 {
      margin-bottom: 0.25rem !important;
    }
    .mb-md-3 {
      margin-bottom: 0.5rem !important;
    }
    .mb-md-4 {
      margin-bottom: 0.75rem !important;
    }
    .mb-md-5 {
      margin-bottom: 1rem !important;
    }
    .mb-md-6 {
      margin-bottom: 1.25rem !important;
    }
    .mb-md-7 {
      margin-bottom: 1.5rem !important;
    }
    .mb-md-8 {
      margin-bottom: 1.75rem !important;
    }
    .mb-md-9 {
      margin-bottom: 2rem !important;
    }
    .mb-md-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-md-11 {
      margin-bottom: 3rem !important;
    }
    .mb-md-12 {
      margin-bottom: 3.5rem !important;
    }
    .mb-md-13 {
      margin-bottom: 4rem !important;
    }
    .mb-md-14 {
      margin-bottom: 4.5rem !important;
    }
    .mb-md-15 {
      margin-bottom: 5rem !important;
    }
    .mb-md-auto {
      margin-bottom: auto !important;
    }
    .ms-md-0 {
      margin-left: 0 !important;
    }
    .ms-md-1 {
      margin-left: 0.125rem !important;
    }
    .ms-md-2 {
      margin-left: 0.25rem !important;
    }
    .ms-md-3 {
      margin-left: 0.5rem !important;
    }
    .ms-md-4 {
      margin-left: 0.75rem !important;
    }
    .ms-md-5 {
      margin-left: 1rem !important;
    }
    .ms-md-6 {
      margin-left: 1.25rem !important;
    }
    .ms-md-7 {
      margin-left: 1.5rem !important;
    }
    .ms-md-8 {
      margin-left: 1.75rem !important;
    }
    .ms-md-9 {
      margin-left: 2rem !important;
    }
    .ms-md-10 {
      margin-left: 2.5rem !important;
    }
    .ms-md-11 {
      margin-left: 3rem !important;
    }
    .ms-md-12 {
      margin-left: 3.5rem !important;
    }
    .ms-md-13 {
      margin-left: 4rem !important;
    }
    .ms-md-14 {
      margin-left: 4.5rem !important;
    }
    .ms-md-15 {
      margin-left: 5rem !important;
    }
    .ms-md-auto {
      margin-left: auto !important;
    }
    .m-md-n1 {
      margin: -0.125rem !important;
    }
    .m-md-n2 {
      margin: -0.25rem !important;
    }
    .m-md-n3 {
      margin: -0.5rem !important;
    }
    .m-md-n4 {
      margin: -0.75rem !important;
    }
    .m-md-n5 {
      margin: -1rem !important;
    }
    .m-md-n6 {
      margin: -1.25rem !important;
    }
    .m-md-n7 {
      margin: -1.5rem !important;
    }
    .m-md-n8 {
      margin: -1.75rem !important;
    }
    .m-md-n9 {
      margin: -2rem !important;
    }
    .m-md-n10 {
      margin: -2.5rem !important;
    }
    .m-md-n11 {
      margin: -3rem !important;
    }
    .m-md-n12 {
      margin: -3.5rem !important;
    }
    .m-md-n13 {
      margin: -4rem !important;
    }
    .m-md-n14 {
      margin: -4.5rem !important;
    }
    .m-md-n15 {
      margin: -5rem !important;
    }
    .mx-md-n1 {
      margin-right: -0.125rem !important;
      margin-left: -0.125rem !important;
    }
    .mx-md-n2 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-md-n3 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-md-n4 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-md-n5 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-md-n6 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-md-n7 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-md-n8 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-md-n9 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-md-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-md-n11 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-md-n12 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-md-n13 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-md-n14 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-md-n15 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .my-md-n1 {
      margin-top: -0.125rem !important;
      margin-bottom: -0.125rem !important;
    }
    .my-md-n2 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-md-n3 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-md-n4 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-md-n5 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-md-n6 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-md-n7 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-md-n8 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-md-n9 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-md-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-md-n11 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-md-n12 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-md-n13 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-md-n14 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-md-n15 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .mt-md-n1 {
      margin-top: -0.125rem !important;
    }
    .mt-md-n2 {
      margin-top: -0.25rem !important;
    }
    .mt-md-n3 {
      margin-top: -0.5rem !important;
    }
    .mt-md-n4 {
      margin-top: -0.75rem !important;
    }
    .mt-md-n5 {
      margin-top: -1rem !important;
    }
    .mt-md-n6 {
      margin-top: -1.25rem !important;
    }
    .mt-md-n7 {
      margin-top: -1.5rem !important;
    }
    .mt-md-n8 {
      margin-top: -1.75rem !important;
    }
    .mt-md-n9 {
      margin-top: -2rem !important;
    }
    .mt-md-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-md-n11 {
      margin-top: -3rem !important;
    }
    .mt-md-n12 {
      margin-top: -3.5rem !important;
    }
    .mt-md-n13 {
      margin-top: -4rem !important;
    }
    .mt-md-n14 {
      margin-top: -4.5rem !important;
    }
    .mt-md-n15 {
      margin-top: -5rem !important;
    }
    .me-md-n1 {
      margin-right: -0.125rem !important;
    }
    .me-md-n2 {
      margin-right: -0.25rem !important;
    }
    .me-md-n3 {
      margin-right: -0.5rem !important;
    }
    .me-md-n4 {
      margin-right: -0.75rem !important;
    }
    .me-md-n5 {
      margin-right: -1rem !important;
    }
    .me-md-n6 {
      margin-right: -1.25rem !important;
    }
    .me-md-n7 {
      margin-right: -1.5rem !important;
    }
    .me-md-n8 {
      margin-right: -1.75rem !important;
    }
    .me-md-n9 {
      margin-right: -2rem !important;
    }
    .me-md-n10 {
      margin-right: -2.5rem !important;
    }
    .me-md-n11 {
      margin-right: -3rem !important;
    }
    .me-md-n12 {
      margin-right: -3.5rem !important;
    }
    .me-md-n13 {
      margin-right: -4rem !important;
    }
    .me-md-n14 {
      margin-right: -4.5rem !important;
    }
    .me-md-n15 {
      margin-right: -5rem !important;
    }
    .mb-md-n1 {
      margin-bottom: -0.125rem !important;
    }
    .mb-md-n2 {
      margin-bottom: -0.25rem !important;
    }
    .mb-md-n3 {
      margin-bottom: -0.5rem !important;
    }
    .mb-md-n4 {
      margin-bottom: -0.75rem !important;
    }
    .mb-md-n5 {
      margin-bottom: -1rem !important;
    }
    .mb-md-n6 {
      margin-bottom: -1.25rem !important;
    }
    .mb-md-n7 {
      margin-bottom: -1.5rem !important;
    }
    .mb-md-n8 {
      margin-bottom: -1.75rem !important;
    }
    .mb-md-n9 {
      margin-bottom: -2rem !important;
    }
    .mb-md-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-md-n11 {
      margin-bottom: -3rem !important;
    }
    .mb-md-n12 {
      margin-bottom: -3.5rem !important;
    }
    .mb-md-n13 {
      margin-bottom: -4rem !important;
    }
    .mb-md-n14 {
      margin-bottom: -4.5rem !important;
    }
    .mb-md-n15 {
      margin-bottom: -5rem !important;
    }
    .ms-md-n1 {
      margin-left: -0.125rem !important;
    }
    .ms-md-n2 {
      margin-left: -0.25rem !important;
    }
    .ms-md-n3 {
      margin-left: -0.5rem !important;
    }
    .ms-md-n4 {
      margin-left: -0.75rem !important;
    }
    .ms-md-n5 {
      margin-left: -1rem !important;
    }
    .ms-md-n6 {
      margin-left: -1.25rem !important;
    }
    .ms-md-n7 {
      margin-left: -1.5rem !important;
    }
    .ms-md-n8 {
      margin-left: -1.75rem !important;
    }
    .ms-md-n9 {
      margin-left: -2rem !important;
    }
    .ms-md-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-md-n11 {
      margin-left: -3rem !important;
    }
    .ms-md-n12 {
      margin-left: -3.5rem !important;
    }
    .ms-md-n13 {
      margin-left: -4rem !important;
    }
    .ms-md-n14 {
      margin-left: -4.5rem !important;
    }
    .ms-md-n15 {
      margin-left: -5rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .p-md-1 {
      padding: 0.125rem !important;
    }
    .p-md-2 {
      padding: 0.25rem !important;
    }
    .p-md-3 {
      padding: 0.5rem !important;
    }
    .p-md-4 {
      padding: 0.75rem !important;
    }
    .p-md-5 {
      padding: 1rem !important;
    }
    .p-md-6 {
      padding: 1.25rem !important;
    }
    .p-md-7 {
      padding: 1.5rem !important;
    }
    .p-md-8 {
      padding: 1.75rem !important;
    }
    .p-md-9 {
      padding: 2rem !important;
    }
    .p-md-10 {
      padding: 2.5rem !important;
    }
    .p-md-11 {
      padding: 3rem !important;
    }
    .p-md-12 {
      padding: 3.5rem !important;
    }
    .p-md-13 {
      padding: 4rem !important;
    }
    .p-md-14 {
      padding: 4.5rem !important;
    }
    .p-md-15 {
      padding: 5rem !important;
    }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-md-1 {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important;
    }
    .px-md-2 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-md-3 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-md-4 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-md-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-md-6 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-md-7 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-md-8 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-md-9 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-md-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-md-11 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-md-12 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-md-13 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-md-14 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-md-15 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-md-1 {
      padding-top: 0.125rem !important;
      padding-bottom: 0.125rem !important;
    }
    .py-md-2 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-md-3 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-md-4 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-md-5 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-md-6 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-md-7 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-md-8 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-md-9 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-md-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-md-11 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-md-12 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-md-13 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-md-14 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-md-15 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .pt-md-0 {
      padding-top: 0 !important;
    }
    .pt-md-1 {
      padding-top: 0.125rem !important;
    }
    .pt-md-2 {
      padding-top: 0.25rem !important;
    }
    .pt-md-3 {
      padding-top: 0.5rem !important;
    }
    .pt-md-4 {
      padding-top: 0.75rem !important;
    }
    .pt-md-5 {
      padding-top: 1rem !important;
    }
    .pt-md-6 {
      padding-top: 1.25rem !important;
    }
    .pt-md-7 {
      padding-top: 1.5rem !important;
    }
    .pt-md-8 {
      padding-top: 1.75rem !important;
    }
    .pt-md-9 {
      padding-top: 2rem !important;
    }
    .pt-md-10 {
      padding-top: 2.5rem !important;
    }
    .pt-md-11 {
      padding-top: 3rem !important;
    }
    .pt-md-12 {
      padding-top: 3.5rem !important;
    }
    .pt-md-13 {
      padding-top: 4rem !important;
    }
    .pt-md-14 {
      padding-top: 4.5rem !important;
    }
    .pt-md-15 {
      padding-top: 5rem !important;
    }
    .pe-md-0 {
      padding-right: 0 !important;
    }
    .pe-md-1 {
      padding-right: 0.125rem !important;
    }
    .pe-md-2 {
      padding-right: 0.25rem !important;
    }
    .pe-md-3 {
      padding-right: 0.5rem !important;
    }
    .pe-md-4 {
      padding-right: 0.75rem !important;
    }
    .pe-md-5 {
      padding-right: 1rem !important;
    }
    .pe-md-6 {
      padding-right: 1.25rem !important;
    }
    .pe-md-7 {
      padding-right: 1.5rem !important;
    }
    .pe-md-8 {
      padding-right: 1.75rem !important;
    }
    .pe-md-9 {
      padding-right: 2rem !important;
    }
    .pe-md-10 {
      padding-right: 2.5rem !important;
    }
    .pe-md-11 {
      padding-right: 3rem !important;
    }
    .pe-md-12 {
      padding-right: 3.5rem !important;
    }
    .pe-md-13 {
      padding-right: 4rem !important;
    }
    .pe-md-14 {
      padding-right: 4.5rem !important;
    }
    .pe-md-15 {
      padding-right: 5rem !important;
    }
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
    .pb-md-1 {
      padding-bottom: 0.125rem !important;
    }
    .pb-md-2 {
      padding-bottom: 0.25rem !important;
    }
    .pb-md-3 {
      padding-bottom: 0.5rem !important;
    }
    .pb-md-4 {
      padding-bottom: 0.75rem !important;
    }
    .pb-md-5 {
      padding-bottom: 1rem !important;
    }
    .pb-md-6 {
      padding-bottom: 1.25rem !important;
    }
    .pb-md-7 {
      padding-bottom: 1.5rem !important;
    }
    .pb-md-8 {
      padding-bottom: 1.75rem !important;
    }
    .pb-md-9 {
      padding-bottom: 2rem !important;
    }
    .pb-md-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-md-11 {
      padding-bottom: 3rem !important;
    }
    .pb-md-12 {
      padding-bottom: 3.5rem !important;
    }
    .pb-md-13 {
      padding-bottom: 4rem !important;
    }
    .pb-md-14 {
      padding-bottom: 4.5rem !important;
    }
    .pb-md-15 {
      padding-bottom: 5rem !important;
    }
    .ps-md-0 {
      padding-left: 0 !important;
    }
    .ps-md-1 {
      padding-left: 0.125rem !important;
    }
    .ps-md-2 {
      padding-left: 0.25rem !important;
    }
    .ps-md-3 {
      padding-left: 0.5rem !important;
    }
    .ps-md-4 {
      padding-left: 0.75rem !important;
    }
    .ps-md-5 {
      padding-left: 1rem !important;
    }
    .ps-md-6 {
      padding-left: 1.25rem !important;
    }
    .ps-md-7 {
      padding-left: 1.5rem !important;
    }
    .ps-md-8 {
      padding-left: 1.75rem !important;
    }
    .ps-md-9 {
      padding-left: 2rem !important;
    }
    .ps-md-10 {
      padding-left: 2.5rem !important;
    }
    .ps-md-11 {
      padding-left: 3rem !important;
    }
    .ps-md-12 {
      padding-left: 3.5rem !important;
    }
    .ps-md-13 {
      padding-left: 4rem !important;
    }
    .ps-md-14 {
      padding-left: 4.5rem !important;
    }
    .ps-md-15 {
      padding-left: 5rem !important;
    }
    .text-md-start {
      text-align: left !important;
    }
    .text-md-end {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
    .rounded-top-md {
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
    }
    .visibility-md-visible {
      visibility: visible !important;
    }
    .visibility-md-invisible {
      visibility: hidden !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important;
    }
    .float-lg-end {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-lg-0 {
      gap: 0 !important;
    }
    .gap-lg-1 {
      gap: 0.125rem !important;
    }
    .gap-lg-2 {
      gap: 0.25rem !important;
    }
    .gap-lg-3 {
      gap: 0.5rem !important;
    }
    .gap-lg-4 {
      gap: 0.75rem !important;
    }
    .gap-lg-5 {
      gap: 1rem !important;
    }
    .gap-lg-6 {
      gap: 1.25rem !important;
    }
    .gap-lg-7 {
      gap: 1.5rem !important;
    }
    .gap-lg-8 {
      gap: 1.75rem !important;
    }
    .gap-lg-9 {
      gap: 2rem !important;
    }
    .gap-lg-10 {
      gap: 2.5rem !important;
    }
    .gap-lg-11 {
      gap: 3rem !important;
    }
    .gap-lg-12 {
      gap: 3.5rem !important;
    }
    .gap-lg-13 {
      gap: 4rem !important;
    }
    .gap-lg-14 {
      gap: 4.5rem !important;
    }
    .gap-lg-15 {
      gap: 5rem !important;
    }
    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-lg-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-lg-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-lg-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-lg-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-lg-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-lg-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-lg-0 {
      margin: 0 !important;
    }
    .m-lg-1 {
      margin: 0.125rem !important;
    }
    .m-lg-2 {
      margin: 0.25rem !important;
    }
    .m-lg-3 {
      margin: 0.5rem !important;
    }
    .m-lg-4 {
      margin: 0.75rem !important;
    }
    .m-lg-5 {
      margin: 1rem !important;
    }
    .m-lg-6 {
      margin: 1.25rem !important;
    }
    .m-lg-7 {
      margin: 1.5rem !important;
    }
    .m-lg-8 {
      margin: 1.75rem !important;
    }
    .m-lg-9 {
      margin: 2rem !important;
    }
    .m-lg-10 {
      margin: 2.5rem !important;
    }
    .m-lg-11 {
      margin: 3rem !important;
    }
    .m-lg-12 {
      margin: 3.5rem !important;
    }
    .m-lg-13 {
      margin: 4rem !important;
    }
    .m-lg-14 {
      margin: 4.5rem !important;
    }
    .m-lg-15 {
      margin: 5rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-lg-1 {
      margin-right: 0.125rem !important;
      margin-left: 0.125rem !important;
    }
    .mx-lg-2 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-lg-3 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-lg-4 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-lg-5 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-lg-6 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-lg-7 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-lg-8 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-lg-9 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-lg-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-lg-11 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-lg-12 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-lg-13 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-lg-14 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-lg-15 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-lg-1 {
      margin-top: 0.125rem !important;
      margin-bottom: 0.125rem !important;
    }
    .my-lg-2 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-lg-3 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-lg-4 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-lg-5 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-lg-6 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-lg-7 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-lg-8 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-lg-9 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-lg-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-lg-11 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-lg-12 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-lg-13 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-lg-14 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-lg-15 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-lg-0 {
      margin-top: 0 !important;
    }
    .mt-lg-1 {
      margin-top: 0.125rem !important;
    }
    .mt-lg-2 {
      margin-top: 0.25rem !important;
    }
    .mt-lg-3 {
      margin-top: 0.5rem !important;
    }
    .mt-lg-4 {
      margin-top: 0.75rem !important;
    }
    .mt-lg-5 {
      margin-top: 1rem !important;
    }
    .mt-lg-6 {
      margin-top: 1.25rem !important;
    }
    .mt-lg-7 {
      margin-top: 1.5rem !important;
    }
    .mt-lg-8 {
      margin-top: 1.75rem !important;
    }
    .mt-lg-9 {
      margin-top: 2rem !important;
    }
    .mt-lg-10 {
      margin-top: 2.5rem !important;
    }
    .mt-lg-11 {
      margin-top: 3rem !important;
    }
    .mt-lg-12 {
      margin-top: 3.5rem !important;
    }
    .mt-lg-13 {
      margin-top: 4rem !important;
    }
    .mt-lg-14 {
      margin-top: 4.5rem !important;
    }
    .mt-lg-15 {
      margin-top: 5rem !important;
    }
    .mt-lg-auto {
      margin-top: auto !important;
    }
    .me-lg-0 {
      margin-right: 0 !important;
    }
    .me-lg-1 {
      margin-right: 0.125rem !important;
    }
    .me-lg-2 {
      margin-right: 0.25rem !important;
    }
    .me-lg-3 {
      margin-right: 0.5rem !important;
    }
    .me-lg-4 {
      margin-right: 0.75rem !important;
    }
    .me-lg-5 {
      margin-right: 1rem !important;
    }
    .me-lg-6 {
      margin-right: 1.25rem !important;
    }
    .me-lg-7 {
      margin-right: 1.5rem !important;
    }
    .me-lg-8 {
      margin-right: 1.75rem !important;
    }
    .me-lg-9 {
      margin-right: 2rem !important;
    }
    .me-lg-10 {
      margin-right: 2.5rem !important;
    }
    .me-lg-11 {
      margin-right: 3rem !important;
    }
    .me-lg-12 {
      margin-right: 3.5rem !important;
    }
    .me-lg-13 {
      margin-right: 4rem !important;
    }
    .me-lg-14 {
      margin-right: 4.5rem !important;
    }
    .me-lg-15 {
      margin-right: 5rem !important;
    }
    .me-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
    .mb-lg-1 {
      margin-bottom: 0.125rem !important;
    }
    .mb-lg-2 {
      margin-bottom: 0.25rem !important;
    }
    .mb-lg-3 {
      margin-bottom: 0.5rem !important;
    }
    .mb-lg-4 {
      margin-bottom: 0.75rem !important;
    }
    .mb-lg-5 {
      margin-bottom: 1rem !important;
    }
    .mb-lg-6 {
      margin-bottom: 1.25rem !important;
    }
    .mb-lg-7 {
      margin-bottom: 1.5rem !important;
    }
    .mb-lg-8 {
      margin-bottom: 1.75rem !important;
    }
    .mb-lg-9 {
      margin-bottom: 2rem !important;
    }
    .mb-lg-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-lg-11 {
      margin-bottom: 3rem !important;
    }
    .mb-lg-12 {
      margin-bottom: 3.5rem !important;
    }
    .mb-lg-13 {
      margin-bottom: 4rem !important;
    }
    .mb-lg-14 {
      margin-bottom: 4.5rem !important;
    }
    .mb-lg-15 {
      margin-bottom: 5rem !important;
    }
    .mb-lg-auto {
      margin-bottom: auto !important;
    }
    .ms-lg-0 {
      margin-left: 0 !important;
    }
    .ms-lg-1 {
      margin-left: 0.125rem !important;
    }
    .ms-lg-2 {
      margin-left: 0.25rem !important;
    }
    .ms-lg-3 {
      margin-left: 0.5rem !important;
    }
    .ms-lg-4 {
      margin-left: 0.75rem !important;
    }
    .ms-lg-5 {
      margin-left: 1rem !important;
    }
    .ms-lg-6 {
      margin-left: 1.25rem !important;
    }
    .ms-lg-7 {
      margin-left: 1.5rem !important;
    }
    .ms-lg-8 {
      margin-left: 1.75rem !important;
    }
    .ms-lg-9 {
      margin-left: 2rem !important;
    }
    .ms-lg-10 {
      margin-left: 2.5rem !important;
    }
    .ms-lg-11 {
      margin-left: 3rem !important;
    }
    .ms-lg-12 {
      margin-left: 3.5rem !important;
    }
    .ms-lg-13 {
      margin-left: 4rem !important;
    }
    .ms-lg-14 {
      margin-left: 4.5rem !important;
    }
    .ms-lg-15 {
      margin-left: 5rem !important;
    }
    .ms-lg-auto {
      margin-left: auto !important;
    }
    .m-lg-n1 {
      margin: -0.125rem !important;
    }
    .m-lg-n2 {
      margin: -0.25rem !important;
    }
    .m-lg-n3 {
      margin: -0.5rem !important;
    }
    .m-lg-n4 {
      margin: -0.75rem !important;
    }
    .m-lg-n5 {
      margin: -1rem !important;
    }
    .m-lg-n6 {
      margin: -1.25rem !important;
    }
    .m-lg-n7 {
      margin: -1.5rem !important;
    }
    .m-lg-n8 {
      margin: -1.75rem !important;
    }
    .m-lg-n9 {
      margin: -2rem !important;
    }
    .m-lg-n10 {
      margin: -2.5rem !important;
    }
    .m-lg-n11 {
      margin: -3rem !important;
    }
    .m-lg-n12 {
      margin: -3.5rem !important;
    }
    .m-lg-n13 {
      margin: -4rem !important;
    }
    .m-lg-n14 {
      margin: -4.5rem !important;
    }
    .m-lg-n15 {
      margin: -5rem !important;
    }
    .mx-lg-n1 {
      margin-right: -0.125rem !important;
      margin-left: -0.125rem !important;
    }
    .mx-lg-n2 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-lg-n3 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-lg-n4 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-lg-n5 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-lg-n6 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-lg-n7 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-lg-n8 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-lg-n9 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-lg-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-lg-n11 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-lg-n12 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-lg-n13 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-lg-n14 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-lg-n15 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .my-lg-n1 {
      margin-top: -0.125rem !important;
      margin-bottom: -0.125rem !important;
    }
    .my-lg-n2 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-lg-n3 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-lg-n4 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-lg-n5 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-lg-n6 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-lg-n7 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-lg-n8 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-lg-n9 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-lg-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-lg-n11 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-lg-n12 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-lg-n13 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-lg-n14 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-lg-n15 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .mt-lg-n1 {
      margin-top: -0.125rem !important;
    }
    .mt-lg-n2 {
      margin-top: -0.25rem !important;
    }
    .mt-lg-n3 {
      margin-top: -0.5rem !important;
    }
    .mt-lg-n4 {
      margin-top: -0.75rem !important;
    }
    .mt-lg-n5 {
      margin-top: -1rem !important;
    }
    .mt-lg-n6 {
      margin-top: -1.25rem !important;
    }
    .mt-lg-n7 {
      margin-top: -1.5rem !important;
    }
    .mt-lg-n8 {
      margin-top: -1.75rem !important;
    }
    .mt-lg-n9 {
      margin-top: -2rem !important;
    }
    .mt-lg-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-lg-n11 {
      margin-top: -3rem !important;
    }
    .mt-lg-n12 {
      margin-top: -3.5rem !important;
    }
    .mt-lg-n13 {
      margin-top: -4rem !important;
    }
    .mt-lg-n14 {
      margin-top: -4.5rem !important;
    }
    .mt-lg-n15 {
      margin-top: -5rem !important;
    }
    .me-lg-n1 {
      margin-right: -0.125rem !important;
    }
    .me-lg-n2 {
      margin-right: -0.25rem !important;
    }
    .me-lg-n3 {
      margin-right: -0.5rem !important;
    }
    .me-lg-n4 {
      margin-right: -0.75rem !important;
    }
    .me-lg-n5 {
      margin-right: -1rem !important;
    }
    .me-lg-n6 {
      margin-right: -1.25rem !important;
    }
    .me-lg-n7 {
      margin-right: -1.5rem !important;
    }
    .me-lg-n8 {
      margin-right: -1.75rem !important;
    }
    .me-lg-n9 {
      margin-right: -2rem !important;
    }
    .me-lg-n10 {
      margin-right: -2.5rem !important;
    }
    .me-lg-n11 {
      margin-right: -3rem !important;
    }
    .me-lg-n12 {
      margin-right: -3.5rem !important;
    }
    .me-lg-n13 {
      margin-right: -4rem !important;
    }
    .me-lg-n14 {
      margin-right: -4.5rem !important;
    }
    .me-lg-n15 {
      margin-right: -5rem !important;
    }
    .mb-lg-n1 {
      margin-bottom: -0.125rem !important;
    }
    .mb-lg-n2 {
      margin-bottom: -0.25rem !important;
    }
    .mb-lg-n3 {
      margin-bottom: -0.5rem !important;
    }
    .mb-lg-n4 {
      margin-bottom: -0.75rem !important;
    }
    .mb-lg-n5 {
      margin-bottom: -1rem !important;
    }
    .mb-lg-n6 {
      margin-bottom: -1.25rem !important;
    }
    .mb-lg-n7 {
      margin-bottom: -1.5rem !important;
    }
    .mb-lg-n8 {
      margin-bottom: -1.75rem !important;
    }
    .mb-lg-n9 {
      margin-bottom: -2rem !important;
    }
    .mb-lg-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-lg-n11 {
      margin-bottom: -3rem !important;
    }
    .mb-lg-n12 {
      margin-bottom: -3.5rem !important;
    }
    .mb-lg-n13 {
      margin-bottom: -4rem !important;
    }
    .mb-lg-n14 {
      margin-bottom: -4.5rem !important;
    }
    .mb-lg-n15 {
      margin-bottom: -5rem !important;
    }
    .ms-lg-n1 {
      margin-left: -0.125rem !important;
    }
    .ms-lg-n2 {
      margin-left: -0.25rem !important;
    }
    .ms-lg-n3 {
      margin-left: -0.5rem !important;
    }
    .ms-lg-n4 {
      margin-left: -0.75rem !important;
    }
    .ms-lg-n5 {
      margin-left: -1rem !important;
    }
    .ms-lg-n6 {
      margin-left: -1.25rem !important;
    }
    .ms-lg-n7 {
      margin-left: -1.5rem !important;
    }
    .ms-lg-n8 {
      margin-left: -1.75rem !important;
    }
    .ms-lg-n9 {
      margin-left: -2rem !important;
    }
    .ms-lg-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-lg-n11 {
      margin-left: -3rem !important;
    }
    .ms-lg-n12 {
      margin-left: -3.5rem !important;
    }
    .ms-lg-n13 {
      margin-left: -4rem !important;
    }
    .ms-lg-n14 {
      margin-left: -4.5rem !important;
    }
    .ms-lg-n15 {
      margin-left: -5rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .p-lg-1 {
      padding: 0.125rem !important;
    }
    .p-lg-2 {
      padding: 0.25rem !important;
    }
    .p-lg-3 {
      padding: 0.5rem !important;
    }
    .p-lg-4 {
      padding: 0.75rem !important;
    }
    .p-lg-5 {
      padding: 1rem !important;
    }
    .p-lg-6 {
      padding: 1.25rem !important;
    }
    .p-lg-7 {
      padding: 1.5rem !important;
    }
    .p-lg-8 {
      padding: 1.75rem !important;
    }
    .p-lg-9 {
      padding: 2rem !important;
    }
    .p-lg-10 {
      padding: 2.5rem !important;
    }
    .p-lg-11 {
      padding: 3rem !important;
    }
    .p-lg-12 {
      padding: 3.5rem !important;
    }
    .p-lg-13 {
      padding: 4rem !important;
    }
    .p-lg-14 {
      padding: 4.5rem !important;
    }
    .p-lg-15 {
      padding: 5rem !important;
    }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-lg-1 {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important;
    }
    .px-lg-2 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-lg-3 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-lg-4 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-lg-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-lg-6 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-lg-7 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-lg-8 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-lg-9 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-lg-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-lg-11 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-lg-12 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-lg-13 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-lg-14 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-lg-15 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-lg-1 {
      padding-top: 0.125rem !important;
      padding-bottom: 0.125rem !important;
    }
    .py-lg-2 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-lg-3 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-lg-4 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-lg-5 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-lg-6 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-lg-7 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-lg-8 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-lg-9 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-lg-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-lg-11 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-lg-12 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-lg-13 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-lg-14 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-lg-15 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .pt-lg-0 {
      padding-top: 0 !important;
    }
    .pt-lg-1 {
      padding-top: 0.125rem !important;
    }
    .pt-lg-2 {
      padding-top: 0.25rem !important;
    }
    .pt-lg-3 {
      padding-top: 0.5rem !important;
    }
    .pt-lg-4 {
      padding-top: 0.75rem !important;
    }
    .pt-lg-5 {
      padding-top: 1rem !important;
    }
    .pt-lg-6 {
      padding-top: 1.25rem !important;
    }
    .pt-lg-7 {
      padding-top: 1.5rem !important;
    }
    .pt-lg-8 {
      padding-top: 1.75rem !important;
    }
    .pt-lg-9 {
      padding-top: 2rem !important;
    }
    .pt-lg-10 {
      padding-top: 2.5rem !important;
    }
    .pt-lg-11 {
      padding-top: 3rem !important;
    }
    .pt-lg-12 {
      padding-top: 3.5rem !important;
    }
    .pt-lg-13 {
      padding-top: 4rem !important;
    }
    .pt-lg-14 {
      padding-top: 4.5rem !important;
    }
    .pt-lg-15 {
      padding-top: 5rem !important;
    }
    .pe-lg-0 {
      padding-right: 0 !important;
    }
    .pe-lg-1 {
      padding-right: 0.125rem !important;
    }
    .pe-lg-2 {
      padding-right: 0.25rem !important;
    }
    .pe-lg-3 {
      padding-right: 0.5rem !important;
    }
    .pe-lg-4 {
      padding-right: 0.75rem !important;
    }
    .pe-lg-5 {
      padding-right: 1rem !important;
    }
    .pe-lg-6 {
      padding-right: 1.25rem !important;
    }
    .pe-lg-7 {
      padding-right: 1.5rem !important;
    }
    .pe-lg-8 {
      padding-right: 1.75rem !important;
    }
    .pe-lg-9 {
      padding-right: 2rem !important;
    }
    .pe-lg-10 {
      padding-right: 2.5rem !important;
    }
    .pe-lg-11 {
      padding-right: 3rem !important;
    }
    .pe-lg-12 {
      padding-right: 3.5rem !important;
    }
    .pe-lg-13 {
      padding-right: 4rem !important;
    }
    .pe-lg-14 {
      padding-right: 4.5rem !important;
    }
    .pe-lg-15 {
      padding-right: 5rem !important;
    }
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
    .pb-lg-1 {
      padding-bottom: 0.125rem !important;
    }
    .pb-lg-2 {
      padding-bottom: 0.25rem !important;
    }
    .pb-lg-3 {
      padding-bottom: 0.5rem !important;
    }
    .pb-lg-4 {
      padding-bottom: 0.75rem !important;
    }
    .pb-lg-5 {
      padding-bottom: 1rem !important;
    }
    .pb-lg-6 {
      padding-bottom: 1.25rem !important;
    }
    .pb-lg-7 {
      padding-bottom: 1.5rem !important;
    }
    .pb-lg-8 {
      padding-bottom: 1.75rem !important;
    }
    .pb-lg-9 {
      padding-bottom: 2rem !important;
    }
    .pb-lg-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-lg-11 {
      padding-bottom: 3rem !important;
    }
    .pb-lg-12 {
      padding-bottom: 3.5rem !important;
    }
    .pb-lg-13 {
      padding-bottom: 4rem !important;
    }
    .pb-lg-14 {
      padding-bottom: 4.5rem !important;
    }
    .pb-lg-15 {
      padding-bottom: 5rem !important;
    }
    .ps-lg-0 {
      padding-left: 0 !important;
    }
    .ps-lg-1 {
      padding-left: 0.125rem !important;
    }
    .ps-lg-2 {
      padding-left: 0.25rem !important;
    }
    .ps-lg-3 {
      padding-left: 0.5rem !important;
    }
    .ps-lg-4 {
      padding-left: 0.75rem !important;
    }
    .ps-lg-5 {
      padding-left: 1rem !important;
    }
    .ps-lg-6 {
      padding-left: 1.25rem !important;
    }
    .ps-lg-7 {
      padding-left: 1.5rem !important;
    }
    .ps-lg-8 {
      padding-left: 1.75rem !important;
    }
    .ps-lg-9 {
      padding-left: 2rem !important;
    }
    .ps-lg-10 {
      padding-left: 2.5rem !important;
    }
    .ps-lg-11 {
      padding-left: 3rem !important;
    }
    .ps-lg-12 {
      padding-left: 3.5rem !important;
    }
    .ps-lg-13 {
      padding-left: 4rem !important;
    }
    .ps-lg-14 {
      padding-left: 4.5rem !important;
    }
    .ps-lg-15 {
      padding-left: 5rem !important;
    }
    .text-lg-start {
      text-align: left !important;
    }
    .text-lg-end {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
    .rounded-top-lg {
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
    }
    .visibility-lg-visible {
      visibility: visible !important;
    }
    .visibility-lg-invisible {
      visibility: hidden !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important;
    }
    .float-xl-end {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-xl-0 {
      gap: 0 !important;
    }
    .gap-xl-1 {
      gap: 0.125rem !important;
    }
    .gap-xl-2 {
      gap: 0.25rem !important;
    }
    .gap-xl-3 {
      gap: 0.5rem !important;
    }
    .gap-xl-4 {
      gap: 0.75rem !important;
    }
    .gap-xl-5 {
      gap: 1rem !important;
    }
    .gap-xl-6 {
      gap: 1.25rem !important;
    }
    .gap-xl-7 {
      gap: 1.5rem !important;
    }
    .gap-xl-8 {
      gap: 1.75rem !important;
    }
    .gap-xl-9 {
      gap: 2rem !important;
    }
    .gap-xl-10 {
      gap: 2.5rem !important;
    }
    .gap-xl-11 {
      gap: 3rem !important;
    }
    .gap-xl-12 {
      gap: 3.5rem !important;
    }
    .gap-xl-13 {
      gap: 4rem !important;
    }
    .gap-xl-14 {
      gap: 4.5rem !important;
    }
    .gap-xl-15 {
      gap: 5rem !important;
    }
    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-xl-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-xl-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-xl-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-xl-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-xl-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-xl-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-xl-0 {
      margin: 0 !important;
    }
    .m-xl-1 {
      margin: 0.125rem !important;
    }
    .m-xl-2 {
      margin: 0.25rem !important;
    }
    .m-xl-3 {
      margin: 0.5rem !important;
    }
    .m-xl-4 {
      margin: 0.75rem !important;
    }
    .m-xl-5 {
      margin: 1rem !important;
    }
    .m-xl-6 {
      margin: 1.25rem !important;
    }
    .m-xl-7 {
      margin: 1.5rem !important;
    }
    .m-xl-8 {
      margin: 1.75rem !important;
    }
    .m-xl-9 {
      margin: 2rem !important;
    }
    .m-xl-10 {
      margin: 2.5rem !important;
    }
    .m-xl-11 {
      margin: 3rem !important;
    }
    .m-xl-12 {
      margin: 3.5rem !important;
    }
    .m-xl-13 {
      margin: 4rem !important;
    }
    .m-xl-14 {
      margin: 4.5rem !important;
    }
    .m-xl-15 {
      margin: 5rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xl-1 {
      margin-right: 0.125rem !important;
      margin-left: 0.125rem !important;
    }
    .mx-xl-2 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xl-3 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xl-4 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-xl-5 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xl-6 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-xl-7 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xl-8 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-xl-9 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-xl-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-xl-11 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xl-12 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-xl-13 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xl-14 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-xl-15 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xl-1 {
      margin-top: 0.125rem !important;
      margin-bottom: 0.125rem !important;
    }
    .my-xl-2 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xl-3 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xl-4 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-xl-5 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xl-6 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-xl-7 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xl-8 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-xl-9 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-xl-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-xl-11 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xl-12 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-xl-13 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xl-14 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-xl-15 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xl-0 {
      margin-top: 0 !important;
    }
    .mt-xl-1 {
      margin-top: 0.125rem !important;
    }
    .mt-xl-2 {
      margin-top: 0.25rem !important;
    }
    .mt-xl-3 {
      margin-top: 0.5rem !important;
    }
    .mt-xl-4 {
      margin-top: 0.75rem !important;
    }
    .mt-xl-5 {
      margin-top: 1rem !important;
    }
    .mt-xl-6 {
      margin-top: 1.25rem !important;
    }
    .mt-xl-7 {
      margin-top: 1.5rem !important;
    }
    .mt-xl-8 {
      margin-top: 1.75rem !important;
    }
    .mt-xl-9 {
      margin-top: 2rem !important;
    }
    .mt-xl-10 {
      margin-top: 2.5rem !important;
    }
    .mt-xl-11 {
      margin-top: 3rem !important;
    }
    .mt-xl-12 {
      margin-top: 3.5rem !important;
    }
    .mt-xl-13 {
      margin-top: 4rem !important;
    }
    .mt-xl-14 {
      margin-top: 4.5rem !important;
    }
    .mt-xl-15 {
      margin-top: 5rem !important;
    }
    .mt-xl-auto {
      margin-top: auto !important;
    }
    .me-xl-0 {
      margin-right: 0 !important;
    }
    .me-xl-1 {
      margin-right: 0.125rem !important;
    }
    .me-xl-2 {
      margin-right: 0.25rem !important;
    }
    .me-xl-3 {
      margin-right: 0.5rem !important;
    }
    .me-xl-4 {
      margin-right: 0.75rem !important;
    }
    .me-xl-5 {
      margin-right: 1rem !important;
    }
    .me-xl-6 {
      margin-right: 1.25rem !important;
    }
    .me-xl-7 {
      margin-right: 1.5rem !important;
    }
    .me-xl-8 {
      margin-right: 1.75rem !important;
    }
    .me-xl-9 {
      margin-right: 2rem !important;
    }
    .me-xl-10 {
      margin-right: 2.5rem !important;
    }
    .me-xl-11 {
      margin-right: 3rem !important;
    }
    .me-xl-12 {
      margin-right: 3.5rem !important;
    }
    .me-xl-13 {
      margin-right: 4rem !important;
    }
    .me-xl-14 {
      margin-right: 4.5rem !important;
    }
    .me-xl-15 {
      margin-right: 5rem !important;
    }
    .me-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xl-1 {
      margin-bottom: 0.125rem !important;
    }
    .mb-xl-2 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xl-3 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xl-4 {
      margin-bottom: 0.75rem !important;
    }
    .mb-xl-5 {
      margin-bottom: 1rem !important;
    }
    .mb-xl-6 {
      margin-bottom: 1.25rem !important;
    }
    .mb-xl-7 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xl-8 {
      margin-bottom: 1.75rem !important;
    }
    .mb-xl-9 {
      margin-bottom: 2rem !important;
    }
    .mb-xl-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-xl-11 {
      margin-bottom: 3rem !important;
    }
    .mb-xl-12 {
      margin-bottom: 3.5rem !important;
    }
    .mb-xl-13 {
      margin-bottom: 4rem !important;
    }
    .mb-xl-14 {
      margin-bottom: 4.5rem !important;
    }
    .mb-xl-15 {
      margin-bottom: 5rem !important;
    }
    .mb-xl-auto {
      margin-bottom: auto !important;
    }
    .ms-xl-0 {
      margin-left: 0 !important;
    }
    .ms-xl-1 {
      margin-left: 0.125rem !important;
    }
    .ms-xl-2 {
      margin-left: 0.25rem !important;
    }
    .ms-xl-3 {
      margin-left: 0.5rem !important;
    }
    .ms-xl-4 {
      margin-left: 0.75rem !important;
    }
    .ms-xl-5 {
      margin-left: 1rem !important;
    }
    .ms-xl-6 {
      margin-left: 1.25rem !important;
    }
    .ms-xl-7 {
      margin-left: 1.5rem !important;
    }
    .ms-xl-8 {
      margin-left: 1.75rem !important;
    }
    .ms-xl-9 {
      margin-left: 2rem !important;
    }
    .ms-xl-10 {
      margin-left: 2.5rem !important;
    }
    .ms-xl-11 {
      margin-left: 3rem !important;
    }
    .ms-xl-12 {
      margin-left: 3.5rem !important;
    }
    .ms-xl-13 {
      margin-left: 4rem !important;
    }
    .ms-xl-14 {
      margin-left: 4.5rem !important;
    }
    .ms-xl-15 {
      margin-left: 5rem !important;
    }
    .ms-xl-auto {
      margin-left: auto !important;
    }
    .m-xl-n1 {
      margin: -0.125rem !important;
    }
    .m-xl-n2 {
      margin: -0.25rem !important;
    }
    .m-xl-n3 {
      margin: -0.5rem !important;
    }
    .m-xl-n4 {
      margin: -0.75rem !important;
    }
    .m-xl-n5 {
      margin: -1rem !important;
    }
    .m-xl-n6 {
      margin: -1.25rem !important;
    }
    .m-xl-n7 {
      margin: -1.5rem !important;
    }
    .m-xl-n8 {
      margin: -1.75rem !important;
    }
    .m-xl-n9 {
      margin: -2rem !important;
    }
    .m-xl-n10 {
      margin: -2.5rem !important;
    }
    .m-xl-n11 {
      margin: -3rem !important;
    }
    .m-xl-n12 {
      margin: -3.5rem !important;
    }
    .m-xl-n13 {
      margin: -4rem !important;
    }
    .m-xl-n14 {
      margin: -4.5rem !important;
    }
    .m-xl-n15 {
      margin: -5rem !important;
    }
    .mx-xl-n1 {
      margin-right: -0.125rem !important;
      margin-left: -0.125rem !important;
    }
    .mx-xl-n2 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xl-n3 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xl-n4 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-xl-n5 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xl-n6 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-xl-n7 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-xl-n8 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-xl-n9 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-xl-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-xl-n11 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xl-n12 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-xl-n13 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xl-n14 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-xl-n15 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .my-xl-n1 {
      margin-top: -0.125rem !important;
      margin-bottom: -0.125rem !important;
    }
    .my-xl-n2 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xl-n3 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xl-n4 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-xl-n5 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xl-n6 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-xl-n7 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-xl-n8 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-xl-n9 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-xl-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-xl-n11 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xl-n12 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-xl-n13 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xl-n14 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-xl-n15 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .mt-xl-n1 {
      margin-top: -0.125rem !important;
    }
    .mt-xl-n2 {
      margin-top: -0.25rem !important;
    }
    .mt-xl-n3 {
      margin-top: -0.5rem !important;
    }
    .mt-xl-n4 {
      margin-top: -0.75rem !important;
    }
    .mt-xl-n5 {
      margin-top: -1rem !important;
    }
    .mt-xl-n6 {
      margin-top: -1.25rem !important;
    }
    .mt-xl-n7 {
      margin-top: -1.5rem !important;
    }
    .mt-xl-n8 {
      margin-top: -1.75rem !important;
    }
    .mt-xl-n9 {
      margin-top: -2rem !important;
    }
    .mt-xl-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-xl-n11 {
      margin-top: -3rem !important;
    }
    .mt-xl-n12 {
      margin-top: -3.5rem !important;
    }
    .mt-xl-n13 {
      margin-top: -4rem !important;
    }
    .mt-xl-n14 {
      margin-top: -4.5rem !important;
    }
    .mt-xl-n15 {
      margin-top: -5rem !important;
    }
    .me-xl-n1 {
      margin-right: -0.125rem !important;
    }
    .me-xl-n2 {
      margin-right: -0.25rem !important;
    }
    .me-xl-n3 {
      margin-right: -0.5rem !important;
    }
    .me-xl-n4 {
      margin-right: -0.75rem !important;
    }
    .me-xl-n5 {
      margin-right: -1rem !important;
    }
    .me-xl-n6 {
      margin-right: -1.25rem !important;
    }
    .me-xl-n7 {
      margin-right: -1.5rem !important;
    }
    .me-xl-n8 {
      margin-right: -1.75rem !important;
    }
    .me-xl-n9 {
      margin-right: -2rem !important;
    }
    .me-xl-n10 {
      margin-right: -2.5rem !important;
    }
    .me-xl-n11 {
      margin-right: -3rem !important;
    }
    .me-xl-n12 {
      margin-right: -3.5rem !important;
    }
    .me-xl-n13 {
      margin-right: -4rem !important;
    }
    .me-xl-n14 {
      margin-right: -4.5rem !important;
    }
    .me-xl-n15 {
      margin-right: -5rem !important;
    }
    .mb-xl-n1 {
      margin-bottom: -0.125rem !important;
    }
    .mb-xl-n2 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xl-n3 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xl-n4 {
      margin-bottom: -0.75rem !important;
    }
    .mb-xl-n5 {
      margin-bottom: -1rem !important;
    }
    .mb-xl-n6 {
      margin-bottom: -1.25rem !important;
    }
    .mb-xl-n7 {
      margin-bottom: -1.5rem !important;
    }
    .mb-xl-n8 {
      margin-bottom: -1.75rem !important;
    }
    .mb-xl-n9 {
      margin-bottom: -2rem !important;
    }
    .mb-xl-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-xl-n11 {
      margin-bottom: -3rem !important;
    }
    .mb-xl-n12 {
      margin-bottom: -3.5rem !important;
    }
    .mb-xl-n13 {
      margin-bottom: -4rem !important;
    }
    .mb-xl-n14 {
      margin-bottom: -4.5rem !important;
    }
    .mb-xl-n15 {
      margin-bottom: -5rem !important;
    }
    .ms-xl-n1 {
      margin-left: -0.125rem !important;
    }
    .ms-xl-n2 {
      margin-left: -0.25rem !important;
    }
    .ms-xl-n3 {
      margin-left: -0.5rem !important;
    }
    .ms-xl-n4 {
      margin-left: -0.75rem !important;
    }
    .ms-xl-n5 {
      margin-left: -1rem !important;
    }
    .ms-xl-n6 {
      margin-left: -1.25rem !important;
    }
    .ms-xl-n7 {
      margin-left: -1.5rem !important;
    }
    .ms-xl-n8 {
      margin-left: -1.75rem !important;
    }
    .ms-xl-n9 {
      margin-left: -2rem !important;
    }
    .ms-xl-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-xl-n11 {
      margin-left: -3rem !important;
    }
    .ms-xl-n12 {
      margin-left: -3.5rem !important;
    }
    .ms-xl-n13 {
      margin-left: -4rem !important;
    }
    .ms-xl-n14 {
      margin-left: -4.5rem !important;
    }
    .ms-xl-n15 {
      margin-left: -5rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .p-xl-1 {
      padding: 0.125rem !important;
    }
    .p-xl-2 {
      padding: 0.25rem !important;
    }
    .p-xl-3 {
      padding: 0.5rem !important;
    }
    .p-xl-4 {
      padding: 0.75rem !important;
    }
    .p-xl-5 {
      padding: 1rem !important;
    }
    .p-xl-6 {
      padding: 1.25rem !important;
    }
    .p-xl-7 {
      padding: 1.5rem !important;
    }
    .p-xl-8 {
      padding: 1.75rem !important;
    }
    .p-xl-9 {
      padding: 2rem !important;
    }
    .p-xl-10 {
      padding: 2.5rem !important;
    }
    .p-xl-11 {
      padding: 3rem !important;
    }
    .p-xl-12 {
      padding: 3.5rem !important;
    }
    .p-xl-13 {
      padding: 4rem !important;
    }
    .p-xl-14 {
      padding: 4.5rem !important;
    }
    .p-xl-15 {
      padding: 5rem !important;
    }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xl-1 {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important;
    }
    .px-xl-2 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xl-3 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xl-4 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-xl-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xl-6 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-xl-7 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xl-8 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-xl-9 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-xl-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-xl-11 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xl-12 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-xl-13 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xl-14 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-xl-15 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xl-1 {
      padding-top: 0.125rem !important;
      padding-bottom: 0.125rem !important;
    }
    .py-xl-2 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xl-3 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xl-4 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-xl-5 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xl-6 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-xl-7 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xl-8 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-xl-9 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-xl-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-xl-11 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xl-12 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-xl-13 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xl-14 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-xl-15 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .pt-xl-0 {
      padding-top: 0 !important;
    }
    .pt-xl-1 {
      padding-top: 0.125rem !important;
    }
    .pt-xl-2 {
      padding-top: 0.25rem !important;
    }
    .pt-xl-3 {
      padding-top: 0.5rem !important;
    }
    .pt-xl-4 {
      padding-top: 0.75rem !important;
    }
    .pt-xl-5 {
      padding-top: 1rem !important;
    }
    .pt-xl-6 {
      padding-top: 1.25rem !important;
    }
    .pt-xl-7 {
      padding-top: 1.5rem !important;
    }
    .pt-xl-8 {
      padding-top: 1.75rem !important;
    }
    .pt-xl-9 {
      padding-top: 2rem !important;
    }
    .pt-xl-10 {
      padding-top: 2.5rem !important;
    }
    .pt-xl-11 {
      padding-top: 3rem !important;
    }
    .pt-xl-12 {
      padding-top: 3.5rem !important;
    }
    .pt-xl-13 {
      padding-top: 4rem !important;
    }
    .pt-xl-14 {
      padding-top: 4.5rem !important;
    }
    .pt-xl-15 {
      padding-top: 5rem !important;
    }
    .pe-xl-0 {
      padding-right: 0 !important;
    }
    .pe-xl-1 {
      padding-right: 0.125rem !important;
    }
    .pe-xl-2 {
      padding-right: 0.25rem !important;
    }
    .pe-xl-3 {
      padding-right: 0.5rem !important;
    }
    .pe-xl-4 {
      padding-right: 0.75rem !important;
    }
    .pe-xl-5 {
      padding-right: 1rem !important;
    }
    .pe-xl-6 {
      padding-right: 1.25rem !important;
    }
    .pe-xl-7 {
      padding-right: 1.5rem !important;
    }
    .pe-xl-8 {
      padding-right: 1.75rem !important;
    }
    .pe-xl-9 {
      padding-right: 2rem !important;
    }
    .pe-xl-10 {
      padding-right: 2.5rem !important;
    }
    .pe-xl-11 {
      padding-right: 3rem !important;
    }
    .pe-xl-12 {
      padding-right: 3.5rem !important;
    }
    .pe-xl-13 {
      padding-right: 4rem !important;
    }
    .pe-xl-14 {
      padding-right: 4.5rem !important;
    }
    .pe-xl-15 {
      padding-right: 5rem !important;
    }
    .pb-xl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xl-1 {
      padding-bottom: 0.125rem !important;
    }
    .pb-xl-2 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xl-3 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xl-4 {
      padding-bottom: 0.75rem !important;
    }
    .pb-xl-5 {
      padding-bottom: 1rem !important;
    }
    .pb-xl-6 {
      padding-bottom: 1.25rem !important;
    }
    .pb-xl-7 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xl-8 {
      padding-bottom: 1.75rem !important;
    }
    .pb-xl-9 {
      padding-bottom: 2rem !important;
    }
    .pb-xl-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-xl-11 {
      padding-bottom: 3rem !important;
    }
    .pb-xl-12 {
      padding-bottom: 3.5rem !important;
    }
    .pb-xl-13 {
      padding-bottom: 4rem !important;
    }
    .pb-xl-14 {
      padding-bottom: 4.5rem !important;
    }
    .pb-xl-15 {
      padding-bottom: 5rem !important;
    }
    .ps-xl-0 {
      padding-left: 0 !important;
    }
    .ps-xl-1 {
      padding-left: 0.125rem !important;
    }
    .ps-xl-2 {
      padding-left: 0.25rem !important;
    }
    .ps-xl-3 {
      padding-left: 0.5rem !important;
    }
    .ps-xl-4 {
      padding-left: 0.75rem !important;
    }
    .ps-xl-5 {
      padding-left: 1rem !important;
    }
    .ps-xl-6 {
      padding-left: 1.25rem !important;
    }
    .ps-xl-7 {
      padding-left: 1.5rem !important;
    }
    .ps-xl-8 {
      padding-left: 1.75rem !important;
    }
    .ps-xl-9 {
      padding-left: 2rem !important;
    }
    .ps-xl-10 {
      padding-left: 2.5rem !important;
    }
    .ps-xl-11 {
      padding-left: 3rem !important;
    }
    .ps-xl-12 {
      padding-left: 3.5rem !important;
    }
    .ps-xl-13 {
      padding-left: 4rem !important;
    }
    .ps-xl-14 {
      padding-left: 4.5rem !important;
    }
    .ps-xl-15 {
      padding-left: 5rem !important;
    }
    .text-xl-start {
      text-align: left !important;
    }
    .text-xl-end {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
    .rounded-top-xl {
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
    }
    .visibility-xl-visible {
      visibility: visible !important;
    }
    .visibility-xl-invisible {
      visibility: hidden !important;
    }
  }
  @media (min-width: 1440px) {
    .float-xxl-start {
      float: left !important;
    }
    .float-xxl-end {
      float: right !important;
    }
    .float-xxl-none {
      float: none !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .flex-xxl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xxl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xxl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-xxl-0 {
      gap: 0 !important;
    }
    .gap-xxl-1 {
      gap: 0.125rem !important;
    }
    .gap-xxl-2 {
      gap: 0.25rem !important;
    }
    .gap-xxl-3 {
      gap: 0.5rem !important;
    }
    .gap-xxl-4 {
      gap: 0.75rem !important;
    }
    .gap-xxl-5 {
      gap: 1rem !important;
    }
    .gap-xxl-6 {
      gap: 1.25rem !important;
    }
    .gap-xxl-7 {
      gap: 1.5rem !important;
    }
    .gap-xxl-8 {
      gap: 1.75rem !important;
    }
    .gap-xxl-9 {
      gap: 2rem !important;
    }
    .gap-xxl-10 {
      gap: 2.5rem !important;
    }
    .gap-xxl-11 {
      gap: 3rem !important;
    }
    .gap-xxl-12 {
      gap: 3.5rem !important;
    }
    .gap-xxl-13 {
      gap: 4rem !important;
    }
    .gap-xxl-14 {
      gap: 4.5rem !important;
    }
    .gap-xxl-15 {
      gap: 5rem !important;
    }
    .justify-content-xxl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xxl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xxl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xxl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-xxl-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-xxl-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-xxl-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-xxl-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-xxl-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-xxl-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-xxl-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-xxl-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-xxl-0 {
      margin: 0 !important;
    }
    .m-xxl-1 {
      margin: 0.125rem !important;
    }
    .m-xxl-2 {
      margin: 0.25rem !important;
    }
    .m-xxl-3 {
      margin: 0.5rem !important;
    }
    .m-xxl-4 {
      margin: 0.75rem !important;
    }
    .m-xxl-5 {
      margin: 1rem !important;
    }
    .m-xxl-6 {
      margin: 1.25rem !important;
    }
    .m-xxl-7 {
      margin: 1.5rem !important;
    }
    .m-xxl-8 {
      margin: 1.75rem !important;
    }
    .m-xxl-9 {
      margin: 2rem !important;
    }
    .m-xxl-10 {
      margin: 2.5rem !important;
    }
    .m-xxl-11 {
      margin: 3rem !important;
    }
    .m-xxl-12 {
      margin: 3.5rem !important;
    }
    .m-xxl-13 {
      margin: 4rem !important;
    }
    .m-xxl-14 {
      margin: 4.5rem !important;
    }
    .m-xxl-15 {
      margin: 5rem !important;
    }
    .m-xxl-auto {
      margin: auto !important;
    }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xxl-1 {
      margin-right: 0.125rem !important;
      margin-left: 0.125rem !important;
    }
    .mx-xxl-2 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xxl-3 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xxl-4 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important;
    }
    .mx-xxl-5 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xxl-6 {
      margin-right: 1.25rem !important;
      margin-left: 1.25rem !important;
    }
    .mx-xxl-7 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xxl-8 {
      margin-right: 1.75rem !important;
      margin-left: 1.75rem !important;
    }
    .mx-xxl-9 {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }
    .mx-xxl-10 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important;
    }
    .mx-xxl-11 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xxl-12 {
      margin-right: 3.5rem !important;
      margin-left: 3.5rem !important;
    }
    .mx-xxl-13 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xxl-14 {
      margin-right: 4.5rem !important;
      margin-left: 4.5rem !important;
    }
    .mx-xxl-15 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xxl-1 {
      margin-top: 0.125rem !important;
      margin-bottom: 0.125rem !important;
    }
    .my-xxl-2 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xxl-3 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xxl-4 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important;
    }
    .my-xxl-5 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xxl-6 {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
    .my-xxl-7 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xxl-8 {
      margin-top: 1.75rem !important;
      margin-bottom: 1.75rem !important;
    }
    .my-xxl-9 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .my-xxl-10 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important;
    }
    .my-xxl-11 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xxl-12 {
      margin-top: 3.5rem !important;
      margin-bottom: 3.5rem !important;
    }
    .my-xxl-13 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xxl-14 {
      margin-top: 4.5rem !important;
      margin-bottom: 4.5rem !important;
    }
    .my-xxl-15 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xxl-0 {
      margin-top: 0 !important;
    }
    .mt-xxl-1 {
      margin-top: 0.125rem !important;
    }
    .mt-xxl-2 {
      margin-top: 0.25rem !important;
    }
    .mt-xxl-3 {
      margin-top: 0.5rem !important;
    }
    .mt-xxl-4 {
      margin-top: 0.75rem !important;
    }
    .mt-xxl-5 {
      margin-top: 1rem !important;
    }
    .mt-xxl-6 {
      margin-top: 1.25rem !important;
    }
    .mt-xxl-7 {
      margin-top: 1.5rem !important;
    }
    .mt-xxl-8 {
      margin-top: 1.75rem !important;
    }
    .mt-xxl-9 {
      margin-top: 2rem !important;
    }
    .mt-xxl-10 {
      margin-top: 2.5rem !important;
    }
    .mt-xxl-11 {
      margin-top: 3rem !important;
    }
    .mt-xxl-12 {
      margin-top: 3.5rem !important;
    }
    .mt-xxl-13 {
      margin-top: 4rem !important;
    }
    .mt-xxl-14 {
      margin-top: 4.5rem !important;
    }
    .mt-xxl-15 {
      margin-top: 5rem !important;
    }
    .mt-xxl-auto {
      margin-top: auto !important;
    }
    .me-xxl-0 {
      margin-right: 0 !important;
    }
    .me-xxl-1 {
      margin-right: 0.125rem !important;
    }
    .me-xxl-2 {
      margin-right: 0.25rem !important;
    }
    .me-xxl-3 {
      margin-right: 0.5rem !important;
    }
    .me-xxl-4 {
      margin-right: 0.75rem !important;
    }
    .me-xxl-5 {
      margin-right: 1rem !important;
    }
    .me-xxl-6 {
      margin-right: 1.25rem !important;
    }
    .me-xxl-7 {
      margin-right: 1.5rem !important;
    }
    .me-xxl-8 {
      margin-right: 1.75rem !important;
    }
    .me-xxl-9 {
      margin-right: 2rem !important;
    }
    .me-xxl-10 {
      margin-right: 2.5rem !important;
    }
    .me-xxl-11 {
      margin-right: 3rem !important;
    }
    .me-xxl-12 {
      margin-right: 3.5rem !important;
    }
    .me-xxl-13 {
      margin-right: 4rem !important;
    }
    .me-xxl-14 {
      margin-right: 4.5rem !important;
    }
    .me-xxl-15 {
      margin-right: 5rem !important;
    }
    .me-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
      margin-bottom: 0.125rem !important;
    }
    .mb-xxl-2 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xxl-3 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xxl-4 {
      margin-bottom: 0.75rem !important;
    }
    .mb-xxl-5 {
      margin-bottom: 1rem !important;
    }
    .mb-xxl-6 {
      margin-bottom: 1.25rem !important;
    }
    .mb-xxl-7 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xxl-8 {
      margin-bottom: 1.75rem !important;
    }
    .mb-xxl-9 {
      margin-bottom: 2rem !important;
    }
    .mb-xxl-10 {
      margin-bottom: 2.5rem !important;
    }
    .mb-xxl-11 {
      margin-bottom: 3rem !important;
    }
    .mb-xxl-12 {
      margin-bottom: 3.5rem !important;
    }
    .mb-xxl-13 {
      margin-bottom: 4rem !important;
    }
    .mb-xxl-14 {
      margin-bottom: 4.5rem !important;
    }
    .mb-xxl-15 {
      margin-bottom: 5rem !important;
    }
    .mb-xxl-auto {
      margin-bottom: auto !important;
    }
    .ms-xxl-0 {
      margin-left: 0 !important;
    }
    .ms-xxl-1 {
      margin-left: 0.125rem !important;
    }
    .ms-xxl-2 {
      margin-left: 0.25rem !important;
    }
    .ms-xxl-3 {
      margin-left: 0.5rem !important;
    }
    .ms-xxl-4 {
      margin-left: 0.75rem !important;
    }
    .ms-xxl-5 {
      margin-left: 1rem !important;
    }
    .ms-xxl-6 {
      margin-left: 1.25rem !important;
    }
    .ms-xxl-7 {
      margin-left: 1.5rem !important;
    }
    .ms-xxl-8 {
      margin-left: 1.75rem !important;
    }
    .ms-xxl-9 {
      margin-left: 2rem !important;
    }
    .ms-xxl-10 {
      margin-left: 2.5rem !important;
    }
    .ms-xxl-11 {
      margin-left: 3rem !important;
    }
    .ms-xxl-12 {
      margin-left: 3.5rem !important;
    }
    .ms-xxl-13 {
      margin-left: 4rem !important;
    }
    .ms-xxl-14 {
      margin-left: 4.5rem !important;
    }
    .ms-xxl-15 {
      margin-left: 5rem !important;
    }
    .ms-xxl-auto {
      margin-left: auto !important;
    }
    .m-xxl-n1 {
      margin: -0.125rem !important;
    }
    .m-xxl-n2 {
      margin: -0.25rem !important;
    }
    .m-xxl-n3 {
      margin: -0.5rem !important;
    }
    .m-xxl-n4 {
      margin: -0.75rem !important;
    }
    .m-xxl-n5 {
      margin: -1rem !important;
    }
    .m-xxl-n6 {
      margin: -1.25rem !important;
    }
    .m-xxl-n7 {
      margin: -1.5rem !important;
    }
    .m-xxl-n8 {
      margin: -1.75rem !important;
    }
    .m-xxl-n9 {
      margin: -2rem !important;
    }
    .m-xxl-n10 {
      margin: -2.5rem !important;
    }
    .m-xxl-n11 {
      margin: -3rem !important;
    }
    .m-xxl-n12 {
      margin: -3.5rem !important;
    }
    .m-xxl-n13 {
      margin: -4rem !important;
    }
    .m-xxl-n14 {
      margin: -4.5rem !important;
    }
    .m-xxl-n15 {
      margin: -5rem !important;
    }
    .mx-xxl-n1 {
      margin-right: -0.125rem !important;
      margin-left: -0.125rem !important;
    }
    .mx-xxl-n2 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xxl-n3 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xxl-n4 {
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
    }
    .mx-xxl-n5 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xxl-n6 {
      margin-right: -1.25rem !important;
      margin-left: -1.25rem !important;
    }
    .mx-xxl-n7 {
      margin-right: -1.5rem !important;
      margin-left: -1.5rem !important;
    }
    .mx-xxl-n8 {
      margin-right: -1.75rem !important;
      margin-left: -1.75rem !important;
    }
    .mx-xxl-n9 {
      margin-right: -2rem !important;
      margin-left: -2rem !important;
    }
    .mx-xxl-n10 {
      margin-right: -2.5rem !important;
      margin-left: -2.5rem !important;
    }
    .mx-xxl-n11 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xxl-n12 {
      margin-right: -3.5rem !important;
      margin-left: -3.5rem !important;
    }
    .mx-xxl-n13 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xxl-n14 {
      margin-right: -4.5rem !important;
      margin-left: -4.5rem !important;
    }
    .mx-xxl-n15 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .my-xxl-n1 {
      margin-top: -0.125rem !important;
      margin-bottom: -0.125rem !important;
    }
    .my-xxl-n2 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xxl-n3 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xxl-n4 {
      margin-top: -0.75rem !important;
      margin-bottom: -0.75rem !important;
    }
    .my-xxl-n5 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xxl-n6 {
      margin-top: -1.25rem !important;
      margin-bottom: -1.25rem !important;
    }
    .my-xxl-n7 {
      margin-top: -1.5rem !important;
      margin-bottom: -1.5rem !important;
    }
    .my-xxl-n8 {
      margin-top: -1.75rem !important;
      margin-bottom: -1.75rem !important;
    }
    .my-xxl-n9 {
      margin-top: -2rem !important;
      margin-bottom: -2rem !important;
    }
    .my-xxl-n10 {
      margin-top: -2.5rem !important;
      margin-bottom: -2.5rem !important;
    }
    .my-xxl-n11 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xxl-n12 {
      margin-top: -3.5rem !important;
      margin-bottom: -3.5rem !important;
    }
    .my-xxl-n13 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xxl-n14 {
      margin-top: -4.5rem !important;
      margin-bottom: -4.5rem !important;
    }
    .my-xxl-n15 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .mt-xxl-n1 {
      margin-top: -0.125rem !important;
    }
    .mt-xxl-n2 {
      margin-top: -0.25rem !important;
    }
    .mt-xxl-n3 {
      margin-top: -0.5rem !important;
    }
    .mt-xxl-n4 {
      margin-top: -0.75rem !important;
    }
    .mt-xxl-n5 {
      margin-top: -1rem !important;
    }
    .mt-xxl-n6 {
      margin-top: -1.25rem !important;
    }
    .mt-xxl-n7 {
      margin-top: -1.5rem !important;
    }
    .mt-xxl-n8 {
      margin-top: -1.75rem !important;
    }
    .mt-xxl-n9 {
      margin-top: -2rem !important;
    }
    .mt-xxl-n10 {
      margin-top: -2.5rem !important;
    }
    .mt-xxl-n11 {
      margin-top: -3rem !important;
    }
    .mt-xxl-n12 {
      margin-top: -3.5rem !important;
    }
    .mt-xxl-n13 {
      margin-top: -4rem !important;
    }
    .mt-xxl-n14 {
      margin-top: -4.5rem !important;
    }
    .mt-xxl-n15 {
      margin-top: -5rem !important;
    }
    .me-xxl-n1 {
      margin-right: -0.125rem !important;
    }
    .me-xxl-n2 {
      margin-right: -0.25rem !important;
    }
    .me-xxl-n3 {
      margin-right: -0.5rem !important;
    }
    .me-xxl-n4 {
      margin-right: -0.75rem !important;
    }
    .me-xxl-n5 {
      margin-right: -1rem !important;
    }
    .me-xxl-n6 {
      margin-right: -1.25rem !important;
    }
    .me-xxl-n7 {
      margin-right: -1.5rem !important;
    }
    .me-xxl-n8 {
      margin-right: -1.75rem !important;
    }
    .me-xxl-n9 {
      margin-right: -2rem !important;
    }
    .me-xxl-n10 {
      margin-right: -2.5rem !important;
    }
    .me-xxl-n11 {
      margin-right: -3rem !important;
    }
    .me-xxl-n12 {
      margin-right: -3.5rem !important;
    }
    .me-xxl-n13 {
      margin-right: -4rem !important;
    }
    .me-xxl-n14 {
      margin-right: -4.5rem !important;
    }
    .me-xxl-n15 {
      margin-right: -5rem !important;
    }
    .mb-xxl-n1 {
      margin-bottom: -0.125rem !important;
    }
    .mb-xxl-n2 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xxl-n3 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xxl-n4 {
      margin-bottom: -0.75rem !important;
    }
    .mb-xxl-n5 {
      margin-bottom: -1rem !important;
    }
    .mb-xxl-n6 {
      margin-bottom: -1.25rem !important;
    }
    .mb-xxl-n7 {
      margin-bottom: -1.5rem !important;
    }
    .mb-xxl-n8 {
      margin-bottom: -1.75rem !important;
    }
    .mb-xxl-n9 {
      margin-bottom: -2rem !important;
    }
    .mb-xxl-n10 {
      margin-bottom: -2.5rem !important;
    }
    .mb-xxl-n11 {
      margin-bottom: -3rem !important;
    }
    .mb-xxl-n12 {
      margin-bottom: -3.5rem !important;
    }
    .mb-xxl-n13 {
      margin-bottom: -4rem !important;
    }
    .mb-xxl-n14 {
      margin-bottom: -4.5rem !important;
    }
    .mb-xxl-n15 {
      margin-bottom: -5rem !important;
    }
    .ms-xxl-n1 {
      margin-left: -0.125rem !important;
    }
    .ms-xxl-n2 {
      margin-left: -0.25rem !important;
    }
    .ms-xxl-n3 {
      margin-left: -0.5rem !important;
    }
    .ms-xxl-n4 {
      margin-left: -0.75rem !important;
    }
    .ms-xxl-n5 {
      margin-left: -1rem !important;
    }
    .ms-xxl-n6 {
      margin-left: -1.25rem !important;
    }
    .ms-xxl-n7 {
      margin-left: -1.5rem !important;
    }
    .ms-xxl-n8 {
      margin-left: -1.75rem !important;
    }
    .ms-xxl-n9 {
      margin-left: -2rem !important;
    }
    .ms-xxl-n10 {
      margin-left: -2.5rem !important;
    }
    .ms-xxl-n11 {
      margin-left: -3rem !important;
    }
    .ms-xxl-n12 {
      margin-left: -3.5rem !important;
    }
    .ms-xxl-n13 {
      margin-left: -4rem !important;
    }
    .ms-xxl-n14 {
      margin-left: -4.5rem !important;
    }
    .ms-xxl-n15 {
      margin-left: -5rem !important;
    }
    .p-xxl-0 {
      padding: 0 !important;
    }
    .p-xxl-1 {
      padding: 0.125rem !important;
    }
    .p-xxl-2 {
      padding: 0.25rem !important;
    }
    .p-xxl-3 {
      padding: 0.5rem !important;
    }
    .p-xxl-4 {
      padding: 0.75rem !important;
    }
    .p-xxl-5 {
      padding: 1rem !important;
    }
    .p-xxl-6 {
      padding: 1.25rem !important;
    }
    .p-xxl-7 {
      padding: 1.5rem !important;
    }
    .p-xxl-8 {
      padding: 1.75rem !important;
    }
    .p-xxl-9 {
      padding: 2rem !important;
    }
    .p-xxl-10 {
      padding: 2.5rem !important;
    }
    .p-xxl-11 {
      padding: 3rem !important;
    }
    .p-xxl-12 {
      padding: 3.5rem !important;
    }
    .p-xxl-13 {
      padding: 4rem !important;
    }
    .p-xxl-14 {
      padding: 4.5rem !important;
    }
    .p-xxl-15 {
      padding: 5rem !important;
    }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xxl-1 {
      padding-right: 0.125rem !important;
      padding-left: 0.125rem !important;
    }
    .px-xxl-2 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xxl-3 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xxl-4 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important;
    }
    .px-xxl-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xxl-6 {
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
    }
    .px-xxl-7 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xxl-8 {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important;
    }
    .px-xxl-9 {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    .px-xxl-10 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important;
    }
    .px-xxl-11 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xxl-12 {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }
    .px-xxl-13 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xxl-14 {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important;
    }
    .px-xxl-15 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xxl-1 {
      padding-top: 0.125rem !important;
      padding-bottom: 0.125rem !important;
    }
    .py-xxl-2 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xxl-3 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xxl-4 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .py-xxl-5 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xxl-6 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .py-xxl-7 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xxl-8 {
      padding-top: 1.75rem !important;
      padding-bottom: 1.75rem !important;
    }
    .py-xxl-9 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .py-xxl-10 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .py-xxl-11 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xxl-12 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .py-xxl-13 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xxl-14 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .py-xxl-15 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .pt-xxl-0 {
      padding-top: 0 !important;
    }
    .pt-xxl-1 {
      padding-top: 0.125rem !important;
    }
    .pt-xxl-2 {
      padding-top: 0.25rem !important;
    }
    .pt-xxl-3 {
      padding-top: 0.5rem !important;
    }
    .pt-xxl-4 {
      padding-top: 0.75rem !important;
    }
    .pt-xxl-5 {
      padding-top: 1rem !important;
    }
    .pt-xxl-6 {
      padding-top: 1.25rem !important;
    }
    .pt-xxl-7 {
      padding-top: 1.5rem !important;
    }
    .pt-xxl-8 {
      padding-top: 1.75rem !important;
    }
    .pt-xxl-9 {
      padding-top: 2rem !important;
    }
    .pt-xxl-10 {
      padding-top: 2.5rem !important;
    }
    .pt-xxl-11 {
      padding-top: 3rem !important;
    }
    .pt-xxl-12 {
      padding-top: 3.5rem !important;
    }
    .pt-xxl-13 {
      padding-top: 4rem !important;
    }
    .pt-xxl-14 {
      padding-top: 4.5rem !important;
    }
    .pt-xxl-15 {
      padding-top: 5rem !important;
    }
    .pe-xxl-0 {
      padding-right: 0 !important;
    }
    .pe-xxl-1 {
      padding-right: 0.125rem !important;
    }
    .pe-xxl-2 {
      padding-right: 0.25rem !important;
    }
    .pe-xxl-3 {
      padding-right: 0.5rem !important;
    }
    .pe-xxl-4 {
      padding-right: 0.75rem !important;
    }
    .pe-xxl-5 {
      padding-right: 1rem !important;
    }
    .pe-xxl-6 {
      padding-right: 1.25rem !important;
    }
    .pe-xxl-7 {
      padding-right: 1.5rem !important;
    }
    .pe-xxl-8 {
      padding-right: 1.75rem !important;
    }
    .pe-xxl-9 {
      padding-right: 2rem !important;
    }
    .pe-xxl-10 {
      padding-right: 2.5rem !important;
    }
    .pe-xxl-11 {
      padding-right: 3rem !important;
    }
    .pe-xxl-12 {
      padding-right: 3.5rem !important;
    }
    .pe-xxl-13 {
      padding-right: 4rem !important;
    }
    .pe-xxl-14 {
      padding-right: 4.5rem !important;
    }
    .pe-xxl-15 {
      padding-right: 5rem !important;
    }
    .pb-xxl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
      padding-bottom: 0.125rem !important;
    }
    .pb-xxl-2 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xxl-3 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xxl-4 {
      padding-bottom: 0.75rem !important;
    }
    .pb-xxl-5 {
      padding-bottom: 1rem !important;
    }
    .pb-xxl-6 {
      padding-bottom: 1.25rem !important;
    }
    .pb-xxl-7 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xxl-8 {
      padding-bottom: 1.75rem !important;
    }
    .pb-xxl-9 {
      padding-bottom: 2rem !important;
    }
    .pb-xxl-10 {
      padding-bottom: 2.5rem !important;
    }
    .pb-xxl-11 {
      padding-bottom: 3rem !important;
    }
    .pb-xxl-12 {
      padding-bottom: 3.5rem !important;
    }
    .pb-xxl-13 {
      padding-bottom: 4rem !important;
    }
    .pb-xxl-14 {
      padding-bottom: 4.5rem !important;
    }
    .pb-xxl-15 {
      padding-bottom: 5rem !important;
    }
    .ps-xxl-0 {
      padding-left: 0 !important;
    }
    .ps-xxl-1 {
      padding-left: 0.125rem !important;
    }
    .ps-xxl-2 {
      padding-left: 0.25rem !important;
    }
    .ps-xxl-3 {
      padding-left: 0.5rem !important;
    }
    .ps-xxl-4 {
      padding-left: 0.75rem !important;
    }
    .ps-xxl-5 {
      padding-left: 1rem !important;
    }
    .ps-xxl-6 {
      padding-left: 1.25rem !important;
    }
    .ps-xxl-7 {
      padding-left: 1.5rem !important;
    }
    .ps-xxl-8 {
      padding-left: 1.75rem !important;
    }
    .ps-xxl-9 {
      padding-left: 2rem !important;
    }
    .ps-xxl-10 {
      padding-left: 2.5rem !important;
    }
    .ps-xxl-11 {
      padding-left: 3rem !important;
    }
    .ps-xxl-12 {
      padding-left: 3.5rem !important;
    }
    .ps-xxl-13 {
      padding-left: 4rem !important;
    }
    .ps-xxl-14 {
      padding-left: 4.5rem !important;
    }
    .ps-xxl-15 {
      padding-left: 5rem !important;
    }
    .text-xxl-start {
      text-align: left !important;
    }
    .text-xxl-end {
      text-align: right !important;
    }
    .text-xxl-center {
      text-align: center !important;
    }
    .rounded-top-xxl {
      border-top-left-radius: 0.6rem !important;
      border-top-right-radius: 0.6rem !important;
    }
    .visibility-xxl-visible {
      visibility: visible !important;
    }
    .visibility-xxl-invisible {
      visibility: hidden !important;
    }
  }
  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 1.5rem !important;
    }
    .fs-2 {
      font-size: 1.375rem !important;
    }
  }
  @media print {
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-grid {
      display: grid !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-print-none {
      display: none !important;
    }
  }
  .text-truncate-xs {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: 575.98px) {
    .text-truncate-sm {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 767.98px) {
    .text-truncate-md {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 991.98px) {
    .text-truncate-lg {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 1199.98px) {
    .text-truncate-xl {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 1439.98px) {
    .text-truncate-xxl {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 13px;
    color: #7c7d7f;
  }
  .text-soft-primary {
    color: #7db7f9 !important;
  }
  .text-soft-secondary {
    color: #e8eff6 !important;
  }
  .text-soft-success {
    color: #f4cebc !important;
  }
  .text-soft-info {
    color: #74c7d4 !important;
  }
  .text-soft-warning {
    color: #ffda6a !important;
  }
  .text-soft-danger {
    color: #fea3aa !important;
  }
  .text-soft-light {
    color: #fafbfd !important;
  }
  .text-soft-dark {
    color: #f3f7fa !important;
  }
  .text-soft-white {
    color: #fff !important;
  }
  .bg-soft-primary {
    background: #d4e7fd !important;
    background: rgba(39, 135, 245, 0.2) !important;
  }
  .bg-soft-secondary {
    background: #f7fafc !important;
    background: rgba(217, 228, 240, 0.2) !important;
  }
  .bg-soft-success {
    background: #fbefe9 !important;
    background: rgba(236, 173, 143, 0.2) !important;
  }
  .bg-soft-info {
    background: #d1ecf1 !important;
    background: rgba(23, 162, 184, 0.2) !important;
  }
  .bg-soft-warning {
    background: #fff3cd !important;
    background: rgba(255, 193, 7, 0.2) !important;
  }
  .bg-soft-danger {
    background: #ffe0e3 !important;
    background: rgba(254, 101, 113, 0.2) !important;
  }
  .bg-soft-light {
    background: #fdfefe !important;
    background: rgba(246, 249, 251, 0.2) !important;
  }
  .bg-soft-dark {
    background: #fbfcfd !important;
    background: rgba(235, 241, 247, 0.2) !important;
  }
  .bg-soft-white {
    background: #fff !important;
    background: rgba(255, 255, 255, 0.2) !important;
  }
  .alert hr {
    color: inherit;
    opacity: 0.4;
  }
  .alert:last-child {
    margin-bottom: 0;
  }
  .accordion-button {
    background: 0 0;
    border: 0;
    padding: 0;
  }
  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .accordion-item:first-of-type .accordion-button,
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 0;
  }
  .accordion-header {
    padding: 1.25rem 1.5rem;
    border-top: 2px solid #f5f8fb;
  }
  .accordion-item:first-child .accordion-header {
    border-top: 0;
  }
  .accordion-item:not(:first-child) .accordion-header {
    margin-top: -2px;
  }
  .accordion-collapse {
    border: 0;
    border-top: 2px solid #f5f8fb;
  }
  .accordion-flush {
    border: 0;
  }
  .accordion-flush .accordion-body,
  .accordion-flush .accordion-header {
    padding-left: 0;
    padding-right: 0;
  }
  .accordion-flush .accordion-collapse {
    border-top: 2px solid #f5f8fb;
  }
  .badge-circle {
    position: relative;
    border-radius: 2.25rem;
    height: calc(1em + 0.778em);
    padding: 0.389em;
  }
  .badge-circle > i,
  .badge-circle > span {
    display: block;
    min-width: 1em;
  }
  .badge-circle svg {
    height: 1em;
    width: 1em;
  }
  .badge-xl {
    padding: 0.52515em 0.81em;
    font-size: 0.81em;
  }
  .badge-xl.badge-circle {
    height: calc(1em + 1.0503em);
    padding: 0.52515em;
  }
  .badge-lg {
    padding: 0.4668em 0.72em;
    font-size: 0.72em;
  }
  .badge-lg.badge-circle {
    height: calc(1em + 0.9336em);
    padding: 0.4668em;
  }
  .badge-sm {
    padding: 0.33065em 0.51em;
    font-size: 0.51em;
  }
  .badge-sm.badge-circle {
    height: calc(1em + 0.6613em);
    padding: 0.33065em;
  }
  .badge-xs {
    padding: 0.29175em 0.45em;
    font-size: 0.45em;
  }
  .badge-xs.badge-circle {
    height: calc(1em + 0.5835em);
    padding: 0.29175em;
  }
  .btn:not([class*="btn-outline"]) {
    border-color: transparent !important;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    letter-spacing: 0.00625rem;
  }
  .btn-soft-primary {
    color: #2787f5;
    background: #dfedfe;
    border-color: #dfedfe;
  }
  .btn-soft-primary:hover {
    color: #2787f5;
    background: #d5e7fe;
    border-color: #d5e7fe;
  }
  .btn-check:focus + .btn-soft-primary,
  .btn-soft-primary:focus {
    color: #2787f5;
    background-color: #d5e7fe;
    border-color: #d5e7fe;
    -webkit-box-shadow: 0 0 0 0 rgba(195, 222, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(195, 222, 253, 0.5);
  }
  .btn-check:active + .btn-soft-primary,
  .btn-check:checked + .btn-soft-primary,
  .btn-soft-primary.active,
  .btn-soft-primary:active,
  .show > .btn-soft-primary.dropdown-toggle {
    color: #2787f5;
    background-color: #d3e6fe;
    border-color: #d3e6fe;
  }
  .btn-check:active + .btn-soft-primary:focus,
  .btn-check:checked + .btn-soft-primary:focus,
  .btn-soft-primary.active:focus,
  .btn-soft-primary:active:focus,
  .show > .btn-soft-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(195, 222, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(195, 222, 253, 0.5);
  }
  .btn-soft-primary.disabled,
  .btn-soft-primary:disabled {
    color: #2787f5;
    background-color: #dfedfe;
    border-color: #dfedfe;
  }
  .btn-soft-secondary {
    color: #d9e4f0;
    background: #f9fbfd;
    border-color: #f9fbfd;
  }
  .btn-soft-secondary:hover {
    color: #d9e4f0;
    background: #f1f6fa;
    border-color: #f1f6fa;
  }
  .btn-check:focus + .btn-soft-secondary,
  .btn-soft-secondary:focus {
    color: #d9e4f0;
    background-color: #f1f6fa;
    border-color: #f1f6fa;
    -webkit-box-shadow: 0 0 0 0 rgba(244, 248, 251, 0.5);
    box-shadow: 0 0 0 0 rgba(244, 248, 251, 0.5);
  }
  .btn-check:active + .btn-soft-secondary,
  .btn-check:checked + .btn-soft-secondary,
  .btn-soft-secondary.active,
  .btn-soft-secondary:active,
  .show > .btn-soft-secondary.dropdown-toggle {
    color: #d9e4f0;
    background-color: #eff5fa;
    border-color: #eff5fa;
  }
  .btn-check:active + .btn-soft-secondary:focus,
  .btn-check:checked + .btn-soft-secondary:focus,
  .btn-soft-secondary.active:focus,
  .btn-soft-secondary:active:focus,
  .show > .btn-soft-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 248, 251, 0.5);
    box-shadow: 0 0 0 0 rgba(244, 248, 251, 0.5);
  }
  .btn-soft-secondary.disabled,
  .btn-soft-secondary:disabled {
    color: #d9e4f0;
    background-color: #f9fbfd;
    border-color: #f9fbfd;
  }
  .btn-soft-success {
    color: #ecad8f;
    background: #fcf3ee;
    border-color: #fcf3ee;
  }
  .btn-soft-success:hover {
    color: #ecad8f;
    background: #faede5;
    border-color: #faede5;
  }
  .btn-check:focus + .btn-soft-success,
  .btn-soft-success:focus {
    color: #ecad8f;
    background-color: #faede5;
    border-color: #faede5;
    -webkit-box-shadow: 0 0 0 0 rgba(250, 233, 224, 0.5);
    box-shadow: 0 0 0 0 rgba(250, 233, 224, 0.5);
  }
  .btn-check:active + .btn-soft-success,
  .btn-check:checked + .btn-soft-success,
  .btn-soft-success.active,
  .btn-soft-success:active,
  .show > .btn-soft-success.dropdown-toggle {
    color: #ecad8f;
    background-color: #faebe3;
    border-color: #faebe3;
  }
  .btn-check:active + .btn-soft-success:focus,
  .btn-check:checked + .btn-soft-success:focus,
  .btn-soft-success.active:focus,
  .btn-soft-success:active:focus,
  .show > .btn-soft-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 233, 224, 0.5);
    box-shadow: 0 0 0 0 rgba(250, 233, 224, 0.5);
  }
  .btn-soft-success.disabled,
  .btn-soft-success:disabled {
    color: #ecad8f;
    background-color: #fcf3ee;
    border-color: #fcf3ee;
  }
  .btn-soft-info {
    color: #17a2b8;
    background: #dcf1f4;
    border-color: #dcf1f4;
  }
  .btn-soft-info:hover {
    color: #17a2b8;
    background: #d4eef2;
    border-color: #d4eef2;
  }
  .btn-check:focus + .btn-soft-info,
  .btn-soft-info:focus {
    color: #17a2b8;
    background-color: #d4eef2;
    border-color: #d4eef2;
    -webkit-box-shadow: 0 0 0 0 rgba(190, 229, 235, 0.5);
    box-shadow: 0 0 0 0 rgba(190, 229, 235, 0.5);
  }
  .btn-check:active + .btn-soft-info,
  .btn-check:checked + .btn-soft-info,
  .btn-soft-info.active,
  .btn-soft-info:active,
  .show > .btn-soft-info.dropdown-toggle {
    color: #17a2b8;
    background-color: #d2edf1;
    border-color: #d2edf1;
  }
  .btn-check:active + .btn-soft-info:focus,
  .btn-check:checked + .btn-soft-info:focus,
  .btn-soft-info.active:focus,
  .btn-soft-info:active:focus,
  .show > .btn-soft-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(190, 229, 235, 0.5);
    box-shadow: 0 0 0 0 rgba(190, 229, 235, 0.5);
  }
  .btn-soft-info.disabled,
  .btn-soft-info:disabled {
    color: #17a2b8;
    background-color: #dcf1f4;
    border-color: #dcf1f4;
  }
  .btn-soft-warning {
    color: #ffc107;
    background: #fff6da;
    border-color: #fff6da;
  }
  .btn-soft-warning:hover {
    color: #ffc107;
    background: #fff4d0;
    border-color: #fff4d0;
  }
  .btn-check:focus + .btn-soft-warning,
  .btn-soft-warning:focus {
    color: #ffc107;
    background-color: #fff4d0;
    border-color: #fff4d0;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 238, 186, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 238, 186, 0.5);
  }
  .btn-check:active + .btn-soft-warning,
  .btn-check:checked + .btn-soft-warning,
  .btn-soft-warning.active,
  .btn-soft-warning:active,
  .show > .btn-soft-warning.dropdown-toggle {
    color: #ffc107;
    background-color: #fff3cd;
    border-color: #fff3cd;
  }
  .btn-check:active + .btn-soft-warning:focus,
  .btn-check:checked + .btn-soft-warning:focus,
  .btn-soft-warning.active:focus,
  .btn-soft-warning:active:focus,
  .show > .btn-soft-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 238, 186, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 238, 186, 0.5);
  }
  .btn-soft-warning.disabled,
  .btn-soft-warning:disabled {
    color: #ffc107;
    background-color: #fff6da;
    border-color: #fff6da;
  }
  .btn-soft-danger {
    color: #fe6571;
    background: #ffe8ea;
    border-color: #ffe8ea;
  }
  .btn-soft-danger:hover {
    color: #fe6571;
    background: #ffdee1;
    border-color: #ffdee1;
  }
  .btn-check:focus + .btn-soft-danger,
  .btn-soft-danger:focus {
    color: #fe6571;
    background-color: #ffdee1;
    border-color: #ffdee1;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 212, 216, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 212, 216, 0.5);
  }
  .btn-check:active + .btn-soft-danger,
  .btn-check:checked + .btn-soft-danger,
  .btn-soft-danger.active,
  .btn-soft-danger:active,
  .show > .btn-soft-danger.dropdown-toggle {
    color: #fe6571;
    background-color: #ffdbde;
    border-color: #ffdbde;
  }
  .btn-check:active + .btn-soft-danger:focus,
  .btn-check:checked + .btn-soft-danger:focus,
  .btn-soft-danger.active:focus,
  .btn-soft-danger:active:focus,
  .show > .btn-soft-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 212, 216, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 212, 216, 0.5);
  }
  .btn-soft-danger.disabled,
  .btn-soft-danger:disabled {
    color: #fe6571;
    background-color: #ffe8ea;
    border-color: #ffe8ea;
  }
  .btn-soft-light {
    color: #f6f9fb;
    background: #fefefe;
    border-color: #fefefe;
  }
  .btn-soft-light:hover {
    color: #f6f9fb;
    background: #f9f9f9;
    border-color: #f9f9f9;
  }
  .btn-check:focus + .btn-soft-light,
  .btn-soft-light:focus {
    color: #f6f9fb;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    -webkit-box-shadow: 0 0 0 0 rgba(253, 253, 254, 0.5);
    box-shadow: 0 0 0 0 rgba(253, 253, 254, 0.5);
  }
  .btn-check:active + .btn-soft-light,
  .btn-check:checked + .btn-soft-light,
  .btn-soft-light.active,
  .btn-soft-light:active,
  .show > .btn-soft-light.dropdown-toggle {
    color: #f6f9fb;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
  }
  .btn-check:active + .btn-soft-light:focus,
  .btn-check:checked + .btn-soft-light:focus,
  .btn-soft-light.active:focus,
  .btn-soft-light:active:focus,
  .show > .btn-soft-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(253, 253, 254, 0.5);
    box-shadow: 0 0 0 0 rgba(253, 253, 254, 0.5);
  }
  .btn-soft-light.disabled,
  .btn-soft-light:disabled {
    color: #f6f9fb;
    background-color: #fefefe;
    border-color: #fefefe;
  }
  .btn-soft-dark {
    color: #ebf1f7;
    background: #fcfdfe;
    border-color: #fcfdfe;
  }
  .btn-soft-dark:hover {
    color: #ebf1f7;
    background: #f4f8fb;
    border-color: #f4f8fb;
  }
  .btn-check:focus + .btn-soft-dark,
  .btn-soft-dark:focus {
    color: #ebf1f7;
    background-color: #f4f8fb;
    border-color: #f4f8fb;
    -webkit-box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
  }
  .btn-check:active + .btn-soft-dark,
  .btn-check:checked + .btn-soft-dark,
  .btn-soft-dark.active,
  .btn-soft-dark:active,
  .show > .btn-soft-dark.dropdown-toggle {
    color: #ebf1f7;
    background-color: #f2f7fb;
    border-color: #f2f7fb;
  }
  .btn-check:active + .btn-soft-dark:focus,
  .btn-check:checked + .btn-soft-dark:focus,
  .btn-soft-dark.active:focus,
  .btn-soft-dark:active:focus,
  .show > .btn-soft-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5);
  }
  .btn-soft-dark.disabled,
  .btn-soft-dark:disabled {
    color: #ebf1f7;
    background-color: #fcfdfe;
    border-color: #fcfdfe;
  }
  .btn-soft-white {
    color: #fff;
    background: #fff;
    border-color: #fff;
  }
  .btn-soft-white:hover {
    color: #fff;
    background: #fafafa;
    border-color: #fafafa;
  }
  .btn-check:focus + .btn-soft-white,
  .btn-soft-white:focus {
    color: #fff;
    background-color: #fafafa;
    border-color: #fafafa;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .btn-check:active + .btn-soft-white,
  .btn-check:checked + .btn-soft-white,
  .btn-soft-white.active,
  .btn-soft-white:active,
  .show > .btn-soft-white.dropdown-toggle {
    color: #fff;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
  }
  .btn-check:active + .btn-soft-white:focus,
  .btn-check:checked + .btn-soft-white:focus,
  .btn-soft-white.active:focus,
  .btn-soft-white:active:focus,
  .show > .btn-soft-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  .btn-soft-white.disabled,
  .btn-soft-white:disabled {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-primary {
    color: #fff;
    background-color: #1e71a1;
    border-color: #1e71a1;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #18577c;
    border-color: #18577c;
  }
  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    color: #fff;
    background-color: #18577c;
    border-color: #18577c;
    -webkit-box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
  }
  .btn-check:active + .btn-primary,
  .btn-check:checked + .btn-primary,
  .btn-primary.active,
  .btn-primary:active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a6ddf;
    border-color: #0a6ddf;
  }
  .btn-check:active + .btn-primary:focus,
  .btn-check:checked + .btn-primary:focus,
  .btn-primary.active:focus,
  .btn-primary:active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 153, 247, 0.5);
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #2787f5;
    border-color: #2787f5;
  }
  .btn-secondary {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-secondary:hover {
    color: #95aac9;
    background-color: #c7d8e8;
    border-color: #c7d8e8;
  }
  .btn-check:focus + .btn-secondary,
  .btn-secondary:focus {
    color: #95aac9;
    background-color: #c7d8e8;
    border-color: #c7d8e8;
    -webkit-box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
  }
  .btn-check:active + .btn-secondary,
  .btn-check:checked + .btn-secondary,
  .btn-secondary.active,
  .btn-secondary:active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #b4cbe1;
    border-color: #b4cbe1;
  }
  .btn-check:active + .btn-secondary:focus,
  .btn-check:checked + .btn-secondary:focus,
  .btn-secondary.active:focus,
  .btn-secondary:active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(207, 219, 234, 0.5);
  }
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-success {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #e89d79;
    border-color: #e89d79;
  }
  .btn-check:focus + .btn-success,
  .btn-success:focus {
    color: #fff;
    background-color: #e89d79;
    border-color: #e89d79;
    -webkit-box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
  }
  .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #e58d63;
    border-color: #e58d63;
  }
  .btn-check:active + .btn-success:focus,
  .btn-check:checked + .btn-success:focus,
  .btn-success.active:focus,
  .btn-success:active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 185, 160, 0.5);
  }
  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #ecad8f;
    border-color: #ecad8f;
  }
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #148ea1;
    border-color: #148ea1;
  }
  .btn-check:focus + .btn-info,
  .btn-info:focus {
    color: #fff;
    background-color: #148ea1;
    border-color: #148ea1;
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-check:active + .btn-info,
  .btn-check:checked + .btn-info,
  .btn-info.active,
  .btn-info:active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b;
  }
  .btn-check:active + .btn-info:focus,
  .btn-check:checked + .btn-info:focus,
  .btn-info.active:focus,
  .btn-info:active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-warning {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-warning:hover {
    color: #fff;
    background-color: #edb100;
    border-color: #edb100;
  }
  .btn-check:focus + .btn-warning,
  .btn-warning:focus {
    color: #fff;
    background-color: #edb100;
    border-color: #edb100;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
  }
  .btn-check:active + .btn-warning,
  .btn-check:checked + .btn-warning,
  .btn-warning.active,
  .btn-warning:active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d39e00;
    border-color: #d39e00;
  }
  .btn-check:active + .btn-warning:focus,
  .btn-check:checked + .btn-warning:focus,
  .btn-warning.active:focus,
  .btn-warning:active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 202, 44, 0.5);
  }
  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  .btn-danger {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-danger:hover {
    color: #fff;
    background-color: #fe4c5a;
    border-color: #fe4c5a;
  }
  .btn-check:focus + .btn-danger,
  .btn-danger:focus {
    color: #fff;
    background-color: #fe4c5a;
    border-color: #fe4c5a;
    -webkit-box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
  }
  .btn-check:active + .btn-danger,
  .btn-check:checked + .btn-danger,
  .btn-danger.active,
  .btn-danger:active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #fe3242;
    border-color: #fe3242;
  }
  .btn-check:active + .btn-danger:focus,
  .btn-check:checked + .btn-danger:focus,
  .btn-danger.active:focus,
  .btn-danger:active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
    box-shadow: 0 0 0 0 rgba(254, 124, 134, 0.5);
  }
  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #fe6571;
    border-color: #fe6571;
  }
  .btn-light {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-light:hover {
    color: #95aac9;
    background-color: #e4edf3;
    border-color: #e4edf3;
  }
  .btn-check:focus + .btn-light,
  .btn-light:focus {
    color: #95aac9;
    background-color: #e4edf3;
    border-color: #e4edf3;
    -webkit-box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
  }
  .btn-check:active + .btn-light,
  .btn-check:checked + .btn-light,
  .btn-light.active,
  .btn-light:active,
  .show > .btn-light.dropdown-toggle {
    color: #95aac9;
    background-color: #d3e1eb;
    border-color: #d3e1eb;
  }
  .btn-check:active + .btn-light:focus,
  .btn-check:checked + .btn-light:focus,
  .btn-light.active:focus,
  .btn-light:active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 237, 244, 0.5);
  }
  .btn-light.disabled,
  .btn-light:disabled {
    color: #95aac9;
    background-color: #f6f9fb;
    border-color: #f6f9fb;
  }
  .btn-dark {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-dark:hover {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
  }
  .btn-check:focus + .btn-dark,
  .btn-dark:focus {
    color: #95aac9;
    background-color: #d9e4f0;
    border-color: #d9e4f0;
    -webkit-box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
  }
  .btn-check:active + .btn-dark,
  .btn-check:checked + .btn-dark,
  .btn-dark.active,
  .btn-dark:active,
  .show > .btn-dark.dropdown-toggle {
    color: #95aac9;
    background-color: #c7d8e8;
    border-color: #c7d8e8;
  }
  .btn-check:active + .btn-dark:focus,
  .btn-check:checked + .btn-dark:focus,
  .btn-dark.active:focus,
  .btn-dark:active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(222, 230, 240, 0.5);
  }
  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #95aac9;
    background-color: #ebf1f7;
    border-color: #ebf1f7;
  }
  .btn-white {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-white:hover {
    color: #95aac9;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
  .btn-check:focus + .btn-white,
  .btn-white:focus {
    color: #95aac9;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    -webkit-box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
  }
  .btn-check:active + .btn-white,
  .btn-check:checked + .btn-white,
  .btn-white.active,
  .btn-white:active,
  .show > .btn-white.dropdown-toggle {
    color: #95aac9;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
  .btn-check:active + .btn-white:focus,
  .btn-check:checked + .btn-white:focus,
  .btn-white.active:focus,
  .btn-white:active:focus,
  .show > .btn-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
    box-shadow: 0 0 0 0 rgba(239, 242, 247, 0.5);
  }
  .btn-white.disabled,
  .btn-white:disabled {
    color: #95aac9;
    background-color: #fff;
    border-color: #fff;
  }
  .btn-link:hover {
    opacity: 0.8;
  }
  .btn:not(.btn-icon) > svg {
    height: 1rem;
    width: 1rem;
  }
  .btn-icon {
    line-height: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    height: calc(1.6em + 1.1875rem + 4px);
    width: calc(1.6em + 1.1875rem + 4px);
  }
  .btn-icon > svg {
    height: 1rem;
    width: 1rem;
  }
  .btn-group-sm > .btn-icon.btn,
  .btn-icon.btn-sm {
    height: calc(1.6em + 0.75rem + 4px);
    width: calc(1.6em + 0.75rem + 4px);
  }
  .btn-group-sm > .btn-icon.btn > svg,
  .btn-icon.btn-sm > svg {
    height: 0.75rem;
    width: 0.75rem;
  }
  .btn-group-lg > .btn-icon.btn,
  .btn-icon.btn-lg {
    height: calc(1.6em + 1.75rem + 4px);
    width: calc(1.6em + 1.75rem + 4px);
  }
  .btn-group-lg > .btn-icon.btn > svg,
  .btn-icon.btn-lg > svg {
    height: 1.125rem;
    width: 1.125rem;
  }
  .card-shadow > .card-body,
  .card-shadow > .card-footer,
  .card-shadow > .card-header {
    position: relative;
    z-index: 1;
  }
  .card-shadow::after,
  .card-shadow::before {
    position: absolute;
    content: "";
  }
  .card-shadow::after {
    background: rgba(39, 135, 245, 0.1);
    -webkit-filter: blur(130.478px);
    filter: blur(130.478px);
    z-index: -1;
    top: 64px;
    left: 0;
    right: 0;
    bottom: -64px;
    border-radius: 48px;
  }
  .card-shadow::before {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.6rem;
    top: 8px;
    left: 10px;
    right: 10px;
    bottom: -8px;
  }
  .card-list .card + .card {
    margin-top: 1rem;
  }
  .profile {
    position: relative;
  }
  .profile-img {
    overflow: hidden;
  }
  .profile-body {
    text-align: center;
    padding-bottom: 1.25rem;
  }
  .profile .avatar {
    border: 4px solid #fff;
    margin-left: auto;
    margin-right: auto;
  }
  .profile .avatar.avatar {
    margin-top: -22px;
    margin-bottom: 4.4px;
  }
  .profile .avatar.avatar-xl {
    margin-top: -41px;
    margin-bottom: 8.2px;
  }
  .profile .avatar.avatar-lg {
    margin-top: -34px;
    margin-bottom: 6.8px;
  }
  .profile .avatar.avatar-sm {
    margin-top: -17px;
    margin-bottom: 3.4px;
  }
  .profile .avatar.avatar-xs {
    margin-top: -13px;
    margin-bottom: 2.6px;
  }
  .nav-pills {
    background: #ebf1f7;
    border-radius: 0.6rem;
    padding: 0.3125rem;
  }
  .nav-pills .nav-link {
    padding: 0.75rem;
    color: #95aac9;
  }
  .nav-pills .nav-link.active {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  body {
    letter-spacing: -0.003125rem;
  }
  a {
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    text-decoration: none;
  }
  .h1 > a,
  .h2 > a,
  .h3 > a,
  .h4 > a,
  .h5 > a,
  .h6 > a,
  h1 > a,
  h2 > a,
  h3 > a,
  h4 > a,
  h5 > a,
  h6 > a {
    color: #293951;
  }
  a.text-body:hover {
    opacity: 0.8;
  }
  a.text-muted:hover {
    opacity: 0.8;
  }
  .text-primary .h1,
  .text-primary .h2,
  .text-primary .h3,
  .text-primary .h4,
  .text-primary .h5,
  .text-primary .h6,
  .text-primary h1,
  .text-primary h2,
  .text-primary h3,
  .text-primary h4,
  .text-primary h5,
  .text-primary h6 {
    color: #2787f5;
  }
  .text-secondary .h1,
  .text-secondary .h2,
  .text-secondary .h3,
  .text-secondary .h4,
  .text-secondary .h5,
  .text-secondary .h6,
  .text-secondary h1,
  .text-secondary h2,
  .text-secondary h3,
  .text-secondary h4,
  .text-secondary h5,
  .text-secondary h6 {
    color: #d9e4f0;
  }
  .text-success .h1,
  .text-success .h2,
  .text-success .h3,
  .text-success .h4,
  .text-success .h5,
  .text-success .h6,
  .text-success h1,
  .text-success h2,
  .text-success h3,
  .text-success h4,
  .text-success h5,
  .text-success h6 {
    color: #ecad8f;
  }
  .text-info .h1,
  .text-info .h2,
  .text-info .h3,
  .text-info .h4,
  .text-info .h5,
  .text-info .h6,
  .text-info h1,
  .text-info h2,
  .text-info h3,
  .text-info h4,
  .text-info h5,
  .text-info h6 {
    color: #17a2b8;
  }
  .text-warning .h1,
  .text-warning .h2,
  .text-warning .h3,
  .text-warning .h4,
  .text-warning .h5,
  .text-warning .h6,
  .text-warning h1,
  .text-warning h2,
  .text-warning h3,
  .text-warning h4,
  .text-warning h5,
  .text-warning h6 {
    color: #ffc107;
  }
  .text-danger .h1,
  .text-danger .h2,
  .text-danger .h3,
  .text-danger .h4,
  .text-danger .h5,
  .text-danger .h6,
  .text-danger h1,
  .text-danger h2,
  .text-danger h3,
  .text-danger h4,
  .text-danger h5,
  .text-danger h6 {
    color: #fe6571;
  }
  .text-light .h1,
  .text-light .h2,
  .text-light .h3,
  .text-light .h4,
  .text-light .h5,
  .text-light .h6,
  .text-light h1,
  .text-light h2,
  .text-light h3,
  .text-light h4,
  .text-light h5,
  .text-light h6 {
    color: #f6f9fb;
  }
  .text-dark .h1,
  .text-dark .h2,
  .text-dark .h3,
  .text-dark .h4,
  .text-dark .h5,
  .text-dark .h6,
  .text-dark h1,
  .text-dark h2,
  .text-dark h3,
  .text-dark h4,
  .text-dark h5,
  .text-dark h6 {
    color: #ebf1f7;
  }
  .text-white .h1,
  .text-white .h2,
  .text-white .h3,
  .text-white .h4,
  .text-white .h5,
  .text-white .h6,
  .text-white h1,
  .text-white h2,
  .text-white h3,
  .text-white h4,
  .text-white h5,
  .text-white h6 {
    color: #fff;
  }
  .text-dark .h1,
  .text-dark .h2,
  .text-dark .h3,
  .text-dark .h4,
  .text-dark .h5,
  .text-dark .h6,
  .text-dark h1,
  .text-dark h2,
  .text-dark h3,
  .text-dark h4,
  .text-dark h5,
  .text-dark h6 {
    color: #293951;
  }
  .text-dark {
    color: #293951 !important;
  }
  blockquote:last-child,
  ol:last-child,
  p:last-child,
  ul:last-child {
    margin-bottom: 0;
  }
  .h5,
  .h6,
  h5,
  h6 {
    margin-bottom: 0.125rem;
  }
  .extra-small {
    font-size: 0.665em;
  }
  hr.hr-bold:not([size]) {
    height: 1rem;
  }
  .blockquote {
    border-left: 2px solid currentColor;
    padding: 0 1rem;
  }
  .blockquote-footer {
    margin-top: 0;
  }
  .blockquote-footer:before {
    content: "";
  }
  .typing-dots span {
    opacity: 0;
  }
  .typing-dots span:nth-child(1) {
    -webkit-animation: 1s type-animation infinite 0.33333s;
    animation: 1s type-animation infinite 0.33333s;
  }
  .typing-dots span:nth-child(2) {
    -webkit-animation: 1s type-animation infinite 0.66666s;
    animation: 1s type-animation infinite 0.66666s;
  }
  .typing-dots span:nth-child(3) {
    -webkit-animation: 1s type-animation infinite 0.99999s;
    animation: 1s type-animation infinite 0.99999s;
  }
  @-webkit-keyframes type-animation {
    50% {
      opacity: 1;
    }
  }
  @keyframes type-animation {
    50% {
      opacity: 1;
    }
  }
  .stretched-label {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  .form-switch .form-check-input {
    margin-left: 0;
  }
  .form-control::-webkit-input-placeholder {
    color: #95aac9;
  }
  .form-control::-moz-placeholder {
    color: #95aac9;
  }
  .form-control:-ms-input-placeholder {
    color: #95aac9;
  }
  .form-control::-ms-input-placeholder {
    color: #95aac9;
  }
  .form-control::placeholder {
    color: #95aac9;
  }
  .form-control:-ms-input-placeholder {
    color: #95aac9;
  }
  .form-floating label {
    color: #95aac9;
  }
  :focus {
    outline: 0;
  }
  ::-moz-focus-inner {
    border: 0;
  }
  .dropdown-menu {
    -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05);
    padding: 0.5rem;
  }
  .dropdown-item {
    border-radius: 0.6rem;
  }
  .toast {
    border: 0;
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
    display: block;
    max-width: 100%;
    width: auto;
  }
  .toast-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0.75rem 1.5rem;
    width: auto;
  }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
  @media (min-width: 1200px) {
    .toast {
      max-width: 352px;
    }
  }
  .list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
  .list-group-flush.list-group-no-gutters .list-group-item:last-child {
    padding-bottom: 0;
  }
  .list-group-flush.list-group-no-gutters .list-group-item:first-child {
    padding-top: 0;
  }
  .modal-gx {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .modal-gy {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .modal-gx-n {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .modal-px {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-py {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .modal-content {
    -webkit-box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.1);
  }
  .modal-header .btn-close {
    margin: -1.2em;
  }
  .offcanvas-aside {
    z-index: 1050;
    -webkit-transition-property: all;
    transition-property: all;
    width: 100%;
  }
  .offcanvas-footer {
    padding: 1.5rem 1.5rem;
  }
  hr.offcanvas-divider {
    background: #f5f8fb;
    height: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .offcanvas-no-gap {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  @media (min-width: 1200px) {
    .offcanvas-aside {
      position: static;
      -webkit-transition: none;
      transition: none;
      width: 0;
    }
    .offcanvas-aside.offcanvas-end {
      border-left: 2px solid #f5f8fb;
    }
    .offcanvas-aside.offcanvas-start {
      border-right: 2px solid #f5f8fb;
    }
    .offcanvas-aside.show {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      min-width: 400px;
    }
  }
  .btn-close.btn-close-arrow {
    background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%2395aac9' stroke-opacity='{%23$btn-close-opacity}' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3e%3cpolyline points='15 18 9 12 15 6'%3e%3c/polyline%3e%3c/svg%3e")
    center/1.2em auto no-repeat;
  }
  .placeholder {
    border-radius: 0.2rem;
  }
  .placeholder-img {
    cursor: wait;
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
    opacity: 0.5;
    fill: currentColor;
  }
  .layout,
  body,
  html {
    overflow-x: hidden;
    height: 100%;
  }
  .layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navigation {
    border-top: 2px solid #f5f8fb;
    padding: 0 1.25rem;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    min-height: 58px;
  }
  .main,
  .sidebar {
    overflow: hidden;
  }
  .main {
    position: relative;
    background: #fff;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .sidebar {
    width: 100%;
    height: -webkit-fill-available;
    height: calc(100vh - 58px);
  }
  .chat-footer {
    width: calc(100% + (2rem));
    margin-left: -1rem;
    z-index: 1;
    padding-left: 20px;
    padding-right: 20px;
  }
  .chat-footer::before {
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
    display: block;
    height: 3.9375rem;
    width: calc(100% + 0.5rem);
    left: -0.25rem;
    bottom: 0;
    content: "";
  }
  @-moz-document url-prefix() {
    .chat-footer::before {
      background: #fff;
    }
  }
  .chat-form {
    position: relative;
    padding: 0.5rem;
  }
  @media (min-width: 1200px) {
    .main,
    .navigation,
    .sidebar {
      height: 100vh;
    }
  }
  @media (min-width: 1200px) {
    .layout {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navigation {
      width: 100px;
      padding: 1.5rem 0;
      border: 0;
    }
    .sidebar {
      width: 410px;
    }
  }
  @media (max-width: 1199.98px) {
    .sidebar {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .navigation {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .main {
      position: fixed;
      background: #fff;
      top: 0;
      left: 0;
      bottom: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      transition: visibility 0.4s ease, -webkit-transform 0.4s ease, transform 0.4s ease;
      visibility: hidden;
      z-index: 3;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      height: 100%;
      width: 100%;
    }
    .main.is-visible {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible;
    }
  }
  .avatar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .avatar .avatar-img {
    max-width: 100%;
    height: auto;
  }
  .avatar,
  .avatar .avatar-img,
  .avatar .avatar-text {
    border-radius: 50%;
  }
  .avatar .avatar-text {
    background: #2787f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    line-height: 0;
    height: 100%;
    width: 100%;
  }
  .avatar:hover .avatar-text {
    background: #1d82f5;
  }
  .avatar {
    height: 54px;
    width: 54px;
  }
  .avatar > .avatar-text {
    font-size: 15.1724137931px;
  }
  .avatar-group .avatar + .avatar {
    margin-left: -17.6px;
  }
  .avatar-xl {
    height: 82px;
    width: 82px;
  }
  .avatar-xl > .avatar-text {
    font-size: 28.275862069px;
  }
  .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -32.8px;
  }
  .avatar-lg {
    height: 68px;
    width: 68px;
  }
  .avatar-lg > .avatar-text {
    font-size: 23.4482758621px;
  }
  .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -27.2px;
  }
  .avatar-sm {
    height: 34px;
    width: 34px;
  }
  .avatar-sm > .avatar-text {
    font-size: 11.724137931px;
  }
  .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -13.6px;
  }
  .avatar-xs {
    height: 26px;
    width: 26px;
  }
  .avatar-xs > .avatar-text {
    font-size: 8.9655172414px;
  }
  .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -10.4px;
  }
  .avatar-text > svg {
    height: 1em;
    width: 1em;
  }
  .avatar-offline .avatar-img,
  .avatar-offline .avatar-text,
  .avatar-online .avatar-img,
  .avatar-online .avatar-text {
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0NiA0NiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDYgNDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZmlsbD0iI0ZGMDAwMCIgZD0iTTM5LjUsMTNjLTMuNTg5ODQzOCwwLTYuNS0yLjkxMDE1NjMtNi41LTYuNVMzNS45MTAxNTYzLDAsMzkuNSwwSDB2NDZoNDZWNi41DQoJCUM0NiwxMC4wODk4NDM4LDQzLjA4OTg0MzgsMTMsMzkuNSwxM3oiLz4NCgk8cGF0aCBmaWxsPSIjRkYwMDAwIiBkPSJNMzkuNSwwQzQzLjA4OTg0MzgsMCw0NiwyLjkxMDE1NjMsNDYsNi41VjBIMzkuNXoiLz4NCjwvZz4NCjwvc3ZnPg0K);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0NiA0NiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDYgNDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZmlsbD0iI0ZGMDAwMCIgZD0iTTM5LjUsMTNjLTMuNTg5ODQzOCwwLTYuNS0yLjkxMDE1NjMtNi41LTYuNVMzNS45MTAxNTYzLDAsMzkuNSwwSDB2NDZoNDZWNi41DQoJCUM0NiwxMC4wODk4NDM4LDQzLjA4OTg0MzgsMTMsMzkuNSwxM3oiLz4NCgk8cGF0aCBmaWxsPSIjRkYwMDAwIiBkPSJNMzkuNSwwQzQzLjA4OTg0MzgsMCw0NiwyLjkxMDE1NjMsNDYsNi41VjBIMzkuNXoiLz4NCjwvZz4NCjwvc3ZnPg0K);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .avatar-offline::before,
  .avatar-online::before {
    position: absolute;
    border-radius: 50%;
    display: block;
    content: "";
    height: 18%;
    width: 18%;
    top: 5%;
    right: 5%;
  }
  .avatar-online::before {
    background: #ffc107;
  }
  .avatar-offline::before {
    background: #adb5bd;
  }
  .avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNNTQsNTBDNTQsMjcuODAyNDI5Miw2Ny4xNTc0NzA3LDguNjg5NzU4Myw4Ni4wOTIxNjMxLDBIMHYxMDBoODYuMDkyMTYzMUM2Ny4xNTc0NzA3LDkxLjMxMDI0MTcsNTQsNzIuMTk3NTcwOCw1NCw1MHoiLz4NCjwvc3ZnPg0K);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNNTQsNTBDNTQsMjcuODAyNDI5Miw2Ny4xNTc0NzA3LDguNjg5NzU4Myw4Ni4wOTIxNjMxLDBIMHYxMDBoODYuMDkyMTYzMUM2Ny4xNTc0NzA3LDkxLjMxMDI0MTcsNTQsNzIuMTk3NTcwOCw1NCw1MHoiLz4NCjwvc3ZnPg0K);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .avatar-group-trigon {
    position: relative;
    height: 71.0776px;
    width: 71.0776px;
  }
  .avatar-group-trigon .avatar {
    position: absolute;
  }
  .avatar-group-trigon .avatar:nth-child(1) {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDI2IDI2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNiAyNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2Rpc3BsYXk6bm9uZTt9DQoJLnN0MXtmaWxsOiMwMDAwRkY7fQ0KPC9zdHlsZT4NCjxnIGlkPSLQodC70L7QuV8yIj4NCjwvZz4NCjxnIGlkPSLQodC70L7QuV8xIj4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNC41LDEzLjM5OTk5OTZjMy4wOTk5OTk5LDAsNiwxLjEwMDAwMDQsOC4zMDAwMDAyLDNjMi4xOTk5OTk4LTEuODAwMDAwMiw1LjA5OTk5OTQtMyw4LjMwMDAwMDItMw0KCQljMS43OTk5OTkyLDAsMy41LDAuMzk5OTk5Niw1LDFWLTFIMHYxNS4xOTk5OTk4QzEuNCwxMy42OTk5OTk4LDIuOTAwMDAwMSwxMy4zOTk5OTk2LDQuNSwxMy4zOTk5OTk2eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik01LDE0YzMuMDIwMzg1NywwLDUuNzkyOTA3NywxLjAzOTMwNjYsOCwyLjc2NzI3MjlDMTUuMjA3MDkyMywxNS4wMzkzMDY2LDE3Ljk3OTYxNDMsMTQsMjEsMTQNCgkJYzEuNzcyMzM4OSwwLDMuNDYwNDQ5MiwwLjM1NzM2MDgsNSwwLjk5OTU3MjhWMEgwdjE0Ljk5OTU3MjhDMS41Mzk1NTA4LDE0LjM1NzM2MDgsMy4yMjc2NjExLDE0LDUsMTR6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDI2IDI2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNiAyNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2Rpc3BsYXk6bm9uZTt9DQoJLnN0MXtmaWxsOiMwMDAwRkY7fQ0KPC9zdHlsZT4NCjxnIGlkPSLQodC70L7QuV8yIj4NCjwvZz4NCjxnIGlkPSLQodC70L7QuV8xIj4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNC41LDEzLjM5OTk5OTZjMy4wOTk5OTk5LDAsNiwxLjEwMDAwMDQsOC4zMDAwMDAyLDNjMi4xOTk5OTk4LTEuODAwMDAwMiw1LjA5OTk5OTQtMyw4LjMwMDAwMDItMw0KCQljMS43OTk5OTkyLDAsMy41LDAuMzk5OTk5Niw1LDFWLTFIMHYxNS4xOTk5OTk4QzEuNCwxMy42OTk5OTk4LDIuOTAwMDAwMSwxMy4zOTk5OTk2LDQuNSwxMy4zOTk5OTk2eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik01LDE0YzMuMDIwMzg1NywwLDUuNzkyOTA3NywxLjAzOTMwNjYsOCwyLjc2NzI3MjlDMTUuMjA3MDkyMywxNS4wMzkzMDY2LDE3Ljk3OTYxNDMsMTQsMjEsMTQNCgkJYzEuNzcyMzM4OSwwLDMuNDYwNDQ5MiwwLjM1NzM2MDgsNSwwLjk5OTU3MjhWMEgwdjE0Ljk5OTU3MjhDMS41Mzk1NTA4LDE0LjM1NzM2MDgsMy4yMjc2NjExLDE0LDUsMTR6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .avatar-group-trigon .avatar:nth-child(2) {
    bottom: 0;
    left: 0;
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNiAyNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjYgMjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0xNC4xLDEzYzAtNS45LDMuNC0xMC45LDguMi0xM0gwdjI2aDIyLjNDMTcuNSwyMy45LDE0LjEsMTguOSwxNC4xLDEzeiIvPg0KPC9zdmc+DQo=);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNiAyNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjYgMjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0xNC4xLDEzYzAtNS45LDMuNC0xMC45LDguMi0xM0gwdjI2aDIyLjNDMTcuNSwyMy45LDE0LjEsMTguOSwxNC4xLDEzeiIvPg0KPC9zdmc+DQo=);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .avatar-group-trigon .avatar:nth-child(3) {
    bottom: 0;
    right: 0;
  }
  .avatar-group-trigon-xs {
    height: 42.0004px;
    width: 42.0004px;
  }
  .avatar-group-trigon-sm {
    height: 54.9236px;
    width: 54.9236px;
  }
  .avatar-group-trigon-lg {
    height: 109.8472px;
    width: 109.8472px;
  }
  .avatar-group-trigon-xl {
    height: 132.4628px;
    width: 132.4628px;
  }
  .avatar-responsive.avatar,
  .avatar-responsive.avatar.avatar-lg,
  .avatar-responsive.avatar.avatar-sm,
  .avatar-responsive.avatar.avatar-xl,
  .avatar-responsive.avatar.avatar-xs {
    height: 26px;
    width: 26px;
  }
  @media (min-width: 576px) {
    .avatar-responsive.avatar,
    .avatar-responsive.avatar.avatar-lg,
    .avatar-responsive.avatar.avatar-sm,
    .avatar-responsive.avatar.avatar-xl {
      height: 34px;
      width: 34px;
    }
  }
  @media (min-width: 768px) {
    .avatar-responsive.avatar,
    .avatar-responsive.avatar.avatar-lg,
    .avatar-responsive.avatar.avatar-xl {
      height: 44px;
      width: 44px;
    }
  }
  @media (min-width: 992px) {
    .avatar-responsive.avatar.avatar-lg,
    .avatar-responsive.avatar.avatar-xl {
      height: 68px;
      width: 68px;
    }
  }
  @media (min-width: 1200px) {
    .avatar-responsive.avatar.avatar-xl {
      height: 82px;
      width: 82px;
    }
  }
  .icon {
    display: inline-block;
    line-height: 1;
  }
  .icon.icon-badged {
    position: relative;
  }
  .icon.icon-badged > svg {
    -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik03LDAuNUM3LDAuMyw3LDAuMiw3LDBIMHYxNGgxNFY3Yy0wLjIsMC0wLjMsMC0wLjUsMEM5LjksNyw3LDQuMSw3LDAuNXoiLz4NCjwvc3ZnPg0K);
    mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i0KHQu9C+0LlfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik03LDAuNUM3LDAuMyw3LDAuMiw3LDBIMHYxNGgxNFY3Yy0wLjIsMC0wLjMsMC0wLjUsMEM5LjksNyw3LDQuMSw3LDAuNXoiLz4NCjwvc3ZnPg0K);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
  .icon.icon-badged .badge {
    position: absolute;
    bottom: 60%;
    left: 60%;
  }
  .icon > svg {
    height: 1rem;
    width: 1rem;
  }
  .icon-xl > svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .icon-lg > svg {
    height: 1.125rem;
    width: 1.125rem;
  }
  .icon-sm > svg {
    height: 0.875rem;
    width: 0.875rem;
  }
  .icon-xs > svg {
    height: 0.75rem;
    width: 0.75rem;
  }
  .message + .message {
    margin-top: 1rem;
  }
  @media (min-width: 992px) {
    .message + .message {
      margin-top: 1.5rem;
    }
  }
  .message-divider {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  @media (min-width: 992px) {
    .message-divider {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .message.message-out {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .message-inner {
    margin-left: 0.5rem;
  }
  @media (min-width: 768px) {
    .message-inner {
      margin-left: 1rem;
    }
  }
  .message-out .message-inner {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  @media (min-width: 768px) {
    .message-out .message-inner {
      margin-right: 1rem;
    }
  }
  .message-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .message-content + .message-content {
    margin-top: 0.75rem;
  }
  .message-out .message-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .message-gallery,
  .message-text {
    overflow: hidden;
    margin-right: 0.25rem;
  }
  .message-out .message-gallery,
  .message-out .message-text {
    margin-left: 0.25rem;
    margin-right: 0;
  }
  .message-text {
    background: #e8ebef;
    border-radius: 6px 6px 6px 0px;
    padding: 1rem 1.25rem;
    color: #333c49;
    padding-bottom: 6px;
  }
  .message-out .message-text {
    background: #1e71a1;
    border-radius: 6px 6px 0px 6px;
    color: #fff;
  }

  .message-footer {
    line-height: 1.3;
    margin-top: 0.25rem;
  }
  .message-out .message-footer {
    text-align: right;
  }
  .message-inner {
    max-width: 100%;
    min-width: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  @media (min-width: 992px) {
    .message-inner {
      max-width: 70%;
    }
  }
  @media (min-width: 1200px) {
    .message-inner {
      max-width: 50%;
    }
  }
  @media (min-width: 1440px) {
    .message-inner {
      max-width: 45%;
    }
  }
  .stretched-link-up {
    z-index: 2;
  }
  .border-outline {
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
  }
  .border-outline.border-1 {
    -webkit-box-shadow: 0 0 0 1px #fff !important;
    box-shadow: 0 0 0 1px #fff !important;
  }
  .border-outline.border-2 {
    -webkit-box-shadow: 0 0 0 2px #fff !important;
    box-shadow: 0 0 0 2px #fff !important;
  }
  .border-outline.border-3 {
    -webkit-box-shadow: 0 0 0 3px #fff !important;
    box-shadow: 0 0 0 3px #fff !important;
  }
  .border-outline.border-4 {
    -webkit-box-shadow: 0 0 0 4px #fff !important;
    box-shadow: 0 0 0 4px #fff !important;
  }
  .border-outline.border-5 {
    -webkit-box-shadow: 0 0 0 5px #fff !important;
    box-shadow: 0 0 0 5px #fff !important;
  }
  .border-outline.border-primary {
    -webkit-box-shadow: 0 0 0 2px #2787f5 !important;
    box-shadow: 0 0 0 2px #2787f5 !important;
  }
  .border-outline.border-secondary {
    -webkit-box-shadow: 0 0 0 2px #d9e4f0 !important;
    box-shadow: 0 0 0 2px #d9e4f0 !important;
  }
  .border-outline.border-success {
    -webkit-box-shadow: 0 0 0 2px #ecad8f !important;
    box-shadow: 0 0 0 2px #ecad8f !important;
  }
  .border-outline.border-info {
    -webkit-box-shadow: 0 0 0 2px #17a2b8 !important;
    box-shadow: 0 0 0 2px #17a2b8 !important;
  }
  .border-outline.border-warning {
    -webkit-box-shadow: 0 0 0 2px #ffc107 !important;
    box-shadow: 0 0 0 2px #ffc107 !important;
  }
  .border-outline.border-danger {
    -webkit-box-shadow: 0 0 0 2px #fe6571 !important;
    box-shadow: 0 0 0 2px #fe6571 !important;
  }
  .border-outline.border-light {
    -webkit-box-shadow: 0 0 0 2px #f6f9fb !important;
    box-shadow: 0 0 0 2px #f6f9fb !important;
  }
  .border-outline.border-dark {
    -webkit-box-shadow: 0 0 0 2px #ebf1f7 !important;
    box-shadow: 0 0 0 2px #ebf1f7 !important;
  }
  .border-outline.border-white {
    -webkit-box-shadow: 0 0 0 2px #fff !important;
    box-shadow: 0 0 0 2px #fff !important;
  }
  .flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .hide-empty:empty {
    display: none;
  }
  [data-horizontal-scroll] {
    overflow-x: scroll !important;
  }
  [data-horizontal-scroll] {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
  }
  [data-horizontal-scroll]::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .emoji-picker {
    width: calc(var(--emoji-per-row) * var(--emoji-size) + (0.75rem * var(--emoji-per-row)) + (0.75rem * 2) + (var(--emoji-per-row) * 1px) + (2px * 2));
    overflow: hidden;
    background: #fff;
    background: 0 0;
    padding: 0 0.75rem;
    border-radius: 0.6rem;
    -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.05);
    position: relative;
  }
  .emoji-picker:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
    content: "";
    display: block;
  }
  @-moz-document url-prefix() {
    .emoji-picker::before {
      background: #fff;
    }
  }
  .emoji-picker__variant-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .emoji-picker__variant-popup {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 2px solid #f5f8fb;
    padding: 0.75rem;
    margin-top: 0.75rem;
  }
  .emoji-picker {
    position: relative;
    margin-bottom: 1.5rem;
  }
  .emoji-picker__emoji {
    background: 0 0;
    border: none;
    outline: 0 !important;
    cursor: pointer;
    overflow: hidden;
    font-size: var(--emoji-size);
    width: calc(var(--emoji-size) + 0.75rem);
    height: calc(var(--emoji-size) + 0.75rem);
    padding: 0;
    margin: 0 1px 0 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0.6rem;
  }
  .emoji-picker__emoji:focus,
  .emoji-picker__emoji:hover {
    background: #dfedfe;
  }
  .emoji-picker__category-name {
    font-weight: 400;
    font-size: 0.875em;
    color: #bfccdf;
    padding: 12px 0;
    margin: 0;
  }
  .emoji-picker__emojis {
    height: calc(var(--row-count) * var(--emoji-size) + (0.75rem * var(--row-count)));
    overflow-y: auto;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .emoji-picker__emojis {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .emoji-picker__emojis::-webkit-scrollbar {
    display: none;
  }
  .emoji-picker__preview {
    height: calc(var(--emoji-size) * 2 + (0.75rem * 2));
    padding: 0.75rem 0;
    border-top: 2px solid #f5f8fb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .emoji-picker__preview .emoji-picker__preview-emoji {
    font-size: calc(var(--emoji-size) * 1.4);
    margin-right: 0.5rem;
  }
  .emoji-picker__preview .emoji-picker__preview-name {
    text-transform: capitalize;
    font-size: 0.875em;
    color: #bfccdf;
    display: none;
  }
  .emoji-picker__emoji img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 0.05em 0 0.1em;
    vertical-align: -0.1em;
  }
  .emoji-picker__search-icon {
    display: none;
  }
  .emoji-picker__search-container {
    position: relative;
    border-bottom: 2px solid #f5f8fb;
    padding: 0.75rem 0;
  }
  .emoji-picker__search-not-found {
    padding: 0.75rem 0;
    text-align: center;
  }
  .emoji-picker__search-not-found .h2,
  .emoji-picker__search-not-found h2 {
    font-size: 15px;
    font-weight: 400;
    color: #95aac9;
  }
  .emoji-picker__search-not-found-icon {
    margin-bottom: 0.5rem;
  }
  .emoji-picker__category-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    border-bottom: 2px solid #f5f8fb;
    padding: 0.75rem 0;
  }
  button.emoji-picker__category-button {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background: 0 0;
    padding: 0;
    border: none;
    cursor: pointer;
    color: #95aac9;
    outline: 0;
  }
  button.emoji-picker__category-button.active {
    color: #2787f5;
  }
  img[data-action="zoom"] {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoom-img,
  .zoom-img-wrap {
    position: relative;
    z-index: 1055;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  img.zoom-img {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
  .zoom-overlay {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 1054;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: "alpha(opacity=0)";
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .zoom-overlay-open .zoom-overlay {
    filter: "alpha(opacity=100)";
    opacity: 1;
  }
  [data-dz-size] > strong {
    font-weight: 400;
  }
  .dropzone-image-preview {
    display: none;
  }
  .dz-image-preview .dropzone-file-preview {
    display: none;
  }
  .dz-image-preview .dropzone-image-preview {
    display: block;
  }
  .dz-preview {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .dz-preview.dz-preview-moved {
    margin-bottom: -1.9375rem;
  }
  .dz-preview.dz-preview-moved + .chat-form::before {
    content: "";
    display: block;
    height: 2px;
    background: #d9e4f0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .commom-shadows{
    box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  }

  .card-transform{
    transform: scale(1.0);
    cursor: pointer;
  }

  .card-transform:hover{
    transform: scale(1.02);
  }

  .card-transform.active{
    transform: scale(1.05);
    cursor: pointer;
  }

  .title-card-left {
    color: #1e71a1 !important;
    font-size: 16px;
    font-weight: 700;
  }

  .header-title{
    font-weight: 700;
    color: #333c49;
  }

  .header-subtitle{
    color: #7c7d7f;
    font-size: 13px;
  }

  .message-text .message-footer .extra-small{
    float:right;
    color: rgba(124, 125, 127, 0.8) !important;
  }

  .message-out .message-text .message-footer .extra-small{
    color: rgba(255, 255, 255, 0.8) !important;
    float:right;
  }

  .avatar-min {
    height: 44px;
    width: 44px;
  }

  .light-shadow{
    box-shadow: 0 1px 1.5px rgba(0, 0, 0, .13);
  }

  .btn-select{
    color: #1e71a1 !important;
    border: 1px #1e71a1 solid !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    margin: 0 auto;
    background: rgba(30,113,161,0 ) !important;
    padding: 6px 10px;

    &:hover{
      color: #fff !important;
      background: rgba(30,113,161,0.80 ) !important;
    }

    &.active{
      color: #fff !important;
      background: rgba(30,113,161, 1 ) !important;
    }



  }



}