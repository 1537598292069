@keyframes icon-bubble {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.55;
  }
  75% {
    -webkit-transform: scale(1.32);
    transform: scale(1.32);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes giraffe {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.home-header-gray {
  height: 104px;
  position: fixed;
  width: 100vw;
  top: 0px;
  z-index: 25;
}

#home-quemsomos {
  margin-top: 0px;
  position: relative;
  padding-bottom: 200px;
}

.home-f-section {
  margin-top: 0px;
}

.home-border-header {
  position: absolute;
  width: 100%;
  height: 6px;
  left: 0px;
  top: 99px;
  background: #fe0000;
  z-index: 11;
}

.home-div-logo {
  position: absolute;
  width: 194px;
  height: auto;
  z-index: 10;
  left: 6px;
  top: -1px;
}

.home-img-logo {
  width: 194px;
}

.home-img-logo.img-logo-scrolled {
  width: 126px;
}

.nav-item.home {
  padding-left: 10px;
  padding-right: 10px;
}

.home-header-gray .col-menu-top a {
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}

.nav-item.home .dropdown {
  background: #0e6fa5;
  border-radius: 30px;
}

.nav-item.home .dropdown .btn.btn-primary {
  color: #fff !important;
  border: none;
  background-color: initial;
  text-decoration: none;
  padding: 10px 8px 10px 8px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.nav-item.home .dropdown .btn.btn-primary:after{
  display: none;
}

.nav-item.home .dropdown .drop-link {
  color: #fff !important;
}

.nav-item.home .nav-link.btn-log {
  background: #ff0e21;
  color: #fff !important;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.home-header-gray.scroled-64 {
  background: #e8ebef !important;
  box-shadow: 0px 0px 5px 5px rgba(23,25,27,0.21);
}

.home-banner-row {
  position: relative;
  padding-top: 180px;
  z-index: 10;
}

.home-blue-title {
  position: relative;
  background: #0f73ae;
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  border: 4px #fd0000 solid;
  text-align: right;
  color: #fff;
  padding: 4px;
  left: -6px;
}

.home-col-no-pleft {
  padding-left: 0px;
}

.home-col-no-pright {
  padding-right: 0px;
}

.home-img-top {
  position: absolute;
  right: 0;
  top: 0;
  width: 52%;
  max-width: 800px;
}

.home-img-top img {
  position: absolute;
  right: 0px;
  top: 0px;
}

#home-quemsomos p {
  text-align: justify;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 20px;
  line-height: 30px;
  font-size: 18px;
}

.col-right-header {
  background: none;
}

#home-quemsomos p span {
  font-weight: bold;
}

.home-col-no-pleft .wrapper-btns {
  width: 100%;
  margin-top: 50px;
}

.home-col-no-pleft .wrapper-btns .btn-red {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
}

.home-col-no-pleft .wrapper-btns button {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.home-col-no-pleft .wrapper-btns button:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.home-col-no-pleft .wrapper-btns button:after {
  display: none
}

.home-col-no-pleft .wrapper-btns button:focus{
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}


.home-col-no-pleft .wrapper-btns .btn-red:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.home-col-no-pleft .wrapper-btns .btn-white {
  background: rgba(255,14,33,0);
  color: #2c3e50 !important;
  border: 1px rgba(255,14,33,1) solid;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
}

.home-col-no-pleft .wrapper-btns .btn-white:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.video-ply-btn {
  position: relative;
  z-index: 9;
}

.video-ply-btn {
  padding-top: 15%;
  padding-left: 174px;
}

.video-ply-btn a {
  display: inline-block;
  width: 140px;
  height: 140px;
  line-height: 140px;
  position: relative;
  z-index: 9;
  border-radius: 50%;
  text-align: center;
  font-size: 28px;
  color: #ff0e21;
  cursor: pointer;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(72, 88, 238, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(72, 88, 238, 0.2);
}

.video-ply-btn a:hover {
  background-color: #ff0e21;
  color: #fff;
}

.video-ply-btn a:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140px;
  width: 140px;
  border: 3px solid #ff0e21;
  border-radius: 50%;
  -webkit-animation: icon-bubble 1s infinite forwards linear;
  animation: icon-bubble 1s infinite forwards linear;
}

.video-ply-btn a:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 140px;
  width: 140px;
  border: 3px solid #ff0e21;
  border-radius: 50%;
  -webkit-animation: icon-bubble 1s infinite forwards linear 0.5s;
  animation: icon-bubble 1s infinite forwards linear 0.5s;
}

.modal-video .modal-content{
  background-color: rgba(255,255,255,0);
}

#section-counter {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: bottom !important;
  position: relative;
  padding-top: 80px;
  padding-bottom: 120px;
}

#section-counter:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #181d46;
  opacity: .9;
}

.counter-single {
  position: relative;
}

.counter-single .icon-inner {
  background-color: #fff;
  width: 86px;
  height: 86px;
  line-height: 86px;
  border-radius: 50%;
  position: relative;
  margin: 19px auto 0;
  top: 20px;
}

.counter-single .icon:before {
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  width: 120px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.counter-single .icon:after {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  width: 105px;
  height: 105px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.counter-single .icon {
  position: relative;
  width: 126px;
  height: 126px;
  line-height: 126px;
  border-radius: 50%;
  margin: 0 auto;
}

.counter-single h2 {
  font-weight: 500;
  color: #f87115;
  margin: 30px 0 12px;
  font-size: 50px;
}

.counter-single p {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.counter-single .red-title {
  background: #ff0e21;
  color: #fff !important;
  border-radius: 10px;
  padding: 8px 10px 8px 10px;
  cursor: pointer;
  font-size: 20px;
  width: 140px;
  margin: 0 auto;
  text-align: center;
}

#section-middle {
  /*background: #fff;*/
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}

.home-coach-img {
  position: relative;
  width: 100%;
  top: 0px;
  left: -1px;
}

.home-wrapper-coach-txts {
  width: 90%;
}

.home-blue-right-title {
  position: relative;
  background: #0f73ae;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border: 4px #fd0000 solid;
  text-align: left;
  color: #fff;
  padding: 4px 4px 4px 8px;
  right: -18px;
}

.home-wrapper-coach-txts p {
  text-align: justify;
  padding-left: 32px;
  padding-right: 10px;
  padding-top: 20px;
  line-height: 30px;
  font-size: 18px;
}

.home-wrapper-coach-txts p .span-upper {
  text-transform: uppercase;
}

.home-wrapper-btn-red {
  padding-left: 30px;
}

.home-btn-red {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
}

.home-btn-red:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.home-btn-blue {
  background: rgba(14,111,165,1);
  border: 1px rgba(14,111,165,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
}

.home-btn-blue:hover {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
}

.home-btn-white-red {
  background: rgba(255,14,33,0);
  color: #2c3e50 !important;
  border: 1px rgba(255,14,33,1) solid;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
}

.home-btn-white-red:hover {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
}

.home-wrapper-sponsor-txts {
  width: 94%;
}

.home-wrapper-sponsor-txts p {
  text-align: justify;
  padding-left: 10px;
  padding-right: 32px;
  padding-top: 20px;
  line-height: 38px;
  font-size: 18px;
}

.home-wrapper-sponsor-txts p .span-upper {
  text-transform: uppercase;
}

.home-wrapper-bts-sponsor {
  padding-left: 0px;
  padding-right: 28px;
}

.home-red-left-title {
  position: relative;
  background: #ff0e21;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  border: 4px #0a5178 solid;
  text-align: right;
  color: #fff;
  padding: 4px 8px 4px 4px;
  left: -20px;
}

.home-sponsor-img {
  position: relative;
  width: 100%;
  top: 0px;
  right: -1px;
}

.home-sponsor-img img {
  position: relative;
  right: -1px;
}

#section-price {
  padding-bottom: 80px;
}

.home-pricing-top {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: top left !important;
  background-size: cover !important;
  padding: 160px 0 265px;
}

.home-pricing-top .top-bg {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  z-index: 9;
  background-blend-mode: multiply;
}

/* .home-pricing-top::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/White_bg.png);
  background-repeat: no-repeat !important;
} */

#section-price .main-title {
  position: relative;
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  font-weight: bold;
}

#section-price .main-title::before {
  content: ' ';
  position: absolute;
  width: 310px;
  height: 6px;
  background: #fff;
  border-radius: 3px;
  left: 0px;
  bottom: -2px;
}

#section-price .main-p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: justify;
  margin-top: 40px;
  margin-bottom: 80px;
}

.home-pricing-btm {
  position: relative;
  margin-top: -280px;
  padding: 0 0 76px;
  z-index: 12;
}

.home-pricing-tbl-single {
  position: relative;
  background-color: #fff;
  border: 1px solid #585273;
  border-radius: 20px;
  padding: 32px 34px 65px;
  transition: all 0.3s;
}

.home-pricing-tbl-single h4 {
  font-weight: 700;
  color: #0e6fa5;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  margin-top: 40px;
}

.home-pricing-tbl-single h3 {
  font-weight: bold;
  color: #f87115;
  text-align: center;
  font-size: 30px;
}

.home-pricing-tbl-single h3 span {
  font-size: 24px;
}

.home-price-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.home-price-details li {
  border-bottom: 2px dotted #585273;
  padding: 6px 20px 6px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.home-price-btn a {
  display: block;
  padding: 14px 0;
  border-radius: 30px;
  background-color: #1563f8;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(20, 50, 80, 0.40);
  box-shadow: 0px 5px 15px 0px rgba(20, 50, 80, 0.40);
  margin: 22px 0 -90px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
}

.home-price-btn a:hover {
  background-color: #0e6fa5;
}

.home-ribbon-price {
  background-color: #f87115;
  color: #fff;
  border-radius: 0 20px 20px 0;
  font-size: 19px;
  font-weight: 700;
  padding: 10px 15px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  top: 51px;
  right: -36px;
}

.home-price-stars {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 65px;
}

.home-price-stars img {
  margin: 0 auto;
  display: block;
}

.testimonial-section {
  padding-top: 10px;
  background: #fff;
}

.home-sec-heading h4 {
  color: #f87115;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 14px;
}

.home-heading h2 {
  font-weight: 700;
  margin-bottom: 20px;
}

.home-sec-heading h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.client-item-area {
  position: relative;
  padding-top: 170px;
  padding-bottom: 152px;
  background-image: -moz-linear-gradient( 140deg, rgb(25,18,72) 0%, rgb(111,0,247) 100%);
  background-image: -webkit-linear-gradient( 140deg, rgb(25,18,72) 0%, rgb(111,0,247) 100%);
  background-image: -ms-linear-gradient( 140deg, rgb(25,18,72) 0%, rgb(111,0,247) 100%);
  overflow: hidden;
}

.client-element-one {
  position: absolute;
  bottom: -32%;
  right: 18%;
  animation: rotate2 30s linear infinite;
}

.client-element-two {
  position: absolute;
  top: -12%;
  right: 8%;
  animation: giraffe 3s linear infinite;
}

.client-element-two img {
  width: 180px;
}

.client-element-three {
  position: absolute;
  bottom: 0;
  left: 12%;
  animation: giraffe 3s linear infinite;
}

.client-element-three img {
  width: 150px;
}

.client-icon-one {
  position: absolute;
  left: 10%;
  top: 10%;
}

.client-icon-two {
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.client-slider {
  overflow: hidden;
}

.client-thumb img {
  width: 100px;
  border-radius: 50%;
  border: 3px solid #fff;
}

.client-item .title {
  color: #fff;
  font-size: 24px;
  margin-top: 30px;
  font-weight: 600;
  line-height: 10px;
}

.client-item .sub-title {
  color: #fff;
}

.client-icon {
  margin-top: 20px;
}

.client-icon i {
  color: #f87115;
}

.client-item p {
  color: #000;
  font-size: 16px;
  width: 48%;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  border: 2px #012984 solid;
}

.swiper-pagination {
  position: relative;
  display: block;
  margin-top: 30px;
}

.swiper-pagination .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-notification {
  display: none;
}

.home-final-sep {
  width: 100%;
  height: 4px;
  background: #065985;
}

.home-wrapper-box-cats {
  width: 100%;
  padding-left: 22px;
  margin-top: 32px;
}

.home-box-cats {
  width: 110px;
  height: 110px;
  background: rgba(255,255,255,0.9);
  box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.15);
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
  transform: scale(1);
  margin-bottom: 10px;
}

.home-box-cats:hover {
  transform: scale(1.05);
}

.home-box-cats img {
  margin: 0 auto;
  display: block;
  width: 54px;
  height: 54px;
  margin-top: 10px;
}

.home-box-cats h3 {
  color: #0e6fa5;
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
  text-align: center;
}

.header-register{
  background: #e8ebef !important;
}

.home-bck-top{
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(14,111,165,0.80);
  color: #fff;
  font-weight: bold;
  text-align: center;
  z-index: 30;
  font-size: 20px;
  padding-top: 5px;
  cursor: pointer;
}


.section-mbl-quemsomos {
  width: 100%;
  margin-top: 64px;
  position: relative;
  height: auto;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding-bottom: 72px;
  display: none;
}

.section-mbl-quemsomos .fixed-image {
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: -1;
}

.section-mbl-quemsomos .titulo {
  color: #fff;
  font-size: 25px;
  /*border-bottom: 6px rgba(14,111,165,0.6) solid;*/
  text-align: center;
  font-weight: bold;
  position: relative;
}

.section-mbl-quemsomos .titulo .sep-titulo {
  position: relative;
  width: 160px;
  height: 6px;
  background: rgba(14,111,165,0.6);
  margin: 6px auto 6px auto;
  display: block;
}

.section-mbl-quemsomos .txt {
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  text-align: justify;
}

.section-mbl-quemsomos .btn-red:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.section-mbl-quemsomos .btn-red {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
}

.section-mbl-quemsomos .btn-white:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.section-mbl-quemsomos .btn-white {
  background: rgba(255,14,33,0);
  color: #fff!important;
  border: 1px rgba(255,255,255,1) solid;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
}

.nav-item.home .wapper-social {
  position: relative;
  transform: scale(0.8);
  top: 5px;
}

.nav-item.home .wapper-social h2 {
  position: absolute;
  top: -20px;
  color: #000000;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.nav-item.home .wapper-social .circle {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 26px;
  text-align: center;
  background: #000;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  padding: 2px 0px 0px 0px !important;
}

.nav-item.home .wapper-social .circle:last-child {
  margin-right: 0px;
}

.nav-item.home .wapper-social .circle.facebook {
  background: #0c43aa;
}

.nav-item.home .wapper-social .circle.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.nav-item.home .wapper-social .circle.twitter {
  background: #00aced;
}

.nav-item.home .wapper-social .circle.youtube {
  background: #ff0000;
}

.nav-item.home .wapper-social .circle.tiktok {
  background: #000;
}

.nav-item.home .wapper-social .circle i {
  position: relative;
  top: -2px;
  font-weight: normal;
}

.nav-item.home .wapper-social .circle svg {
  position: relative;
  top: -2px;
  font-weight: normal;
}

.home-price-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.home-price-details .h {
  color: #2b4661;
}

.home-price-details .nh {
  color: rgba(43,70,97,0.25);
}

.home-price-details .h i {
  color: green;
  position: absolute;
  right: 0px;
  top: 29%;
  font-size: 18px;
  font-weight: bold;
}

.home-price-details .nh i {
  color: rgba(43,70,97,0.25);
  position: absolute;
  right: 0px;
  top: 29%;
  font-size: 18px;
  font-weight: bold;
}

.home-price-details .h svg {
  color: green;
  position: absolute;
  right: 0px;
  top: 29%;
  font-size: 18px;
  font-weight: bold;
}

.home-price-details .nh svg {
  color: rgba(43,70,97,0.25);
  position: absolute;
  right: 0px;
  top: 29%;
  font-size: 18px;
  font-weight: bold;
}


.list-group-item .wapper-social {
  position: relative;
  top: 30px;
}

.list-group-item .wapper-social h2 {
  position: absolute;
  top: -20px;
  color: #000000;
  font-size: 14px;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.list-group-item .wapper-social .circle {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 26px;
  text-align: center;
  background: #000;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  padding: 2px 0px 0px 0px !important;
}

.list-group-item .wapper-social .circle:last-child {
  margin-right: 0px;
}

.list-group-item .wapper-social .circle.facebook {
  background: #0c43aa;
}

.list-group-item .wapper-social .circle.instagram {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.list-group-item .wapper-social .circle.twitter {
  background: #00aced;
}

.list-group-item .wapper-social .circle.youtube {
  background: #ff0000;
}

.list-group-item .wapper-social .circle.tiktok {
  background: #000;
}

.list-group-item .wapper-social .circle i {
  position: relative;
  top: -2px;
  font-weight: normal;
}

.list-group-item .wapper-social .circle svg {
  position: relative;
  top: -2px;
  font-weight: normal;
}

.section-mbl-quemsomos  #dropdown-register-qsmbl {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.section-mbl-quemsomos  #dropdown-register-qsmbl:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.section-mbl-quemsomos  #dropdown-register-qsmbl:after {
  display: none
}

.section-mbl-quemsomos  #dropdown-register-qsmbl:focus{
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.home-launch-h {
  padding-left: 20px;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 700;
}

.home-launch-date {
  padding-left: 20px;
  font-size: 46px;
  margin-bottom: 0px;
  color: #0f73ae;
  font-weight: 700;
  position: relative;
}

.home-launch-date span {
  font-size: 16px;
  top: -20px;
  position: relative;
}

.sep-welcome-first {
  height: 260px;
}

.home-header-gray .col-menu-top span {
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}

.home-header-gray .col-menu-top button {
  text-decoration: none;
  padding: 10px 20px 10px 20px;
  display: block;
  color: #434c58;
  font-size: 14px;
  font-weight: bold;
}


.section-pages {
  padding-top: 125px;
  position: relative;
  padding-bottom: 120px;
  overflow: hidden;
}

.home-blue-right-title.about {
  width: 100%;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 16px 2px 16px 8px;
  right: -18px;
}

.home-wrapper-coach-txts.about {
  width: 100%;
}

.home-blue-right-title.contact {
  width: 100%;
  font-size: 36px;
  text-transform: uppercase;
  right: -18px;
}

.home-wrapper-coach-txts.contact {
  width: 90%;
}

.home-contact-topics {
  position: relative;
  background: #08476b;
  width: 100%;
  color: #fff;
  padding: 0px;
  right: -18px;
}

.home-contact-topics a {
  color: #fff;
  text-decoration: none;
}

.home-contact-topics h2 {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  padding: 12px 4px 12px 0px;
  overflow: hidden;
}

.home-contact-topics h2 span {
  background: #ff0000;
  padding: 12px;
}

.home-form-contact {
  padding-left: 18px;
  padding-right: 18px;
}

.home-form-contact label {
  color: #0e6fa5;
  font-weight: bold;
  font-size: 16px;
}

.home-form-contact input {
  border: 2px #0e6fa5 solid;
  border-radius: 10px;
}

.home-form-contact textarea {
  border: 2px #0e6fa5 solid;
  border-radius: 10px;
}

.home-form-contact .btn-wrappers {
  margin-top: 10px;
  margin-bottom: 10px;
}

.home-form-contact .btn-wrappers .btn-send {
  border-radius: 10px;
  padding: 6px 10px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  background: #0e6fa5;
  color: #fff!important;
  border: 1px solid #0e6fa5;
}

.home-form-contact .btn-wrappers .btn-clear {
  border-radius: 10px;
  padding: 6px 10px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  color: #fff!important;
  background: #ff0e21;
  border: 1px solid #ff0e21;
  margin-right: 10px;
}

.home-form-contact .h-ok {
  color: green;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 21px;
}

.home-form-contact .h-erro {
  color: red;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 21px;
}

.section-pages .home-wrapper-btn-red button{

}



.section-pages .home-wrapper-btn-red button {
  background: rgba(255,14,33,1);
  border: 1px rgba(255,14,33,1) solid;
  color: #fff !important;
  border-radius: 30px;
  padding: 10px 40PX;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
  text-align: center;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.section-pages .home-wrapper-btn-red button:hover {
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}

.section-pages .home-wrapper-btn-red button:after {
  display: none
}

.home-col-no-pleft .wrapper-btns button:focus{
  background: rgba(14,111,165,1);
  color: #fff !important;
  border: 1px rgba(14,111,165,1) solid;
}









