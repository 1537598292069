@media (max-width: 992px) {
  .div-top-btns {
    float: right;
  }
}

@media (max-width: 692px) {
  .d-none576 {
    display: none !important;
  }
}

@media (max-width: 692px) {
  .img-logo-scrolled {
    width: 116px;
  }
}

@media (max-width: 692px) {
  .div-logo {
    width: 116px;
    top: 2px;
  }

  .img-logo {
    width: 116px;
  }

  .ath-mb-menu {
    display: flex;
  }

  .row-104{
    height: 64px !important;
  }

  .border-header{
    height: 3px !important;
    top: 62px;
  }

  .ath-top-titles-event h1 {
    font-size: 24px !important;;
  }

  .ath-top-titles-event h2 {
    font-size: 20px !important;;
  }

  .ath-top-titles-event h3 {
    font-size: 18px !important;;
  }

}


@media (max-width: 1040px) {
  .ath-main-banner {
    height: 200px;
  }

  .ath-card-main {
    height: 160px;
    width: 160px;
  }

  .ath-card-main .top {
    height: 160px;
  }
}

@media (max-width: 992px) {
  .ath-l-col {
    display: none;
  }
}

@media (max-width: 692px) {
  .ath-f-col {
    display: none;
  }
}

@media (max-width: 692px) {
  .icon-not-top {
    display: initial;
    margin-left: 8px;
  }
}

@media (max-width: 692px) {
  .icon-not-top span {
    width: 20px;
    height: 20px;
    padding-top: 1px;
  }
}

@media (max-width: 992px) {
  .ath-menu-bottom {
    display: block;
  }
}

@media (max-width: 992px) {
  .sponsored-mb {
    margin-bottom: 16px;
    display: block;
  }
}

@media (max-width: 576px) {
  .ath-white-mdl-box .col-content-w-box {
    padding-top: 10px;
  }
}

@media (max-width: 576px) {
  .ath-white-mdl-box .un-shield {
    position: absolute;
    right: 12px;
    top: initial;
    width: 40px;
    height: 40px;
    bottom: 18px;
  }
}

@media (max-width: 992px) {
  .ath-card-univ {
    width: 160px;
    height: 160px;
    right: initial;
    left: 0px;
  }
}

@media (max-width: 992px) {
  .ath-card-univ .inner {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 992px) {
  .ath-sep-u {
    padding-top: 0px;
  }
}

@media (max-width: 992px) {
  .title-u {
    font-size: 26px;
  }
}

@media (max-width: 360px) {
  .white-btns {
    margin-bottom: 10px;
  }
}

@media (max-width: 692px) {
  .ath-contact-f {
    width: 100%;
  }
}

@media (max-width: 692px) {
  .ath-contact-s {
    width: 100%;
    padding-top: 16px;
  }
}

@media (max-width: 768px) {
  .ath-main-banner.event {
    height: 250px;
  }
}
@media (max-width: 768px) {
  .prf-left-space {
    height: 10px;
  }
}


