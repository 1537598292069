.ch-card-main {
  position: relative;
  width: 230px;
  height: 230px;
  margin: 0 auto;
  top: 51%;
}

.ch-card-main .ath-card-main {
  top: 0px;
}

.ch-card-main .shield {
  position: absolute;
  top: -26px;
  right: -17px;
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50px;
  border: 5px #0c6899 solid;
  z-index: 10;
}

.ch-card-main .shield img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: block;
  margin-top: 7px;
}

.events-m-box .logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 60px;
  margin-top: -31px;
  margin-bottom: 10px;
  position: relative;
  border: 2px #ffffff solid;
}

.events-m-box h2 {
  color: #333c49 !important;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
}

.events-m-box h6 {
  color: #333c49;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.events-m-box h5 {
  font-size: 14px;
  color: #333c49;
  text-align: center;
}

.accordion-ch-filters button {
  padding: 12px 12px 12px 12px;
  color: #333c49;
  font-weight: bold;
  background: rgba(131,131,131, 0.0);
}

.accordion-ch-filters button:focus {
  color: #333c49;
}

.accordion-ch-filters button:hover {
  color: #333c49;
  background: rgba(131,131,131, 0.1);
}

.accordion-ch-filters button:not(.collapsed) {
  background: rgba(131,131,131, 0.1);
  color: #1e71a1;
}

.accordion-ch-filters .accordion-body {
  padding: 12px !important;
}

.accordion-ch-filters .accordion-item  {
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

.accordion-flush .accordion-item:last-child{
  border-bottom: 1px;
}

.span-tag-filter {
  background: rgba(131,131,131, 0.1);
  font-size: 11px;
  padding: 6px;
  font-size: 11px;
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 2px;
  border-radius: 16px;
}

.btn-filter-search {
  background: #1e71a1;
}

.btn-filter-search:hover {
  background: #1e71a1;
}

.filter-box {
  margin-bottom: 24px;
}

.filter-box .form-check-label {
  font-size: 15px;
  font-weight: bold;
}

.filter-box .form-switch {
  margin-top: 2px;
  margin-bottom: 6px;
}

.filter-box .form-check-input:checked {
  background-color: #1e71a1;
  border-color: #1e71a1;
}

.filter-box .div-max {
  height: 200px;
  overflow: auto;
}

.ath-blue {
  color: #1e71a1 !important;
}

.ch-match-h2 {
  margin-bottom: 4px !important;
}

.ch-match-h3 {
  font-size: 20px !important;
  margin-bottom: 0px !important;
}

.ch-match-h3 span {
  font-size: 14px;
}

.ch-match-country-inn {
  position: absolute;
  top: 13px;
  right: 30px;
}

.ch-match-country-inn span {
  font-size: 11px;
  color: #000;
  font-weight: bold;
}

.card-dtls-ath .wrapper-chart {
  height: 469px;
  background: #202020;
  position: relative;
  border-left: 2px #fff solid;
}

.card-dtls-ath .m-card-main {
  margin: initial;
}

.card-dtls-ath .wrapper-chart img {
  max-width: 448px;
  margin: 0px auto 0px auto;
  display: block;
}

.card-dtls-ath .wrapper-chart .wrapper-cats {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.card-dtls-ath .wrapper-chart .box-cats {
  width: 25%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.card-dtls-ath .wrapper-chart .box-cats h4 {
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.card-dtls-ath .wrapper-chart .box-cats img {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
  max-width: 110px;
}

.card-dtls-ath .wrapper-chart .top.fix {
  width: 464px;
  height: 285px;
  padding: 18px 12px 12px 12px;
}


.card-dtls-ath .wrapper-chart .box-grad-utr {
  /*position: absolute;*/
  position: relative;
  /*top: 12px;*/
  /*left: 12px;*/
  border-radius: 6px;
  width: 182px;
  height: 121px;
  margin-right: 4px;
  margin-bottom: 8px;

}

.card-dtls-ath .wrapper-chart .box-grad-utr.fix {
  height: auto;

  padding-bottom: 8px;
}

.box-grad-utr{
  background: linear-gradient(#0b928e 0%, rgb(8,101,120) 41%, #064669 100%);
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content {
  width: 174px;
  height: 113px;
  background: #293239;
  border-radius: 6px;
  position: relative;
  top: 3px;
  left: 4px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content.fix {
  height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content h2 {
  color: #fff;
  font-size: 20px;
  padding-top: 4px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content.fix h2 {
  font-size: 14px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content h1 {
  color: #fff;
  font-size: 30px;
  padding-top: 0px;
  text-align: center;
  font-weight: 600;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content.fix h1 {
  font-size: 20px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers {
  margin-left: 12px;
  margin-right: 12px;
  border-top: 2px #3e474e solid;
  border-bottom: 2px #3e474e solid;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers span {
  font-size: 14px;
  margin: 0px 4px 0px 4px;
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers .s1 {
  color: rgba(255,255,255,0.05);
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers .s2 {
  color: rgba(255,255,255,0.1);
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers .s3 {
  color: rgba(255,255,255,0.6);
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers .s4 {
  color: rgba(255,255,255,0.1);
}

.card-dtls-ath .wrapper-chart .box-grad-utr .content .hold-numbers .s5 {
  color: rgba(255,255,255,0.05);
}

.card-dtls-ath .wrapper-chart .line-grad {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.card-dtls-ath .wrapper-chart .top {
  background: #293239;
  padding-top: 0px;
  padding-bottom: 14px;
}

.card-dtls-ath .wrapper-chart .bottom {
  position: relative;
  width: 100%;
  background: #202020;
}

.card-dtls-ath .m-card-main {
  width: 330px;
  height: 469px;
}

.card-dtls-ath .m-card-main .top {
  height: 469px;
}

.card-dtls-ath .m-card-main .top .detail-left {
  bottom: 58px;
}

.card-dtls-ath .m-card-main .top .hold-bts {
  position: absolute;
  width: 100%;
  bottom: 6px;
  text-align: left;
  padding-left: 12px;
}

.card-dtls-ath .m-card-main .top .hold-bts button {
  width: 56px;
  height: 56px;
  background: rgba(255,255,255, 0.6);
  border-radius: 50%;
  margin-right: 20px;
  text-align: center !important;
  padding: 0px;
  border: none;
}

.card-dtls-ath .m-card-main .top .hold-bts button i {
  font-size: 36px;
}

.card-dtls-ath .m-card-main .top .hold-bts button svg {
  font-size: 36px;
}

.card-dtls-ath .wrapper-chart .bottom {
  padding: 8px 10px 14px 12px;
  display: flex;
  /*flex-wrap: wrap;*/
  justify-content: start;
}

.card-dtls-ath .wrapper-chart .bottom .min-col {
  width: 50%;
  margin-bottom: 6px;
}

.card-dtls-ath .wrapper-chart .bottom .col25 {
  width: 100%;
  margin-bottom: 6px;
  /*padding-right: 20px;*/
  /*border-left: 1px rgba(255,255,255,0.1) solid;*/
}

.card-dtls-ath .wrapper-chart .bottom h3 {
  color: #fff;
  font-size: 13px;
  margin-bottom: 0px;
  text-align: left !important;
  padding-left: 2px;
}

.card-dtls-ath .wrapper-chart .bottom h2 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: left !important;
  margin-bottom: 0px;
  padding-left: 2px;
}

.card-dtls-ath .wrapper-chart .bottom h2 span {
  font-size: 11px;
  font-weight: 500;
}

.card-dtls-ath .wrapper-chart .bottom .full {
  width: 49%;
}

.card-dtls-ath .wrapper-chart .bottom .first {
  width: 49%;
}

.card-dtls-ath .wrapper-chart .bottom .sep {
  width: 1%;
  border-left: 1px rgba(255,255,255,0.4) SOLID;
}

.card-dtls-ath .wrapper-chart .bottom .last {
  width: 49%;
}

.btn-view-profile {
  border: 3px #fff solid;
  padding: 4px 10px;
  color: #ffffff;
  background: #004972;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.btn.btn-view-profile:hover {
  background: #021d2d;
  color: #ffffff;
}

.btn-view-profile.sm{
  border: 2px #fff solid;
  padding: 2px 8px;
  font-size: 16px;
}

.ath-main-banner {
  width: 100%;
  height: 300px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33,37,41,0.16);
  position: relative;
}

.ath-main-banner.coach {
  height: 350px;
}

.ath-card-univ.coach {
  top: 50%;
}

.pd-content-mdl-box {
  padding-left: 16px;
  padding-right: 16px;
}

.wrapper-poster-video-ath {
  position: relative;
  margin-bottom: 20px;
}

.poster-video-ath {
  position: relative;
  border-radius: 4px;
}

.wrapper-poster-video-ath button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: rgba(0,0,0,0.8);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  border: none;
  font-size: 24px;
}

.wrapper-poster-video-ath button:hover {
  background: rgba(0,0,0,1);
  color: #fff;
}

.colum-right-sep-ath-prof {
  height: 75px;
}

.colum-lft-ath-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
}

.btn-add-target {
  border: none;
  border-radius: 6px;
  font-size: 16px;
  padding: 4px 8px 4px 8px;
  color: #58606a;
  background: rgba(131, 131, 131, 0.1);
  font-weight: bold;
}

.video-title {
  font-size: 15px;
  color: #333c49;
  font-weight: bold;
  text-transform: uppercase;
}

.sidebar-prof-ath {
  position: relative;
  height: 100%;
}

.sidebar-inner-prof-ath {
  width: 100%;
  position: absolute;
}

.col-card-ath-search {
  display: flex;
  justify-content: center;
}

.ch-shield-ath-profile {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-prof-ath-mb {
  display: none;
}


/*.list-group-ath-left .list-group-item:nth-child(4) {*/
/*  display: none;*/
/*}*/

.btn-view-profile.sm {
  border: 2px solid #fff;
  padding: 2px 8px;
  font-size: 16px;
}

.btn-view-profile.sm:hover {
  background: #021d2d;
  color: #ffffff;
}

.events-m-box .logo-spo {
  width: 40px;
  padding: 2px;
}

.ath-main-banner.ath {
  height: 400px;
}

.colum-right-sep-ath-prof.college {
  height: 30px;
}

.card-dtls-ath .row-stats {
  height: 100%;
}


.card-dtls-ath .col-stats {
  position: relative;
  height: 100%;
}

.card-dtls-ath .col-stats::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  padding: 4px;
  background: linear-gradient(#0b928e 0%, rgb(8,101,120) 41%, #064669 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.card-dtls-ath .col-stats .wrapper-stats {
  height: 100%;
  background: #293239;
  width: 100%;
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.card-dtls-ath .col-stats .wrapper-stats h2 {
  color: #fff;
  padding-top: 4px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 14px;
}

.card-dtls-ath .col-stats .wrapper-stats h1 {
  color: #fff;
  font-size: 20px;
  padding-top: 0px;
  text-align: center;
  font-weight: 600;
}

.result-main-card-wrapper{
  width: 230px;
  position: relative;
  margin: 0 auto;
}

.result-main-card-wrapper .card-ath{
  position: relative;
  width: 230px;
  height: 330px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33, 37, 41, 0.16);
  background: #202020;
  cursor: pointer;
  transform: scale(1);
}

.result-main-card-wrapper .card-ath{
  position: relative;
  width: 230px;
  height: 330px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 2px 3px 1px rgba(33, 37, 41, 0.16);
  background: #202020;
  cursor: pointer;
  transform: scale(1);
}

.result-main-card-wrapper .card-ath:hover{
  transform: scale(1.05);
}

.result-main-card-wrapper .card-ath .top{
  height: 200px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.result-main-card-wrapper .card-ath .top .logo{
  position: absolute;
  left: -7px;
  top: -3px;
  z-index: 1;
}

.result-main-card-wrapper .card-ath .top .detail-left{
  position: absolute;
  left: -10px;
  bottom: -8px;
  z-index: 1;
}

.result-main-card-wrapper .card-ath .top .detail-left h4{
  position: absolute;
  top: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  left: 14px;
}

.result-main-card-wrapper .card-ath .top .detail-left h5{
  position: absolute;
  top: 38px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  left: 14px;
}

.result-main-card-wrapper .card-ath .top .detail-right{
  position: absolute;
  right: -10px;
  bottom: -12px;
  z-index: 1;
}

.result-main-card-wrapper .card-ath .pd-content-mdl-box{
  padding-left: 6px;
  padding-right: 6px;
}

.result-main-card-wrapper .card-ath .ath-stats-wrapper{
  margin-right: 6px;
  margin-bottom: 0px;
}

.result-main-card-wrapper .card-ath .ath-stats-wrapper h4{
  font-size: 13px;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 0px;
}



.result-main-card-wrapper .card-ath .ath-stats-wrapper h3{
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.accordion-ch-filters .selectpicker{
  /*border: none;*/
  border-radius: 8px;
  color: #464646;
  font-weight: bold;
  font-family: 'Noto Sans';
  font-size: 14px;
}

.wrapper-modal-details-stats .btn-rgt-top-sport {
  color: #fff;
  background: rgba(0, 73, 114, 0.2) !important;
}

.wrapper-modal-details-stats .btn-rgt-top-sport.active {
  color: #fff;
  background: rgba(0, 73, 114, 1) !important;
  border: 2px rgba(0, 73, 114, 1) solid !important ;
}

.wrapper-modal-details-stats .btn-rgt-top-sport.active:hover {
  color: #fff;
  background: rgba(0, 73, 114, 1) !important;
  border: 2px rgba(0, 73, 114, 1) solid !important ;
}

.card-ath .top .bedge-verified{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 115px;
  height: 30px;
}

.card-ath .top .bedge-verified h2{
  position: absolute;
  top: 2px;
  left: 40px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.m-card-main .top .bedge-verified{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 115px;
  height: 30px;
}

.m-card-main .top .bedge-verified h2{
  position: absolute;
  top: 2px;
  left: 40px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.badge-verified{
  margin-top: 0px;
  font-size: 18px;
  color: #333c49;
  margin-bottom: 10px;
  margin-top: 10px;
  color: green;
}

.badge-verified img{
  width: 20px;
  height: 26px;
}

.ch-rgtr-college-list{
  list-style-type: none;
}









/*.list-group-ath-left .list-group-item:nth-child(4) {*/
/*  display: none;*/
/*}*/

