.div-register-titles-spo {
  background: #00397a;
  /*width: 307px;*/
  /*margin: 0px auto;*/
  /*display: block;*/
  border-radius: 14px;
  border: 4px #ff0000 solid;
  padding: 15px 14px 1px 14px;
  text-align: center;
}

.div-register-titles-spo h4 {
  color: #ffffff;
  font-weight: bold;
  font-size: 26px;
}

.reg-mb38 {
  margin-bottom: 38px;
}

.regiter-titles {
  margin-bottom: 38px;
}

.spo-ath-card-rst .div-ath-card-img {
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-top-right-radius: 4px;
  position: relative;
  border-top-left-radius: 4px;
  margin-bottom: 14px;
}

.spo-ath-card-rst .div-ath-card-img .overlay {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
}

.spo-ath-card-rst .div-ath-card-img .overlay .h3-wrap {
  padding-left: 20px;
  padding-right: 20px;
  padding: 0px 20px 10px 20px;
}

.spo-ath-card-rst .div-ath-card-img .overlay h3 {
  color: #fff;
  font-weight: bold;
  font-size: 19px;
}

.spo-ath-card-rst .left {
  padding: 4px 14px 2px 14px;
  border-right: 1px rgba(0,0,0, 0.10) solid;
  width: 48%;
}

.spo-ath-card-rst .right {
  padding: 4px 0px 2px 6px;
  width: 52%;
}

.ath-control-info-texts .i-social {
  font-size: 22px;
  padding-bottom: -1px;
}

.ath-control-info-texts .h-social {
  font-size: 18px;
  color: #333c49;
  font-weight: bold;
}

.ath-control-info-texts .h-social img {
  width: 20px;
  margin-bottom: 2px;
}

.spo-main-wrapper-search {
  width: 100%;
  position: relative;
}

.spo-wrapper-search {
  position: relative;
  background: #202020;
  margin-top: 71px;
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  border: 4px #fff solid;
  z-index: 10;
}

.row-register-spo-srch label {
  font-weight: normal;
  margin-bottom: 2px;
}

.row-register-spo-srch .col {
  margin-bottom: 10px;
}

.row-register-spo-srch .input-range {
  width: 45%;
}

.row-register-spo-srch .input-range-txt {
  width: 10%;
  color: #fff;
  padding-top: 6px;
  text-align: center;
}

.spo-main-wrapper-search .title-box {
  position: absolute;
  padding: 10px;
  font-weight: bold;
  z-index: 9;
  border-radius: 6px;
  border: 4px #fff solid;
  top: -49px;
  left: 0px;
}

.spo-main-wrapper-search .title-box h2 {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

.spo-main-wrapper-search .btn-box {
  position: absolute;
  padding: 10px 10px 10px 10px;
  font-weight: bold;
  z-index: 9;
  border-radius: 6px;
  border: 4px #fff solid;
  bottom: -53px;
  right: 0px;
}

.spo-main-wrapper-search .btn-box button {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  display: block;
  width: 100%;
  padding: 0px;
  margin-top: 5px;
}

.m-card-main.card-spo .bottom h2 span {
  font-size: 16px;
  color: #ffffff;
  text-align: left;
  font-weight: normal;
}

.m-card-main.card-spo .bottom h2 img {
  width: 20px;
  margin-bottom: 4px;
}

.m-card-main.card-spo .bottom h2 {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
}

.m-card-main.card-spo .top {
  height: 320px;
}

.m-card-main.card-spo .bottom {
  height: 196px;
}

.ath-control-info-texts.hor {
  margin-bottom: 18px;
}

.ath-control-info-texts.hor .wrp-img img {
  width: 28px;
  margin-right: 5px;
}

.ath-control-info-texts.hor .text h4 {
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
  margin-right: 6px;
}

.ath-control-info-texts.hor .text h3 {
  color: #333c49;
  font-size: 22px;
  font-weight: bold;
}

.social-link{
  color: #333c49;
  font-size: 16px;
  font-weight: bold;
  margin-right: 6px;
  text-decoration: none;
}

.spo-filter-icons img{
  width: 20px;
  height: 20px;
}

.no-z-index{
  z-index: unset !important;
}

